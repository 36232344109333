import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { isEmpty, omit } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import moment from "moment"
import { Card, CardBody, Container, Row } from "reactstrap"

import ProductCategory from "models/productCategory"
import ProductTypes from "models/productTypes"
import ProductListModal from "./ProductListModal"
import ProductMasterList from "models/productList"
import Upload from "models/upload"
import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import ErrorState from "components/ErrorState/errorState"
import ImageModal from "components/ImageModal"
import FeedbackNotification from "components/FeedbackNotification"
import Auth from "models/auth"
import Link from "components/Link"
import Avatar from "components/Avatar/Avatar"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { formatName, getStatus, formatVolume } from "utils/utils"
import CONST from "utils/constants"
import "./style.scss"

const { DATE_FORMAT, SIZE } = CONST;

const ProductListDetails = props => {
  // meta title
  document.title = "Product list details | hevara-DMS"

  const { productListId } = useParams()

  const [productListDetails, setProductListDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})
  const [modal, setModal] = useState(false)
  const [imageModal, setImageModal] = useState(false)
  const [productCategories, setProductCategories] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [productImageInfo, setProductImageInfo] = useState({})
  const [editImage, setEditImage] = useState(false)

  const fetchProductCategories = () => {
    ProductCategory.lookUpProductCategories()
      .then(data => {
        setProductCategories([
          {
            status: "Default",
            name: "Select product category",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchProductTypes = () => {
    ProductTypes.lookUpProductTypes()
      .then(data => {
        setProductTypes([
          {
            status: "Default",
            label: "Select product type",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchProductListDetails = id => {
    ProductMasterList.getProductList(id)
      .then(data => {
        setProductListDetails(data)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const updateProductList = (productListId, values) => {
    setProcessing(true)

    ProductMasterList.updateProductList(productListId, values)
      .then(data => {
        setProductListDetails(data)
        toast.success(`${data.name} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableProductList = values => {
    setProcessing(true)

    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "ACTIVE",
    }

    ProductMasterList.updateProductList(values.id, payload)
      .then(data => {
        setProductListDetails(data)
        toast.success(`${data.name} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableProductList = values => {
    setProcessing(true)

    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "DISABLED",
    }

    ProductMasterList.updateProductList(values.id, payload)
      .then(data => {
        setProductListDetails(data)
        toast.success(`${data.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const uploadImage = (formData) => {
    Upload.addUpload(formData)
      .then(data => {
        setProductImageInfo(data)
        toast.success(`Product image added successfully.`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateImage = (formData) => {
    const imageInfo = productListDetails.image;

    Upload.updateUpload(imageInfo._id, formData)
      .then(data => {
        setProductImageInfo(data)
        toast.success(`Product image added successfully.`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const onClickUploadImage = file => {
    toggleImageModal()

    setProcessing(true)

    const formData = new FormData()
    formData.append("file", file[0])
    formData.append("fileType", "PRODUCT_IMAGE")
    formData.append("productListId", productListId)

    if (editImage) {
      updateImage(formData)
    } else {
      uploadImage(formData)
    }
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (productListId && profile) {
      Promise.all([fetchProductListDetails(productListId), fetchProductCategories(), fetchProductTypes()])
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [productListId, productImageInfo])

  const getHeaderActions = () => {
    const canManageProductMasterList = userPermissions.MANAGE_GLOBAL_SETTINGS
    const isActive = productListDetails.status === "ACTIVE"

    const actions = [
      canManageProductMasterList && {
        label: "Edit",
        icon: "mdi mdi-pencil",
        onClick: () => onClickEdit(),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      canManageProductMasterList && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableProductList(productListDetails) : enableProductList(productListDetails)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickEdit = () => {
    setIsEdit(true)
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setIsEdit(false)
    } else {
      setModal(true)
    }
  }

  const toggleImageModal = () => {
    if (imageModal) {
      setImageModal(false)
    } else {
      setImageModal(true)
    }
  }

  const onClickEditImage = () => {
    setEditImage(true)
    toggleImageModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Product list details")}
            breadcrumbItem={props.t("Product list details")}
          />

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Product list details"}
            description={"We are processing your request, please wait"}
          />

          {/* PRODUCT LIST MODAL */}
          <ProductListModal 
            modalIsOpen={modal}
            isEdit={isEdit}
            toggle={toggle}
            productCategories={productCategories}
            productTypes={productTypes}
            productList={productListDetails}
            updateProductList={updateProductList}
          />

          {/* IMAGE MODAL */}
          <ImageModal 
            modalIsOpen={imageModal}
            onClickClose={toggleImageModal}
            onClickUploadImage={onClickUploadImage}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {!error && !loading && !isEmpty(productListDetails) && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    avatar={{ initials: productListDetails.name, image: productListDetails.image?.file }}
                    title={`${productListDetails.name} - (${formatVolume(productListDetails.size)})`}
                    showBackButton={true}
                    showDivider={false}
                    actions={<HeaderActions actions={getHeaderActions()} />}
                    info={
                      <PageHeaderInfo>
                        <div className="mt-2">
                          {getStatus(productListDetails.status)}
                        </div>

                        <PageHeaderInfoText className="fw-semibold">
                          {moment(productListDetails.dateCreated).format(DATE_FORMAT)}
                        </PageHeaderInfoText>
                      </PageHeaderInfo>
                    }
                  />

                  <div className="hevara-product-list-data">
                    <div className="tx-grid mb-3">
                      <div className="mb-4">
                        {!productListDetails.image?.file && (
                          <FeedbackNotification
                            title={`Product list image`}
                            message={`Upload product image by clicking on the link below`}
                            bottomActions={
                              <Link onClick={toggleImageModal}>Add image</Link>
                            }
                            className="mb-4"
                          />
                        )}

                        <h5 className="text-tertiary mt-4">Description</h5>
                          <p className="text-muted font-size-14 mb-4">
                            {productListDetails.description}
                          </p>
                        
                        <h5 className="text-muted mt-4">Product details</h5>
                        <div className="detail-grid">
                          <DataItem
                            label={props.t("Product category")}
                            value={formatName(productListDetails.categoryName)}
                          />

                          <DataItem
                            label={props.t("Product type")}
                            value={formatName(productListDetails.type)}
                          />

                          <DataItem
                            label={props.t("Size")}
                            value={formatVolume(productListDetails.size)}
                          />
                        </div>
                      </div>

                      {/* PRODUCT IMAGE */}
                      <div className="mb-3 tx-avatar">
                        <Avatar
                          size={SIZE.XXL}
                          initials={productListDetails.name}
                          image={productListDetails.image?.file}
                          className="avatar-size"
                        />

                        <Link
                          icon="mdi mdi-camera"
                          className="d-flex justify-content-center mt-1"
                          onClick={
                            productListDetails.image?.file
                              ? () => onClickEditImage()
                              : () => toggleImageModal()
                          }
                        >
                          {productListDetails.image?.file ? `Edit image` : `Add image`}
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

ProductListDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProductListDetails)
