import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { chain, isEmpty, omit, orderBy } from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer, toast } from "react-toastify"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Container
} from "reactstrap"

import Auth from "models/auth"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import BillingPlan from "models/billingPlan"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { PrimaryButton, SecondaryButton, BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import BillingPlanListItem from "components/itemList/billingPlanListItem/billingPlanListItem"

const BillingPlans = props => {
  //meta title
  document.title = "Billing plans | hevara-DMS"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [billingPlans, setBillingPlans] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [billingPlanList, setBillingPlanList] = useState([])
  const [billingPlan, setBillingPlan] = useState(null)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    BillingPlan.getBillingPlans(limit, page)
      .then(data => {
        setBillingPlans(data.results)
        setTotalPages(data.totalPages)
        setTotalResults(data.totalResults)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const addBillingPlan = values => {
    setProcessing(true)

    BillingPlan.addBillingPlan(values)
      .then(plan => {
        setBillingPlans([...billingPlans, plan])
        toast.success(`${plan.name} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateBillingPlan = (billingPlanId, values) => {
    setProcessing(true)

    BillingPlan.updateBillingPlan(billingPlanId, values)
      .then(plan => {
        setBillingPlans(
          billingPlans.map(item =>
            item.id.toString() === plan.id.toString()
              ? { item, ...plan }
              : item
          )
        )
        toast.success(`${plan.name} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableBillingPlan = values => {
    setProcessing(true)

    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "ACTIVE",
    }

    BillingPlan.updateBillingPlan(values.id, payload)
      .then(plan => {
        setBillingPlans(
          billingPlans.map(item =>
            item.id.toString() === plan.id.toString()
              ? { ...plan }
              : item
          )
        )
        toast.success(`${plan.name} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableBillingPlan = values => {
    setProcessing(true)

    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "DISABLED",
    }

    BillingPlan.updateBillingPlan(values.id, payload)
      .then(plan => {
        setBillingPlans(
          billingPlans.map(item =>
            item.id.toString() === plan.id.toString()
              ? { ...plan }
              : item
          )
        )
        toast.success(`${plan.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      fetchData(limit, page)
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setBillingPlanList(billingPlans)
  }, [billingPlans])

  useEffect(() => {
    if (!isEmpty(billingPlans) && !!isEdit) {
      setBillingPlanList(billingPlans)
      setIsEdit(false)
    }
  }, [billingPlans])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (billingPlan && billingPlan.name) || "",
      code: (billingPlan && billingPlan.code) || "",
      description: (billingPlan && billingPlan.description) || "",
      value: (billingPlan && billingPlan.value) || "",
      status: (billingPlan && billingPlan.status) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      code: Yup.string().required("This field is required"),
      description: Yup.string().required("This field is required"),
      value: Yup.string().required("This field is required"),
      status: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updateBillingPlan(billingPlan.id, values)
      } else {
        // create new
        addBillingPlan(values)
      }

      toggle()
      validation.resetForm()
    },
  })

  const handleClick = arg => {
    setBillingPlan(arg)
    setIsEdit(true)
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setBillingPlan(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const getListActions = item => {
    const isActive = item.status === "ACTIVE"

    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "Edit details",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive ? disableBillingPlan(item) : enableBillingPlan(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "New billing plan",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const filteredBillingPlans = chain(billingPlans)
    .filter(billingPlan => {
      const hasName = billingPlan.name
        ?.toLowerCase()
        .includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(filteredBillingPlans, [p => p.name.toUpperCase()], "asc")

  const getNextPage = () => {
    setLimit(limit + 10)
  }

  const onClickNewBillingPlan = () => {
    setIsEdit(false)
    setModal(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("Billing plans")} breadcrumbItem={props.t("Billing plans")} />

          {/* Loading */}
          {loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Billing plans"}
            description={"We are processing your request, please wait a moment..."}
          />

          {/* LIST */}
          {!loading && !isEmpty(billingPlans) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Billing plans"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            {`${billingPlans.length} ${billingPlans.length > 1 ? "Billing plans" : "Billing plan"}`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    {/* BILLING PLANS LIST */}
                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "name", true)}
                      hasNextPage={totalPages > 1}
                      getNextPage={getNextPage}
                      loadingPage={loading}
                      loading={loading}
                      renderItem={item => (
                        <BillingPlanListItem
                          item={item}
                          actions={getListActions(item)}
                          permission={userPermissions.MANAGE_GLOBAL_SETTINGS}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(billingPlans) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                visible={isEmpty(billingPlans)}
                title={`No billing plans`}
                description={`No billing plans have been set yet`}
                firstButton={
                  userPermissions.MANAGE_GLOBAL_SETTINGS && {
                    label: `New billing plan`,
                    onClick: onClickNewBillingPlan,
                    disabled: processing,
                  }
                }
              />
            </div>
          )}

          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Modal */}
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit billing plan" : "New billing plan"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Billing plan name</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Billing plan name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Code</Label>
                      <Input
                        name="code"
                        type="text"
                        placeholder="Code"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.code || ""}
                        invalid={
                          validation.touched.code && validation.errors.code
                            ? true
                            : false
                        }
                      />
                      {validation.touched.code && validation.errors.code ? (
                        <FormFeedback type="invalid">
                          {validation.errors.code}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Description</Label>
                      <Input
                        name="description"
                        type="text"
                        placeholder="Description"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description && validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Plan value</Label>
                      <Input
                        name="value"
                        type="number"
                        placeholder="Plan value"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.value || ""}
                        invalid={
                          validation.touched.value && validation.errors.value
                            ? true
                            : false
                        }
                      />
                      {validation.touched.value && validation.errors.value ? (
                        <FormFeedback type="invalid">
                          {validation.errors.value}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Status</Label>
                      <Input
                        name="status"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ""}
                        invalid={
                          validation.touched.status && validation.errors.status
                            ? true
                            : false
                        }
                      >
                        <option>Select status</option>
                        <option value="ACTIVE">Active</option>
                        <option value="DISABLED">Disabled</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback status="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <SecondaryButton
                        type="button"
                        onClick={toggle}
                        className="me-4"
                      >
                        Cancel
                      </SecondaryButton>

                      <PrimaryButton type="submit">
                        {!!isEdit ? "Update billing plan" : "Add billing plan"}
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

BillingPlans.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(BillingPlans)
