import React from "react"
import { isEmpty, map } from "lodash"
import { Link } from "react-router-dom"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getLastFourDigits, getStatus, getCompanyRole } from "utils/utils"

import "./userListItem.scss"

const UserListItem = ({ user, actions }) => {
  return (
    <TableRow>
      <Link
        key={user.id}
        to={`/user-details/${user.id}`}
        className="hevara-user-list-item reset-link text-tertiary"
      >
        {/* MAIN INFO */}
        <MainInfoList
          title={user.fullName}
          subtitle={getCompanyRole(user.companyRole)}
          avatar={{ image: user.image?.file, initials: user.fullName }}
          className="text-truncate"
        />

        {/* PHONE & EMAIL */}
        <TableRowInfo
          className="text-truncate"
          text={user.email}
          subtext={getLastFourDigits(user.mobileNumber)}
        />

        <div className="status">
          <div className="status-list">
            <div className="status-chip">
              <TableRowInfo
                className="text-truncate"
                text={"Warehouse"}
                subtext={
                  user.warehouseName ? user.warehouseName : "Company user"
                }
              />
            </div>
          </div>

          <div className="actions-wrapper">
            {/* ISVAILABLE */}
            {!isEmpty(user.status) && getStatus(user.status)}

            {/* ACTIONS */}
            <ContextualModal
              className="feature-actions"
              style={{ marginLeft: 4 }}
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, user)}
                />
              ))}
            />
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

UserListItem.defaultProps = {
  user: {},
  actions: [],
}

export default UserListItem
