import axios from "axios"
import API from "config/api.config"

export default {
  getPermissions(limit = 10, page = 1) {
    return axios
      .get(
        `${
          API.getApi().PERMISSIONS.GET_PERMISSIONS
        }?limit=${limit}&page=${page}`
      )
      .then(async ({ data }) => {
        return data
      })
  },
  addPermission(values) {
    return axios
      .post(API.getApi().PERMISSIONS.ADD_PERMISSION, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updatePermission(permissionId, values) {
    return axios
      .patch(
        `${API.getApi().PERMISSIONS.UPDATE_PERMISSION}/${permissionId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpPermissions() {
    return axios
      .get(API.getApi().LOOKUPS.LOOKUP_PERMISSIONS)
      .then(async ({ data }) => {
        return data
      })
  },
}
