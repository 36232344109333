import React from "react"
import styled from "styled-components"

import { SmallText, BodyText } from "components/atoms"
import { getTextStyles, getSubtextStyles } from "./Info.styles"

const StyledInfo = styled.div.attrs({
  className: "hevara-table-row-info",
})``

const StyledText = styled(BodyText).attrs({
  className: "hevara-table-row-info-text",
})`
  ${getTextStyles}
`

const StyledSubtext = styled(SmallText).attrs({
  className: "hevara-table-row-info-subtext text-truncate text-tertiary",
})`
  ${getSubtextStyles}
`

const Info = ({ text, subtext, className, style }) => {
  return (
    <StyledInfo className={className} style={style}>
      <StyledText>{text}</StyledText>
      {!!subtext && <StyledSubtext>{subtext}</StyledSubtext>}
    </StyledInfo>
  )
}

export { Info }