import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getProductTypes(limit = 10, page = 1, filters = {}) {
    const parameters = {
      label: filters.label,
      limit,
      page,
    }
    const url = queryParams(API.getApi().PRODUCT_TYPES.GET_PRODUCT_TYPES, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getProduct(productTypeId) {
    return axios.get(`${API.getApi().PRODUCT_TYPES.GET_PRODUCT_TYPE}/${productTypeId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  createProductType(values) {
    return axios.post(API.getApi().PRODUCT_TYPES.CREATE_PRODUCT_TYPE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateProductType(productTypeId, values) {
    return axios
      .patch(`${API.getApi().PRODUCT_TYPES.UPDATE_PRODUCT_TYPE}/${productTypeId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpProductTypes() {
    return axios.get(API.getApi().LOOKUPS.PRODUCT_TYPES)
      .then(async ({ data }) => {
        return data
      })
  },
}
