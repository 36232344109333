import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getCurrencies(limit = 10, page = 1, filters = {}) {
    const parameters = {
      name: filters.name,
      code: filters.code,
      symbol: filters.symbol,
      status: filters.status,
      limit,
      page,
    }
    const url = queryParams(API.getApi().CURRENCY.GET_CURRENCIES, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getCurrency(currencyId) {
    return axios
      .get(`${API.getApi().CURRENCY.GET_CURRENCY}/${currencyId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  createCurrency(values) {
    return axios
      .post(API.getApi().CURRENCY.CREATE_CURRENCY, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateCurrency(currencyId, values) {
    return axios
      .patch(`${API.getApi().CURRENCY.UPDATE_CURRENCY}/${currencyId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
}
