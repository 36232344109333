import axios from "axios"
import API from "config/api.config"

export default {
  getPaymentMethods(limit = 10, page = 1) {
    return axios
      .get(
        `${
          API.getApi().PAYMENT_METHODS.GET_PAYMENT_METHODS
        }?limit=${limit}&page=${page}`
      )
      .then(async ({ data }) => {
        return data
      })
  },
  addPaymentMethod(values) {
    return axios
      .post(API.getApi().PAYMENT_METHODS.ADD_PAYMENT_METHOD, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updatePaymentMethod(paymentMethodId, values) {
    return axios
      .patch(
        `${
          API.getApi().PAYMENT_METHODS.UPDATE_PAYMENT_METHOD
        }/${paymentMethodId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpPaymentMethods() {
    return axios
      .get(API.getApi().LOOKUPS.LOOKUP_PAYMENT_METHODS)
      .then(async ({ data }) => {
        return data
      })
  },
}
