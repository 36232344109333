import React from "react"
import { map } from "lodash"
// import { Link } from "components/Link/Link"
import { Link } from "react-router-dom"
import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"

import "./contactListItem.scss"

const ContactListItem = ({ contact, actions, permission }) => {
  return (
    <TableRow>
    <Link
      key = {contact.id}
      to = {`/contact-details/${contact.id}`}
      className="hevara-contact-list-item"
    >
        {/* MAIN INFO */}
        <MainInfoList
          title={contact.name}
          subtitle="Company website"
          avatar={{ image: contact.img, initials: contact.name }}
          className="text-truncate"
        />

        <div className="date-item">
          {/* EMAIL ADDRESS */}
          <div className="title-and-date">
            <TableRowInfo
              className="text-truncate"
              text={"Email address"}
              subtext={contact.email}
            />
          </div>

          <div className="status-and-actions">
            {/* ACTIONS */}
            {permission && (
              <ContextualModal
                className="item-actions"
                style={{ marginLeft: 4 }}
                position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
                contentElement={map(actions, (modalItem, index) => (
                  <ContextualModalListItem
                    key={index}
                    {...modalItem}
                    onClick={e => modalItem.onClick(e, contact)}
                  />
                ))}
              />
            )}
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

ContactListItem.defaultProps = {
  contact: {},
  actions: [],
}

export default ContactListItem
