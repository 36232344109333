import axios from "axios"
import API from "config/api.config"

export default {
  getRoles(limit = 10, page = 1) {
    return axios
      .get(`${API.getApi().ROLES.GET_ROLES}?limit=${limit}&page=${page}`)
      .then(async ({ data }) => {
        return data
      })
  },
  getRole(roleId) {
    return axios
      .get(`${API.getApi().ROLES.GET_ROLE}/${roleId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addRole(values) {
    return axios
      .post(API.getApi().ROLES.ADD_ROLE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateRole(roleId, values) {
    return axios
      .patch(`${API.getApi().ROLES.UPDATE_ROLE}/${roleId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpRoles() {
    return axios
      .get(API.getApi().LOOKUPS.LOOKUP_ROLES)
      .then(async ({ data }) => {
        return data
      })
  },
}
