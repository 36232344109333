import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              <p className="text-secondary fw-bolder">
                Crafted with <i className="mdi mdi-heart text-danger" /> by{" "}
                <span className="text-dark">h</span>
                <span className="text-dark">e</span>
                <span className="text-tertiary">v</span>
                <span className="text-tertiary">a</span>
                <span className="text-secondary">r</span>
                <span className="text-primary">a</span>
              </p>
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block fw-bolder">
                © {new Date().getFullYear()}{" "}
                <span className="text-dark">h</span>
                <span className="text-dark">e</span>
                <span className="text-tertiary">v</span>
                <span className="text-tertiary">a</span>
                <span className="text-secondary">r</span>
                <span className="text-primary">a</span>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
