import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { isEmpty, omit, orderBy, chain } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import ErrorState from "components/ErrorState/errorState"
import User from "models/user"
import Warehouse from "models/warehouse"
import Country from "models/country"
import CompanyModal from "models/company"
import CompanyRole from "models/companyRole"
import Role from "models/role"
import Auth from "models/auth"
import { getStatus, getCompanyType } from "utils/utils"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import {
  PrimaryButton,
  SecondaryButton,
  BUTTON_VARIANT,
} from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import UserListItem from "components/itemList/userListItem/userListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"

import CONST from "utils/constants"

const { COMPANY_TYPE } = CONST

const CompanyDetails = props => {
  // meta title
  document.title = "Company details | hevara-DMS"

  const { companyId } = useParams()

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [company, setCompany] = useState({})
  const [warehouses, setWarehouses] = useState([])
  const [users, setUsers] = useState([])
  const [user, setUser] = useState([])
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [otherMenu, setOtherMenu] = useState(false)
  const [availableCountries, setAvailableCountries] = useState([])
  const [availableCompanies, setAvailableCompanies] = useState([])
  const [availableRoles, setAvailableRoles] = useState([])
  const [companyRoles, setCompanyRoles] = useState([])
  const [userPermissions, setUserPermissions] = useState({})

  const fetchCompanyData = id => {
    CompanyModal.getCompany(id)
      .then(data => {
        setCompany(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
  }

  const fetchUsers = companyId => {
    const filters = {
      companyId: companyId,
    }

    User.getUsers(limit, page, filters)
      .then(data => {
        setUsers(data.results)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCountries = () => {
    Country.lookUpCountries()
      .then(data => {
        setAvailableCountries([
          {
            status: "Default",
            name: "Select Country",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanies = () => {
    CompanyModal.lookUpCompanies()
      .then(data => {
        setAvailableCompanies([
          {
            status: "Default",
            companyName: "Select company",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchRoles = () => {
    Role.lookUpRoles()
      .then(data => {
        setAvailableRoles(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyRoles = id => {
    CompanyRole.lookupCompanyRoles(id)
      .then(data => {
        setCompanyRoles(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (companyId && profile) {
      Promise.all([
        fetchUsers(companyId),
        fetchCountries(),
        fetchCompanies(),
        fetchRoles(),
        fetchCompanyWarehouses(companyId),
        fetchCompanyRoles(companyId),
        fetchCompanyData(companyId),
      ])
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [companyId])

  useEffect(() => {
    setUserList(users)
  }, [users])

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setUserList(users)
      setIsEdit(false)
    }
  }, [users])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: (user && user.firstName) || "",
      ...(user && user.middleName
        ? { middleName: (user && user.middleName) || "" }
        : {}),
      lastName: (user && user.lastName) || "",
      email: (user && user.email) || "",
      mobileNumber: (user && user.mobileNumber) || "",
      country: (user && user.country) || "",
      companyId: company && company.id,
      ...(user && user.warehouseId
        ? { warehouseId: (user && user.warehouseId) || "" }
        : {}),
      companyRole: (user && user.companyRole) || "",
      role: (user && user.role) || "",
      tnc: true,
      ...(!isEdit
        ? {
            password: "hevara@12345",
          }
        : {}),
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name"),
      ...(user && user.middleName
        ? {
            middleName: Yup.string().required("Please enter users middle name"),
          }
        : {}),
      lastName: Yup.string().required("Please enter last name"),
      email: Yup.string().email().required("Please enter email"),
      mobileNumber: Yup.string()
        .matches(
          /^\+254\d{9}$/,
          "Invalid phone number format, please use +2547...."
        )
        .required("Mobile number is required"),
      country: Yup.string().required("Please select country"),
      companyId: Yup.string().required("Please select company"),
      ...(user && user.warehouseId
        ? {
            warehouseId: Yup.string().required("Please select warehouse"),
          }
        : {}),
      companyRole: Yup.string().required("Please select company role"),
      role: Yup.string().required("Please select user role"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update user
        updateUser(user.id, {
          ...omit(user, [
            "id",
            "isMobileNumberVerified",
            "isEmailVerified",
            "dateCreated",
            "otpCode",
            "tnc",
            "warehouseName",
          ]),
          ...values,
        })
      } else {
        // New user
        addUser(values)
      }
      validation.resetForm()
      toggle()
    },
  })

  const updateUser = (userId, values) => {
    setProcessing(true)
    User.updateUser(userId, values)
      .then(user => {
        setUsers(
          users.map(item =>
            item.id.toString() === user.id.toString() ? { ...user } : item
          )
        )
        toast.success(`${user.fullName} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const addUser = userData => {
    setProcessing(true)
    User.addUser(userData)
      .then(user => {
        setUsers([...users, user])
        toast.success(`${user.fullName} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableUser = data => {
    setProcessing(true)

    const payload = {
      ...(data && data.middleName
        ? {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
            ]),
            status: "Disabled",
          }
        : {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
              "middleName",
            ]),
            status: "Disabled",
          }),
    }

    User.updateUser(data.id, payload)
      .then(user => {
        setUsers(
          users.map(item =>
            item.id.toString() === user.id.toString() ? { ...user } : item
          )
        )
        toast.success(`${user.fullName} account has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableUser = data => {
    setProcessing(true)

    const payload = {
      ...(data && data.middleName
        ? {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
            ]),
            status: "Active",
          }
        : {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
              "middleName",
            ]),
            status: "Active",
          }),
    }

    User.updateUser(data.id, payload)
      .then(user => {
        setUsers(
          users.map(item =>
            item.id.toString() === user.id.toString() ? { ...user } : item
          )
        )
        toast.success(`${user.fullName} account has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const handleUserClick = data => {
    setUser(data)
    setIsEdit(true)
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setUser(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const getUserListActions = user => {
    const isActive = user.status === "Active"

    const actions = [
      userPermissions.CAN_MANAGE_USERS && {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/user-details/${user.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_USERS && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleUserClick(user),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_USERS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableUser(user) : enableUser(user)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = companyInfo => {
    const productTitle = [
      COMPANY_TYPE.WHOLESALLER,
      COMPANY_TYPE.DISTRIBUTOR,
    ].includes(companyInfo.type)

    const showOptions =
      companyInfo &&
      [
        COMPANY_TYPE.DISTRIBUTOR,
        COMPANY_TYPE.MANUFACTURER,
        COMPANY_TYPE.WHOLESALLER,
        COMPANY_TYPE.IMPORTER,
      ].includes(companyInfo.type)

    const actions = [
      userPermissions.CAN_MANAGE_USERS && {
        label: "New user",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      showOptions && {
        label: productTitle ? "Product catalogue" : "Products",
        onClick: () => navigate(`/product-catalogue/${companyInfo.id}/${companyInfo.type}`),
        icon: "mdi mdi-cart-outline",
        variant: BUTTON_VARIANT.ACCENT,
      },
      showOptions && {
        label: "Warehouses",
        onClick: () => navigate(`/warehouses/${companyInfo.id}`),
        variant: BUTTON_VARIANT.SECONDARY,
      },
      showOptions && {
        label: "Vehicles",
        onClick: () => navigate(`/vehicles/${companyInfo.id}`),
        variant: BUTTON_VARIANT.SECONDARY,
      },
      showOptions && {
        label: "Orders",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.SECONDARY,
      },
      showOptions && {
        label: "Dispatches",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.SECONDARY,
      },
      showOptions && {
        label: "Company roles",
        onClick: () => navigate(`/company_roles/${companyInfo.id}`),
        variant: BUTTON_VARIANT.SECONDARY,
      },
      showOptions && {
        label: "Payment settings",
        onClick: () => navigate(`/payment_settings/${companyInfo.id}`),
        variant: BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  // Combine all roles from roles & company roles
  const combinedRoles = [...availableRoles, ...companyRoles]

  // Omit "SUPER_ADMIN" and "VENDOR" from combined roles
  // const filteredRoles = omitBy(
  //   combinedRoles,
  //   ({ value }) => value === "SUPER_ADMIN" || value === "VENDOR"
  // )

  const roles = [
    {
      status: "Default",
      label: "Select company role",
      value: "default_value",
      dateCreated: "2023-07-03T00:00:00Z",
      id: "default_id",
    },
    ...combinedRoles,
  ]

  const filteredUsers = chain(users)
    .filter(user => {
      // Full name
      const hasLabel = user.fullName?.toLowerCase().includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(filteredUsers, [p => p.fullName.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Company details")}
            breadcrumbItem={props.t("Company details")}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Users"}
            description={"We are processing your request, please wait"}
          />

          {/* LIST & DETAILS */}
          {!error && !loading && !isEmpty(company) && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {/* PAGE HEADER */}
                    <PageHeader
                      title={`${company.companyName}`}
                      showBackButton={true}
                      showDivider={false}
                      actions={
                        <HeaderActions actions={getHeaderActions(company)} />
                      }
                      info={
                        <PageHeaderInfo>
                          <div>{getStatus(company.status)}</div>

                          <PageHeaderInfoText className="fw-semibold">
                            {`${users.length} ${
                              users.length > 1 ? "users" : "user"
                            }`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    {/* DATA INFO */}
                    <Col sm="12" className="mb-4">
                      <div className="pt-2">
                        <Row>
                          <Col xl="2" sm="3">
                            <DataItem
                              label={props.t("Country")}
                              value={company.countryName}
                            />
                          </Col>

                          <Col xl="2" sm="3">
                            <DataItem
                              label={props.t("Company type")}
                              value={getCompanyType(company.type)}
                              className="text-truncate"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    {!error && !loading && users && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "fullName", true)}
                        renderItem={user => (
                          <UserListItem
                            user={user}
                            actions={getUserListActions(user)}
                          />
                        )}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Modal */}
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? `Update ${user?.fullName} details` : "New user"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    {/* FIRST NAME */}
                    <div className="mb-3">
                      <Label className="form-label">First name</Label>
                      <Input
                        name="firstName"
                        type="text"
                        placeholder="First name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstName || ""}
                        invalid={
                          validation.touched.firstName &&
                          validation.errors.firstName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.firstName &&
                      validation.errors.firstName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.firstName}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* MIDDLE NAME */}
                    {user && user.middleName && (
                      <div className="mb-3">
                        <Label className="form-label">Middle name</Label>
                        <Input
                          name="middleName"
                          type="text"
                          placeholder="Middle name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.middleName || ""}
                          invalid={
                            validation.touched.middleName &&
                            validation.errors.middleName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.middleName &&
                        validation.errors.middleName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.middleName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    )}

                    {/* LAST NAME */}
                    <div className="mb-3">
                      <Label className="form-label">Last name</Label>
                      <Input
                        name="lastName"
                        type="text"
                        placeholder="Last name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastName || ""}
                        invalid={
                          validation.touched.lastName &&
                          validation.errors.lastName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lastName &&
                      validation.errors.lastName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lastName}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* EMAIL*/}
                    <div className="mb-3">
                      <Label className="form-label">Email address</Label>
                      <Input
                        name="email"
                        type="email"
                        placeholder="Email address"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* MOBILE NUMBER*/}
                    <div className="mb-3">
                      <Label className="form-label">Mobile number</Label>
                      <Input
                        name="mobileNumber"
                        type="tel"
                        placeholder="Mobile number (+2547XXXXXXXX)"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mobileNumber || ""}
                        invalid={
                          validation.touched.mobileNumber &&
                          validation.errors.mobileNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mobileNumber &&
                      validation.errors.mobileNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mobileNumber}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* COUNTRY*/}
                    <div className="mb-3">
                      <Label className="form-label">Country</Label>
                      <Input
                        name="country"
                        type="select"
                        placeholder="Select country"
                        defaultValue={"Select country"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.country || ""}
                        invalid={
                          validation.touched.country &&
                          validation.errors.country
                            ? true
                            : false
                        }
                      >
                        {availableCountries.map(country => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.country &&
                      validation.errors.country ? (
                        <FormFeedback type="invalid">
                          {validation.errors.country}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* COMPANY ROLE */}
                    <div className="mb-3">
                      <Label className="form-label">Company role</Label>
                      <Input
                        name="companyRole"
                        type="select"
                        placeholder="Select company role"
                        defaultValue={"Select country"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.companyRole || ""}
                        invalid={
                          validation.touched.companyRole &&
                          validation.errors.companyRole
                            ? true
                            : false
                        }
                      >
                        {roles.map(role => (
                          <option key={role.id} value={role.value}>
                            {role.label}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.companyRole &&
                      validation.errors.companyRole ? (
                        <FormFeedback type="invalid">
                          {validation.errors.companyRole}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* WAREHOUSE */}
                    <div className="mb-3">
                      <Label className="form-label">Warehouse (Optional)</Label>
                      <Input
                        name="warehouseId"
                        type="select"
                        placeholder="Select warehouse"
                        defaultValue={"Select warehouse"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.warehouseId || ""}
                        invalid={
                          validation.touched.warehouseId &&
                          validation.errors.warehouseId
                            ? true
                            : false
                        }
                      >
                        {warehouses.map(role => (
                          <option key={role.id} value={role.id}>
                            {role.warehouseName}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.warehouseId &&
                      validation.errors.warehouseId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.warehouseId}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* USER ROLE */}
                    <div className="mb-3">
                      <Label className="form-label">User role</Label>
                      <Input
                        name="role"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.role || ""}
                        invalid={
                          validation.touched.role && validation.errors.role
                            ? true
                            : false
                        }
                      >
                        <option value="">Select role</option>
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                      </Input>
                      {validation.touched.role && validation.errors.role ? (
                        <FormFeedback status="invalid">
                          {validation.errors.role}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <SecondaryButton
                        type="button"
                        onClick={toggle}
                        className="me-4"
                      >
                        Cancel
                      </SecondaryButton>

                      <PrimaryButton type="submit">
                        {!!isEdit ? "Update user" : "Add user"}
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

CompanyDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CompanyDetails)
