import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import {
  getButtonRadius,
  getButtonSizes,
  getButtonStyles,
} from "./Button.styles"
import { ButtonText } from "components/atoms"

const StyledButton = styled.button.attrs({
  className: "hevara-button",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  transition: all 0.25s;
  ${getButtonSizes}
  ${getButtonRadius}
  ${getButtonStyles}
`

const StyledIcon = styled.i.attrs({
  className: "hevara-button-icon",
})``

const StyledText = styled(ButtonText).attrs({
  className: "hevara-button-text",
})``

export const Button = props => {
  return (
    <StyledButton {...props}>
      {props.leftIcon && (
        <StyledIcon size="20" style={{ marginRight: "8px" }}>
          <i className={props.leftIcon + " hevara-button-icon"}></i>
        </StyledIcon>
      )}
      <StyledText className="fw-semibold">{props.children}</StyledText>
    </StyledButton>
  )
}

Button.defaultProps = {
  onClick: undefined,
  variant: "primary",
  size: "m",
}
