import React, { useEffect, useState } from "react"
import { isEmpty, omit, orderBy, chain } from "lodash"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import Auth from "models/auth"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import Country from "models/country"
import Town from "models/town"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import {
  PrimaryButton,
  SecondaryButton,
  BUTTON_VARIANT,
} from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import TownListItem from "components/itemList/townListItem/townListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"

const TownList = props => {
  //meta title
  document.title = "Towns | hevara admin"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [towns, setTowns] = useState([])
  const [availableCountries, setAvailableCountries] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [townsList, setTownsList] = useState([])
  const [town, setTown] = useState(null)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    Town.getTowns(limit, page)
      .then(data => {
        setTowns(data.results)
        setTotalPages(data.totalPages)
        setTotalResults(data.totalResults)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCountries = () => {
    Country.lookUpCountries()
      .then(data => {
        setAvailableCountries([
          {
            status: "Default",
            name: "Select Country",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const addTown = values => {
    setLoading(true)
    Town.addTown(values)
      .then(town => {
        setTowns([...towns, town])
        toast.success(`${town.name} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const updateTown = (countryId, values) => {
    setProcessing(true)
    Town.updateTown(countryId, values)
      .then(town => {
        setTowns(
          towns.map(item =>
            item.id.toString() === town.id.toString() ? { item, ...town } : item
          )
        )
        toast.success(`${town.name} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableTown = values => {
    setProcessing(true)
    const payload = {
      ...omit(values, ["id"]),
      status: "Disabled",
    }
    Town.updateTown(values.id, payload)
      .then(town => {
        setTowns(
          towns.map(item =>
            item.id.toString() === town.id.toString() ? { ...town } : item
          )
        )
        toast.success(`${town.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableTown = values => {
    setProcessing(true)
    const payload = {
      ...omit(values, ["id"]),
      status: "Active",
    }
    Town.updateTown(values.id, payload)
      .then(town => {
        setTowns(
          towns.map(item =>
            item.id.toString() === town.id.toString() ? { ...town } : item
          )
        )
        toast.success(`${town.name} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      Promise.all([fetchCountries(), fetchData(limit, page)])
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setTownsList(towns)
  }, [towns])

  useEffect(() => {
    if (!isEmpty(towns) && !!isEdit) {
      setTownsList(towns)
      setIsEdit(false)
    }
  }, [towns])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (town && town.name) || "",
      country: (town && town.country) || "",
      status: (town && town.status) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter town name"),
      country: Yup.string().required("Please choose a country"),
      status: Yup.string().required("Please enter town status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update Country
        updateTown(town.id, values)
        validation.resetForm()
      } else {
        // add new Country
        addTown(values)
        validation.resetForm()
      }
      toggle()
    },
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setTown(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const handleClick = arg => {
    const town = arg
    setTown(town)
    setIsEdit(true)
    toggle()
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "Edit details",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableTown(item) : enableTown(item)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "New town",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const filteredTowns = chain(towns)
    .filter(town => {
      // Name
      const hasName = town.name?.toLowerCase().includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(filteredTowns, [p => p.name.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Towns" breadcrumbItem="Towns" />

          {/* Loading */}
          {loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Towns"}
            description={
              "We are processing your request, please wait a moment..."
            }
          />

          {/* LIST */}
          {!loading && !isEmpty(towns) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Towns"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText className="fw-semibold">
                            {`${towns.length} ${
                              towns.length > 1 ? "Towns" : "Town"
                            }`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "name", true)}
                      renderItem={item => (
                        <TownListItem
                          item={item}
                          actions={getListActions(item)}
                          permission={userPermissions.MANAGE_GLOBAL_SETTINGS}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(towns) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                title={"No towns"}
                description={
                  "No towns at the moment. Please check again later."
                }
              />

              {userPermissions.MANAGE_GLOBAL_SETTINGS && (
                <PrimaryButton
                  onClick={() => {
                    setIsEdit(false)
                    setModal(true)
                  }}
                  leftIcon="mdi mdi-plus"
                >
                  New town
                </PrimaryButton>
              )}
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Modal */}
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit town" : "Add town"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Town Name</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Town name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Country</Label>
                      <Input
                        name="country"
                        type="select"
                        placeholder="Select country"
                        defaultValue={"Select country"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.country || ""}
                        invalid={
                          validation.touched.country &&
                          validation.errors.country
                            ? true
                            : false
                        }
                      >
                        {availableCountries.map(country => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.country &&
                      validation.errors.country ? (
                        <FormFeedback type="invalid">
                          {validation.errors.country}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Status</Label>
                      <Input
                        name="status"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ""}
                        invalid={
                          validation.touched.status && validation.errors.status
                            ? true
                            : false
                        }
                      >
                        <option>Select status</option>
                        <option value="Active">Active</option>
                        <option value="Disabled">Disabled</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback status="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <SecondaryButton
                        type="button"
                        onClick={toggle}
                        className="me-4"
                      >
                        Cancel
                      </SecondaryButton>

                      <PrimaryButton type="submit">
                        {!!isEdit ? "Update town" : "Add town"}
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default withTranslation()(TownList)
