import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, omit, orderBy, chain } from "lodash"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer } from "react-toastify"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import Warehouse from "models/warehouse"
import Fleet from "models/fleet"
import User from "models/user"
import Auth from "models/auth"
import VehicleTypes from "models/vehicleTypes"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import {
  PrimaryButton,
  SecondaryButton,
  BUTTON_VARIANT,
} from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import VehicleListItem from "components/itemList/vehicleListItem/vehicleListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import CONST from "utils/constants"

const { ROLES } = CONST

const Vehicles = props => {
  //meta title
  document.title = "Vehicles | hevara admin"

  const { companyId } = useParams()

  const navigate = useNavigate()

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [fleets, setFleets] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [fleetsList, setFleetsList] = useState([])
  const [fleet, setFleet] = useState(null)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [vehicleTypes, setVehicleTypes] = useState([])
  const [routeAgents, setRouteAgents] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page, id) => {
    const filters = {
      companyId: id,
    }
    Fleet.getFleets(limit, page, filters)
      .then(data => {
        setFleets(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchVehicleTypes = () => {
    VehicleTypes.lookUpVehicleTypes()
      .then(data => {
        setVehicleTypes([
          {
            status: "Default",
            name: "Select vehicle type",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchRouteAgents = id => {
    const filters = {
      companyId: id,
      companyRole: ROLES.ROUTE_AGENT,
    }

    User.getUsers(limit, page, filters)
      .then(data => {
        setRouteAgents([
          {
            status: "Default",
            fullName: "Select route agent",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data.results,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
  }

  const addFleet = values => {
    setProcessing(true)
    Fleet.addFleet(values)
      .then(fleet => {
        setFleets([...fleets, fleet])
        toast.success(`${fleet.registrationNumber} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateFleet = (vehicleId, values) => {
    setProcessing(true)
    Fleet.updateFleet(vehicleId, values)
      .then(fleet => {
        setFleets(
          fleets.map(item =>
            item.id.toString() === fleet.id.toString()
              ? { item, ...fleet }
              : item
          )
        )
        toast.success(`${fleet.registrationNumber} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (companyId && profile) {
      Promise.all([
        fetchData(limit, page, companyId),
        fetchVehicleTypes(),
        fetchRouteAgents(companyId),
        fetchCompanyWarehouses(companyId),
      ])
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [companyId, limit, page])

  useEffect(() => {
    setFleetsList(fleets)
  }, [fleets])

  useEffect(() => {
    if (!isEmpty(fleets) && !!isEdit) {
      setFleetsList(fleets)
      setIsEdit(false)
    }
  }, [fleets])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      registrationNumber: (fleet && fleet.registrationNumber) || "",
      vehicleType: (fleet && fleet.vehicleType) || "",
      companyId: companyId && companyId,
      routeAgent: (fleet && fleet.routeAgent) || "",
      ...(fleet && fleet.warehouseId
        ? { warehouseId: (fleet && fleet.warehouseId) || "" }
        : {}),
    },
    validationSchema: Yup.object({
      registrationNumber: Yup.string().required(
        "Please enter vehicle registration number"
      ),
      vehicleType: Yup.string().required("Please select vehicle type"),
      companyId: Yup.string().required("Please select company"),
      routeAgent: Yup.string().required("Please select vehicle driver"),
      ...(fleet && fleet.warehouseId
        ? {
            warehouseId: Yup.string().required("Please select warehouse"),
          }
        : {}),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updateFleet(fleet.id, values)
        validation.resetForm()
      } else {
        // add new
        addFleet(values)
        validation.resetForm()
      }
      toggle()
    },
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setFleet(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const handleClick = vehicleData => {
    setFleet(vehicleData)
    setIsEdit(true)
    toggle()
  }

  const disableFleet = data => {
    setProcessing(true)
    const payload = {
      ...omit(data, [
        "id",
        "companyName",
        "routeAgentName",
        "vehicleTypeName",
        "warehouseName",
        "dateCreated",
      ]),
      status: "Disabled",
    }
    Fleet.updateFleet(data.id, payload)
      .then(fleet => {
        setFleets(
          fleets.map(item =>
            item.id.toString() === fleet.id.toString() ? { ...fleet } : item
          )
        )
        toast.success(`${fleet.registrationNumber} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableFleet = data => {
    setProcessing(true)
    const payload = {
      ...omit(data, [
        "id",
        "companyName",
        "routeAgentName",
        "vehicleTypeName",
        "warehouseName",
        "dateCreated",
      ]),
      status: "Active",
    }
    Fleet.updateFleet(data.id, payload)
      .then(fleet => {
        setFleets(
          fleets.map(item =>
            item.id.toString() === fleet.id.toString() ? { ...fleet } : item
          )
        )
        toast.success(`${fleet.registrationNumber} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/vehicle_details/${item.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_FLEETS && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_FLEETS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableFleet(item) : enableFleet(item)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.CAN_MANAGE_FLEETS && {
        label: "New vehicle",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const filteredFleets = chain(fleets)
    .filter(fleet => {
      // Full name
      const hasLabel = fleet.registrationNumber
        ?.toLowerCase()
        .includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(
    filteredFleets,
    [p => p.registrationNumber.toUpperCase()],
    "asc"
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Vehicles" breadcrumbItem="Vehicles" />

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Vehicles"}
            description={"We are processing your request, please wait"}
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* Loading */}
                  {loading && <Loading />}

                  {/* PAGE HEADER */}
                  <PageHeader
                    title={"Vehicles"}
                    showBackButton={true}
                    showDivider={false}
                    actions={<HeaderActions actions={getHeaderActions()} />}
                    info={
                      <PageHeaderInfo>
                        {!loading && !isEmpty(fleets) && (
                          <PageHeaderInfoText className="fw-semibold">
                            {`${fleets.length} ${
                              fleets.length > 1 ? "vehicles" : "vehicle"
                            }`}
                          </PageHeaderInfoText>
                        )}
                      </PageHeaderInfo>
                    }
                  />

                  {/* LIST */}
                  {!loading && !isEmpty(fleets) && (
                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "registrationNumber", true)}
                      renderItem={item => (
                        <VehicleListItem
                          item={item}
                          actions={getListActions(item)}
                        />
                      )}
                    />
                  )}

                  {/* Empty state */}
                  {!loading && isEmpty(fleets) && (
                    <div className="d-flex flex-column align-items-center">
                      <EmptyState
                        visible={isEmpty(fleets)}
                        title={`No vehicles`}
                        description={`No vehicles have been added.`}
                      />
                    </div>
                  )}

                  {/* ERROR */}
                  {error && (
                    <div className="text-center">
                      <ErrorState
                        title={"Something went wrong"}
                        description={"Please try again later."}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Edit/Add Modal */}
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit vehicle" : "New vehicle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    {/* REGISTRATION NUMBER */}
                    <div className="mb-3">
                      <Label className="form-label">
                        Vehicle registration number
                      </Label>
                      <Input
                        name="registrationNumber"
                        type="text"
                        placeholder="Registration number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.registrationNumber || ""}
                        invalid={
                          validation.touched.registrationNumber &&
                          validation.errors.registrationNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.registrationNumber &&
                      validation.errors.registrationNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.registrationNumber}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* VEHICLE TYPE */}
                    <div className="mb-3">
                      <Label className="form-label">Vehicle type</Label>
                      <Input
                        name="vehicleType"
                        type="select"
                        placeholder="Select vehicle type"
                        defaultValue={"Select vehicle type"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.vehicleType || ""}
                        invalid={
                          validation.touched.vehicleType &&
                          validation.errors.vehicleType
                            ? true
                            : false
                        }
                      >
                        {vehicleTypes.map(type => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.vehicleType &&
                      validation.errors.vehicleType ? (
                        <FormFeedback type="invalid">
                          {validation.errors.vehicleType}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* ROUTE-AGENT */}
                    <div className="mb-3">
                      <Label className="form-label">Vehicle driver</Label>
                      <Input
                        name="routeAgent"
                        type="select"
                        placeholder="Select vehicle driver"
                        defaultValue={"Select vehicle driver"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.routeAgent || ""}
                        invalid={
                          validation.touched.routeAgent &&
                          validation.errors.routeAgent
                            ? true
                            : false
                        }
                      >
                        {routeAgents.map(routeAgent => (
                          <option key={routeAgent.id} value={routeAgent.id}>
                            {routeAgent.fullName}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.routeAgent &&
                      validation.errors.routeAgent ? (
                        <FormFeedback type="invalid">
                          {validation.errors.routeAgent}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* WAREHOUSE */}
                    <div className="mb-3">
                      <Label className="form-label">Warehouse (Optional)</Label>
                      <Input
                        name="warehouseId"
                        type="select"
                        placeholder="Select warehouse"
                        defaultValue={"Select warehouse"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.warehouseId || ""}
                        invalid={
                          validation.touched.warehouseId &&
                          validation.errors.warehouseId
                            ? true
                            : false
                        }
                      >
                        {warehouses.map(role => (
                          <option key={role.id} value={role.id}>
                            {role.warehouseName}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.warehouseId &&
                      validation.errors.warehouseId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.warehouseId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <SecondaryButton
                        type="button"
                        onClick={toggle}
                        className="me-4"
                      >
                        Cancel
                      </SecondaryButton>

                      <PrimaryButton type="submit">
                        {!!isEdit ? "Update vehicle" : "Add vehicle"}
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

Vehicles.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Vehicles)
