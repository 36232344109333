import React from "react"
import { isEmpty, map } from "lodash"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getStatus } from "utils/utils"

import "./companyTypeListItem.scss"

const CompanyTypeListItem = ({ item, actions, permission }) => {
  return (
    <TableRow>
      <div key={item.id} className="hevara-company-type-list-item">
        {/* MAIN INFO */}
        <MainInfoList
          title={item.name}
          avatar={{ image: item.img, initials: item.name }}
          className="text-truncate"
        />

        <div className="date-item">
          {/* CODE */}
          <div className="title-and-date">
            <TableRowInfo
              className="text-truncate"
              text={item.type}
            />
          </div>

          <div className="status-and-actions">
            {/* STATUS */}
            {!isEmpty(item.status) && getStatus(item.status)}

            {/* ACTIONS */}
            {permission && (
              <ContextualModal
                className="item-actions"
                style={{ marginLeft: 4 }}
                position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
                contentElement={map(actions, (modalItem, index) => (
                  <ContextualModalListItem
                    key={index}
                    {...modalItem}
                    onClick={e => modalItem.onClick(e, item)}
                  />
                ))}
              />
            )}
          </div>
        </div>
      </div>
    </TableRow>
  )
}

CompanyTypeListItem.defaultProps = {
  item: {},
  actions: [],
}

export default CompanyTypeListItem
