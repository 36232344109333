import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { isEmpty, orderBy, chain } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import Auth from "models/auth"
import ContactModal from "models/contact"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import { PrimaryButton, BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import ContactListItem from "components/itemList/contactListItem/contactListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"

const Contact = props => {
  //meta title
  document.title = "Contact | hevara admin"

  const [modal, setModal] = useState(false)
  const [view, setView] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [contacts, setContacts] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [contactList, setContactList] = useState([])
  const [contact, setContact] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    ContactModal.getContacts(limit, page)
      .then(data => {
        setContacts(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      fetchData(limit, page)
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setContactList(contacts)
  }, [contacts])

  useEffect(() => {
    if (!isEmpty(contacts) && !!isEdit) {
      setContactList(contacts)
      setIsEdit(false)
    }
  }, [contacts])

  const handleClick = data => {
    console.log("DO WE EVEN GET HERE")
    setContact(data)
    setView(true)
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setContact(null)
    } else {
      setModal(true)
    }
  }

  const sendEmail = data => {
    console.log(data)
  }

  const getListActions = contact => {
    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "View message",
        icon: "mdi mdi-monitor-eye",
        onClick: () => {navigate(`/contact-details/${contact.id}`)},
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "Reply",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => sendEmail(contact),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

const filteredContacts = chain(contacts)
    .filter(contact => {
      // Name
      const hasName = contact.name?.toLowerCase().includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(filteredContacts, [p => p.name.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Contacts" breadcrumbItem="Contacts" />

          {/* Loading */}
          {loading && <Loading />}

          {/* LIST */}
          {!loading && !isEmpty(contacts) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Contacts"}
                      showDivider={false}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText className="fw-semibold">
                            {`${contacts.length} ${
                              contacts.length > 1 ? "Contacts" : "Contact"
                            }`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "name", true)}
                      renderItem={contact => (
                        <ContactListItem
                          contact = {contact}
                          actions={getListActions(contact)}
                          permission={userPermissions.MANAGE_GLOBAL_SETTINGS}
          
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(contacts) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                title={"No contacts"}
                description={
                  "No contacts at the moment. Keep checking this section for updates."
                }
              />
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Modal */}
          <Modal size="md" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Message
            </ModalHeader>
            <ModalBody>
              <Row>
                <p className="text-center text-dark text-justify font-size-14">
                  {contact?.message}
                </p>
              </Row>

              <Row>
                <Col>
                  <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                    <PrimaryButton
                      type="button"
                      onClick={toggle}
                      className="me-4"
                    >
                      Close
                    </PrimaryButton>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

Contact.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Contact)
