import axios from "axios"
import API from "config/api.config"

export default {
  getVehicleTypes(limit = 10, page = 1) {
    return axios
      .get(
        `${
          API.getApi().VEHICLE_TYPES.GET_VEHICLE_TYPES
        }?limit=${limit}&page=${page}`
      )
      .then(async ({ data }) => {
        return data
      })
  },
  addVehicleType(values) {
    return axios
      .post(API.getApi().VEHICLE_TYPES.ADD_VEHICLE_TYPE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateVehicleType(vehicleTypeId, values) {
    return axios
      .patch(
        `${API.getApi().VEHICLE_TYPES.UPDATE_VEHICLE_TYPE}/${vehicleTypeId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpVehicleTypes() {
    return axios
      .get(API.getApi().LOOKUPS.LOOKUP_VEHICLE_TYPES)
      .then(async ({ data }) => {
        return data
      })
  },
}
