import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useNavigate, useParams, Link } from "react-router-dom"
import StarRatings from "react-star-ratings"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import ErrorState from "components/ErrorState/errorState"
import ProductCatalogue from "models/productCatalogue"
import ProductModal from "models/product"
import Auth from "models/auth"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { getStatus, getCompanyType, getInitials } from "utils/utils"
import { PrimaryButton, BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import { BorderedContainer } from "components/Container"

import CONST from "utils/constants"
import "./style.scss"

const { COMPANY_TYPE } = CONST

const ProductCatalogueDetails = props => {
  // meta title
  document.title = "Product details | hevara-DMS"

  const { catalogueId, companyType } = useParams()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState("1")
  const [catalogueDetail, setCatalogueDetail] = useState({})
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchProductCatalogueDetails = id => {
    ProductCatalogue.getProductCatalogue(id)
      .then(data => {
        setCatalogueDetail(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchProductDetails = id => {
    ProductModal.getProduct(id)
      .then(data => {
        setCatalogueDetail(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (catalogueId && companyType && profile) {
      if (companyType === COMPANY_TYPE.MANUFACTURER) {
        fetchProductDetails(catalogueId)
      } else {
        fetchProductCatalogueDetails(catalogueId)
      }
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [catalogueId, companyType])

  const getHeaderActions = () => {
    const actions = [
      userPermissions.CAN_MANAGE_PRODUCTS && {
        label: "Edit product",
        icon: "mdi mdi-pencil",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_PRODUCTS && {
        label: "Edit image",
        icon: "mdi mdi-image",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Product details")}
            breadcrumbItem={props.t("Product details")}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Products"}
            description={"We are processing your request, please wait..."}
          />

          {/* DETAILS */}
          {!error && !loading && !isEmpty(catalogueDetail) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* PAGE HEADER */}
                    <PageHeader
                      avatar={{
                        initials: catalogueDetail.name,
                        image: catalogueDetail.image?.file,
                      }}
                      title={`${catalogueDetail.name} (${catalogueDetail.size} ml)`}
                      showBackButton={true}
                      showDivider={true}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            {getStatus(catalogueDetail.status)}
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            <p className="mb-0 fw-bold text-primary">
                              {catalogueDetail.categoryName} -{" "}
                              {catalogueDetail.categoryType}
                            </p>
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            <p className="mb-0 fw-bold text-tertiary">
                              {catalogueDetail.code}
                            </p>
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                      actions={<HeaderActions actions={getHeaderActions()} />}
                    />

                    {/* DETAILS SECTIONS */}
                    <div className="hevara-product-detail">
                      <div className="tx-grid mb-3">
                        <div>
                          <h5 className="text-tertiary">Description</h5>
                          <p className="text-muted font-size-14 mb-4">
                            {catalogueDetail.description}
                          </p>

                          {/* PRODUCT DETAILS */}
                          <h5 className="mb-2 text-tertiary">
                            Product details
                          </h5>
                          <div className="detail-grid mb-5">
                            <DataItem
                              label={props.t("Name")}
                              value={`${catalogueDetail.name} (${catalogueDetail.size} ml)`}
                            />

                            <DataItem
                              label={props.t("Category")}
                              value={catalogueDetail.categoryName}
                            />

                            <DataItem
                              label={props.t("Type")}
                              value={catalogueDetail.categoryType}
                            />
                          </div>

                          {/* PRODUCT PRICING */}
                          <h5 className="mb-2 text-tertiary">
                            Product pricing
                          </h5>
                          <div className="detail-grid mb-5">
                            {/* MANUFACTURER PRICING */}
                            {companyType === COMPANY_TYPE.MANUFACTURER && (
                              <>
                                <DataItem
                                  label={props.t("Price")}
                                  value={`Ksh. ${catalogueDetail.primaryPrice}`}
                                />

                                {!!catalogueDetail.primaryDiscount && (
                                  <DataItem
                                    label={props.t("Discount")}
                                    value={`${catalogueDetail.primaryDiscount}% Off`}
                                  />
                                )}
                              </>
                            )}

                            {/* DISTRIBUTOR PRICING */}
                            {companyType === COMPANY_TYPE.DISTRIBUTOR && (
                              <>
                                <DataItem
                                  label={props.t("Price")}
                                  value={`Ksh. ${
                                    catalogueDetail.secondaryPrice || 0
                                  }`}
                                />

                                {!!catalogueDetail.secondaryDiscount && (
                                  <DataItem
                                    label={props.t("Discount")}
                                    value={`${catalogueDetail.secondaryDiscount}% Off`}
                                  />
                                )}
                              </>
                            )}

                            {companyType === COMPANY_TYPE.WHOLESALLER && (
                              <>
                                <DataItem
                                  label={props.t("Price")}
                                  value={`Ksh. ${
                                    catalogueDetail.retailPrice || 0
                                  }`}
                                />
                                {!!catalogueDetail.retailDiscount && (
                                  <DataItem
                                    label={props.t("Discount")}
                                    value={`${catalogueDetail.retailDiscount}% Off`}
                                  />
                                )}
                              </>
                            )}
                          </div>

                          {/* Other details */}
                          <h5 className="mb-2 text-tertiary">Other details</h5>
                          <div className="detail-grid">
                            <DataItem
                              label={props.t("Stock quantity")}
                              value={`${catalogueDetail.stockQuantity || "-"}`}
                            />

                            <DataItem
                              label={props.t("Re-order level")}
                              value={`${
                                catalogueDetail.replenishingLevel || "-"
                              }`}
                            />

                            <DataItem
                              label={props.t("Manufacturer")}
                              value={catalogueDetail.manufacturerName}
                            />

                            <DataItem
                              label={props.t("Distributor")}
                              value={catalogueDetail.distributorName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

ProductCatalogueDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProductCatalogueDetails)
