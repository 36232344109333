import React from "react"
import { isEmpty, map } from "lodash"
import { Link } from "react-router-dom"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getStatus } from "utils/utils"
import CONST from "utils/constants"

const { COMPANY_TYPE } = CONST

import "./productListItem.scss"

const ProductListItem = ({ item, actions, companyType }) => {
  return (
    <TableRow>
      <Link
        key={item.id}
        to={`/product-catalogue-details/${item.id}/${companyType}`}
        className="hevara-product-list-item reset-link text-tertiary"
      >
        {/* PRODUCT NAME & CODE */}
        <MainInfoList
          title={`${item.name} (${item.size} ml)`}
          subtitle={item.code}
          avatar={{ image: item.image?.file, initials: item.name }}
          className="text-truncate"
        />

        {companyType === COMPANY_TYPE.DISTRIBUTOR && (
          <TableRowInfo
            className="text-truncate"
            text={"Manufacturer"}
            subtext={item.manufacturerName}
          />
        )}

        {companyType === COMPANY_TYPE.MANUFACTURER && (
          <TableRowInfo
            className="text-truncate"
            text={"Manufacturer"}
            subtext={item.manufacturerName}
          />
        )}

        {companyType === COMPANY_TYPE.WHOLESALLER && (
          <TableRowInfo
            className="text-truncate"
            text={"Distributor"}
            subtext={item.distributorName}
          />
        )}

        <div className="status">
          <div className="status-list">
            <div className="status-chip">
              {/* QUANTITY */}
              <TableRowInfo
                className="text-truncate"
                text={"Quantity"}
                subtext={item.stockQuantity?.toLocaleString() || "-"}
              />
            </div>
          </div>

          <div className="actions-wrapper">
            {/* STATUS */}
            {!isEmpty(item.status) && getStatus(item.status)}

            {/* ACTIONS */}
            <ContextualModal
              className="feature-actions"
              style={{ marginLeft: 4 }}
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, item)}
                />
              ))}
            />
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

ProductListItem.defaultProps = {
  user: {},
  actions: [],
}

export default ProductListItem
