export default {
  LOCAL_STORAGE: {
    NEXT_ROUTE: "nextRoute",
  },
  DEFAULT_DATE_FORMAT: "LL, HH:mm",
  DATE_FORMAT: "LL",
  SHORT_DATE_FORMAT: 'll',
  DEFAULT_TIME_FORMAT: 'HH:mm',
  DATE_YMD_FORMAT: 'YYYY-MM-DD',
  DATE_TIME_YMDHM_FORMAT: 'YYYY-MM-DD HH:mm',
  DATE_DMY_FORMAT: 'DD/MM/YYYY',
  PDF_DATE_FORMAT: 'DDMM',
  ROLES: {
    ADMIN: "admin",
    USER: "user",
    SUPER_ADMIN: "SUPER_ADMIN",
    COMPANY_ADMIN: "COMPANY_ADMIN",
    ROUTE_AGENT: "ROUTE_AGENT",
    ACCOUNTANT: "ACCOUNTANT",
    MANAGER: "MANAGER",
    VENDOR: "VENDOR",
  },
  COMPANY_TYPE: {
    IMPORTER: "IMPORTER",
    MANUFACTURER: "MANUFACTURER",
    DISTRIBUTOR: "DISTRIBUTOR",
    WHOLESALLER: "WHOLESALLER",
    VENDOR: "VENDOR",
    SUPER_ADMIN: "SUPER_ADMIN",
    KEY_DISTRIBUTOR: "KEY_DISTRIBUTOR",
  },
  MEDIA: {
    MOBILE: "@media (max-width: 574px)",
    TABLET: "@media (max-width: 768px)",
    DESKTOP_S: "@media (max-width: 1200px)",
    DESKTOP_M: "@media (max-width: 1440px)",
    DESKTOP_L: "@media (max-width: 1600px)",
  },
  MEDIA_QUERIES: {
    mediaXS: `only screen and (min-width: 320px)`,
    mediaS: `only screen and (min-width: 576px)`,
    mediaM: `only screen and (min-width: 768px)`,
    mediaL: `only screen and (min-width: 992px)`,
    mediaXL: `only screen and (min-width: 1200px)`,
    mediaXXL: `only screen and (min-width: 1440px)`,
    mdMediaGtXs: `only screen and (min-width: 600px)`,
  },
  SIZE: {
    XXXL: "xxxl",
    XXL: "xxl",
    XL: "xl",
    L: "l",
    M: "m",
    S: "s",
    XS: "xs",
  },
  DIVIDER_ORIENTATION: {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical",
  },
  SELECTION_BOX: {
    unselected: {
      font_primary: "#000000",
      font_secondary: "#000000",
      border: "#000000",
      background: "#ffffff",
      select_all_icon: "#000000",
      select_all_background: "#ffffff",
    },
    selected: {
      font_primary: "#000000",
      font_secondary: "#000000",
      border: "#000000",
      background: "#ffffff",
      icon: "#000000",
      select_all_icon: "#000000",
      select_all_background: "#ffffff",
    },
  },
}
