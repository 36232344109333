import axios from "axios"
import API from "config/api.config"

export default {
  getFeatures(limit = 10, page = 1) {
    return axios
      .get(`${API.getApi().FEATURES.GET_FEATURES}?limit=${limit}&page=${page}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addFeature(values) {
    return axios
      .post(API.getApi().FEATURES.ADD_FEATURE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateFeature(featureId, values) {
    return axios
      .patch(`${API.getApi().FEATURES.UPDATE_FEATURE}/${featureId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
}
