import React, { useState, useRef, useEffect } from "react"
import { Transition } from "react-transition-group"
import styled from "styled-components"
import { isFunction } from "lodash"

import Text from "components/atoms/Text/BasicText"
import CONST from "components/atoms/constants"
import { CONTAINER_TYPE } from "components/Container/Container.styles"
import Container from "components/Container/Container"
import {
  getContainerRadius,
  getCursorStyles,
  getBorderStyles,
  getShadowStyles,
} from "components/Container/Container.styles"
import {
  getContextualRowDefaultStyles,
  getStyledElementWrapper,
  getStyledElement,
  getListItemStyle,
  getContextualIconStyle,
  getStyledButton,
  TRANSITION_STYLES,
  ANIMATION_DURATION,
  CONTEXTUAL_MODAL_POSITIONS,
} from "./ContextualModalBox.styles"

const { FONT: { WEIGHT, VARIANT } } = CONST;

const StyledContextualModal = styled.div.attrs({
  className: "hevara-contextual-modal",
})`
  position: relative;
  width: max-content;
`

const StyledButtonElement = styled.span.attrs({
  className: "hevara-contextual-modal-button",
})`
  ${getStyledButton}
`

const StyledDefaultButtonElement = styled.i.attrs({
  className: "hevara-contextual-modal-button-icon",
})``

const StyledContentElementWrapper = styled.div.attrs({
  className: "hevara-contextual-modal-wrapper",
})`
  ${getStyledElementWrapper}
`

const StyledContentElement = styled.div.attrs({
  className: "hevara-contextual-modal-box",
})`
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px -2px;
  ${getShadowStyles};
  ${getBorderStyles};
  ${getStyledElement};
`

const StyledContextualRow = styled.div.attrs({
  className: "hevara-contextual-modal-row",
})`
  ${getContextualRowDefaultStyles}
`

const StyledIcon = styled.i.attrs({
  className: "hevara-contextual-modal-icon",
})`
  ${getContextualIconStyle}
`

const StyledTextWrapper = styled.span.attrs({
  className: "hevara-contextual-modal-text-info",
})`
  ${getListItemStyle};
`

export const ContextualModalListItem = ({
  variant,
  leftIcon,
  label,
  onClick,
  disabled,
  selected,
  className,
  style,
}) => {
  const handleOnClick = event => {
    event?.preventDefault()

    if (isFunction(onClick) && !disabled) {
      onClick(event)
    }
  }

  return (
    <StyledContextualRow
      disabled={disabled}
      data-disabled={disabled}
      selected={selected}
      className={className}
      style={style}
      onClick={handleOnClick}
    >
      {!!leftIcon && <i className={leftIcon + " fw-bolder font-size-20"} />}

      <StyledTextWrapper variant={variant}>
        <Text
          weight={WEIGHT.SEMI_BOLD}
          variant={VARIANT.TEXT}
          className="contextual-modal-text"
        >
          {label}
        </Text>
      </StyledTextWrapper>
    </StyledContextualRow>
  )
}

ContextualModalListItem.defaultProps = {
  disabled: false,
}

const ContextualModal = ({
  buttonElement,
  contentElement,
  disabled,
  onClick,
  position,
  className,
  style,
}) => {
  const [modalState, setModalState] = useState(false)
  const [safePosition, setSafePosition] = useState(position)
  const wrapperRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    if (modalState) {
      getModalPosition()
    }
  }, [modalState])

  // ON BLUR - closes contextual modal when blurs outside component
  const onBlur = event => {
    // @ts-ignore
    if (!event?.currentTarget?.contains(event.relatedTarget)) {
      handleMenuClick(event)
    }
  }

  // By default has a position aligned to left
  const getModalPosition = () => {
    let placement = position
    const modalOffsets = wrapperRef?.current
    const containerOffset = containerRef?.current?.clientWidth
    if (modalOffsets && containerOffset) {
      const offsetFromLeft = modalOffsets.getBoundingClientRect().left || 0
      const offsetFromRight =
        window.innerWidth - (offsetFromLeft + modalOffsets?.offsetWidth)
      // Check if it fits on the left corner
      const fitLeft = offsetFromLeft > containerOffset
      // Check if it fits on the right corner
      const fitRight = offsetFromRight > containerOffset
      // If position pram and there is not enough space
      if (
        (position === CONTEXTUAL_MODAL_POSITIONS.RIGHT || !position) &&
        !fitRight &&
        fitLeft
      ) {
        placement = CONTEXTUAL_MODAL_POSITIONS.LEFT
      } else if (
        (position === CONTEXTUAL_MODAL_POSITIONS.LEFT || !position) &&
        !fitLeft &&
        fitRight
      ) {
        placement = CONTEXTUAL_MODAL_POSITIONS.RIGHT
      }
      setSafePosition(placement)
    }
  }

  // Change modal state and pass to parent the new modal state in case it needs it
  // for example to toggle an arrow up or down status
  const handleMenuClick = (event, newState = false) => {
    setModalState(newState)
    if (isFunction(onClick)) {
      onClick(newState)
    }
    event.stopPropagation()
    event.preventDefault()
  }

  // Close the modal when clicking on a valid list element
  const onElementClick = event => {
    // @ts-ignore
    const att = event?.target?.getAttribute("data-disabled")
    if (att === "false") {
      handleMenuClick(event)
    }
  }

  return (
    <StyledContextualModal
      data-testid="contextual-modal-box"
      tabIndex={-1}
      // @ts-ignore
      onBlur={onBlur}
      ref={wrapperRef}
      className={className}
      style={style}
    >
      <StyledButtonElement
        onClick={event => !disabled && handleMenuClick(event, !modalState)}
        disabled={disabled}
      >
        {buttonElement}
      </StyledButtonElement>
      <Transition
        nodeRef={containerRef}
        in={modalState}
        timeout={ANIMATION_DURATION}
        unmountOnExit={true}
      >
        {state => (
          <StyledContentElementWrapper
            data-testid="contextual-modal-wrapper"
            style={{
              ...TRANSITION_STYLES[state],
            }}
            animationType={modalState}
            position={safePosition}
            // @ts-ignore
            onClick={onElementClick}
            ref={containerRef}
          >
            <StyledContentElement shadowType={2} type={CONTAINER_TYPE.SHADOW}>
              {contentElement}
            </StyledContentElement>
          </StyledContentElementWrapper>
        )}
      </Transition>
    </StyledContextualModal>
  )
}

ContextualModal.defaultProps = {
  disabled: false,
  buttonElement: (
    <i className="mdi mdi-dots-vertical me-1 fw-bolder font-size-20" />
  ),
}

export default ContextualModal