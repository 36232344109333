import React from "react"
import styled from "styled-components"
import { isEmpty } from "lodash"

import { HeadingM } from "components/atoms"
import CONST from "utils/constants"
import Divider from "components/Divider/Divider"
import Avatar from "components/Avatar/Avatar"
import {
  getPageHeaderActionsStyles,
  getPageHeaderContentWrapperStyles,
  getPageHeaderInfoStyles,
  getPageHeaderInfoText,
  getPageHeaderStyles,
  getPageHeaderWrapper,
  getPageHeaderSubWrapper,
  getPageHeaderTitle,
  getAvatarStyles,
} from "./PageHeader.styles"

const { SIZE } = CONST

const StyledPageHeader = styled.div.attrs({
  className: "hevara-page-header",
})`
  ${getPageHeaderStyles}
`

const StyledContentWrapper = styled.div.attrs({
  className: "hevara-page-header-content-wrapper",
})`
  ${getPageHeaderContentWrapperStyles}
`

const StyledPageHeaderActions = styled.div.attrs({
  className: "hevara-page-header-actions",
})`
  ${getPageHeaderActionsStyles}
`

const StyledPageHeaderInfo = styled.div.attrs({
  className: "hevara-page-header-info",
})`
  ${getPageHeaderInfoStyles}
`

const StyledTitle = styled(HeadingM).attrs({
  className: "hevara-page-header-title",
})`
  ${getPageHeaderTitle}
`

const StyledWrapper = styled.div.attrs({
  className: "hevara-page-header-wrapper",
})`
  ${getPageHeaderWrapper}
`

const StyledSubWrapper = styled.div.attrs({
  className: "hevara-page-header-sub-wrapper",
})`
  ${getPageHeaderSubWrapper}
`

const StyledInfoText = styled.div.attrs({
  className: "hevara-page-header-info-text",
})`
  ${getPageHeaderInfoText}
`

const StyledAvatar = styled(Avatar).attrs({
  className: "hevara-page-header-avatar",
})`
  ${getAvatarStyles}
`

export const PageHeaderActions = ({ children, className, style }) => {
  return (
    <StyledPageHeaderActions className={className} style={style}>
      {children}
    </StyledPageHeaderActions>
  )
}

export const PageHeaderInfoText = ({ children, className, style }) => {
  return (
    <StyledInfoText className={className} style={style}>
      {children}
    </StyledInfoText>
  )
}

export const PageHeaderInfo = ({ children, className, style }) => {
  return (
    <StyledPageHeaderInfo className={className} style={style}>
      {children}
    </StyledPageHeaderInfo>
  )
}

export const PageHeader = ({
  avatar,
  title,
  actions,
  info,
  showDivider,
  className,
  style,
  avoidtruncate,
}) => {
  return (
    <StyledPageHeader className={className} style={style}>
      <StyledContentWrapper>
        <StyledWrapper>
          {!isEmpty(avatar) && <StyledAvatar {...avatar} size={SIZE.XXL} />}
          <StyledSubWrapper>
            {/* TITLE */}
            <StyledTitle avoidtruncate={true}>
              {title}
            </StyledTitle>
            {/* INFO ELEMENTS */}
            {info}
          </StyledSubWrapper>
        </StyledWrapper>

        {/* ACTION ELEMENTS */}
        {actions}
      </StyledContentWrapper>
      {/* DIVIDER */}
      {showDivider && <Divider className="mt-4" />}
    </StyledPageHeader>
  )
}

PageHeader.defaultProps = {
  avatar: {},
  actions: undefined,
  info: undefined,
  showDivider: true,
  className: "",
  style: {},
}