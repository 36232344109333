import React, { useState } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useNavigate, useLocation } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import Auth from "models/auth"
import Footer from "components/Footer"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { PrimaryButton } from "components/Button"

const ResetPassword = props => {
  //meta title
  document.title = "Reset Password | hevara - DMS"

  const history = useNavigate()
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [recoverSuccessMsg, setRecoverSuccessMsg] = useState("")
  const [recoverErrorMsg, setRecoverErrorMsg] = useState("")
  const [loading, setLoading] = useState(false)

  // Read token value from url
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get("token")

  const recoverPassword = values => {
    setLoading(true)
    Auth.recoverPassword(token, values.password)
      .then(data => {
        setRecoverSuccessMsg(data.message)
        setTimeout(() => {
          history("/login")
        }, 3500)
      })
      .catch(error => {
        setRecoverErrorMsg(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      password2: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter your password"),
      password2: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please re-enter your password"),
    }),
    onSubmit: values => {
      if (values.password !== values.password2) {
        validation.setFieldError("password2", "Passwords must match")
      } else {
        recoverPassword(values)
      }
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-dark p-4">
                        <h5
                          className="text-dark"
                          style={{ fontWeight: "bold" }}
                        >
                          Password recovery
                        </h5>
                        <p>Please enter your new password</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {(recoverErrorMsg || recoverSuccessMsg) && (
                      <Alert
                        color={recoverErrorMsg ? "danger" : "success"}
                        style={{ marginTop: "13px" }}
                      >
                        {recoverErrorMsg
                          ? recoverErrorMsg
                          : recoverSuccessMsg
                          ? recoverSuccessMsg
                          : ""}
                      </Alert>
                    )}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />

                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Repeat password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password2"
                            value={validation.values.password2 || ""}
                            type={show2 ? "text" : "password"}
                            placeholder="Repeat password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password2 &&
                              validation.errors.password2
                                ? true
                                : false
                            }
                          />

                          <button
                            onClick={() => setShow2(!show2)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password2 &&
                        validation.errors.password2 ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password2}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="d-flex flex-column align-items-center">
                        <PrimaryButton type="submit" className="w-lg">
                          {loading ? "Processing..." : "Confirm"}
                        </PrimaryButton>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <Footer />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPassword)
