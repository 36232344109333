import React, { useEffect, useState } from "react"
import { chain, isEmpty, omit, orderBy } from "lodash"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import Auth from "models/auth"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import PaymentMethod from "models/paymentMethod"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import {
  PrimaryButton,
  SecondaryButton,
  BUTTON_VARIANT,
} from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import PaymentMethodListItem from "components/itemList/paymentMethodListItem/paymentMethodListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"

const PaymentMethodList = props => {
  //meta title
  document.title = "Payment methods | hevara admin"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [paymentMethods, setPaymentMethods] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [paymentMethodList, setPaymentMethodList] = useState([])
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    PaymentMethod.getPaymentMethods(limit, page)
      .then(data => {
        setPaymentMethods(data.results)
        setTotalPages(data.totalPages)
        setTotalResults(data.totalResults)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const addPaymentMethod = values => {
    setProcessing(true)
    PaymentMethod.addPaymentMethod(values)
      .then(paymentMethod => {
        setPaymentMethods([...paymentMethods, paymentMethod])
        toast.success(`${paymentMethod.name} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updatePaymentMethod = (paymentMethodId, values) => {
    setProcessing(true)
    PaymentMethod.updatePaymentMethod(paymentMethodId, values)
      .then(paymentMethod => {
        setPaymentMethods(
          paymentMethods.map(item =>
            item.id.toString() === paymentMethod.id.toString()
              ? { item, ...paymentMethod }
              : item
          )
        )
        toast.success(`${paymentMethod.name} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enablePaymentMethod = values => {
    setProcessing(true)
    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "Active",
    }
    PaymentMethod.updatePaymentMethod(values.id, payload)
      .then(paymentMethod => {
        setPaymentMethods(
          paymentMethods.map(item =>
            item.id.toString() === paymentMethod.id.toString()
              ? { ...paymentMethod }
              : item
          )
        )
        toast.success(`${paymentMethod.name} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disablePaymentMethod = values => {
    setProcessing(true)
    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "Disabled",
    }
    PaymentMethod.updatePaymentMethod(values.id, payload)
      .then(paymentMethod => {
        setPaymentMethods(
          paymentMethods.map(item =>
            item.id.toString() === paymentMethod.id.toString()
              ? { ...paymentMethod }
              : item
          )
        )
        toast.success(`${paymentMethod.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      fetchData(limit, page)
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setPaymentMethodList(paymentMethods)
  }, [paymentMethods])

  useEffect(() => {
    if (!isEmpty(paymentMethods) && !!isEdit) {
      setPaymentMethodList(paymentMethods)
      setIsEdit(false)
    }
  }, [paymentMethods])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (paymentMethod && paymentMethod.name) || "",
      status: (paymentMethod && paymentMethod.status) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter payment method name"),
      status: Yup.string().required("Please enter payment method status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update Country
        updatePaymentMethod(paymentMethod.id, values)
        validation.resetForm()
      } else {
        // add new Country
        addPaymentMethod(values)
        validation.resetForm()
      }
      toggle()
    },
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setPaymentMethod(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const handleClick = arg => {
    const paymentMethod = arg
    setPaymentMethod(paymentMethod)
    setIsEdit(true)
    toggle()
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "Edit details",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive ? disablePaymentMethod(item) : enablePaymentMethod(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "New payment method",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickNewPaymentMethod = () => {
    setIsEdit(false)
    setModal(true)
  }

  const filteredPaymentMethods = chain(paymentMethods)
    .filter(paymentMethod => {
      // Name
      const hasName = paymentMethod.name
        ?.toLowerCase()
        .includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(
    filteredPaymentMethods,
    [p => p.name.toUpperCase()],
    "asc"
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Payment methods"
            breadcrumbItem="Payment methods"
          />

          {/* Loading */}
          {loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Payment methods"}
            description={"We are processing your request, please wait a moment..."}
          />

          {/* LIST */}
          {!loading && !isEmpty(paymentMethods) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Payment methods"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText className="fw-semibold">
                            {`${paymentMethods.length} ${
                              paymentMethods.length > 1
                                ? "Payment methods"
                                : "Payment method"
                            }`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "name", true)}
                      renderItem={item => (
                        <PaymentMethodListItem
                          item={item}
                          actions={getListActions(item)}
                          permission={userPermissions.MANAGE_GLOBAL_SETTINGS}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(paymentMethods) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                visible={isEmpty(paymentMethods)}
                title={`No payment methods`}
                description={`No payment methods have been added yet`}
                firstButton={
                  userPermissions.MANAGE_GLOBAL_SETTINGS && {
                    label: `New payment method`,
                    onClick: onClickNewPaymentMethod,
                    disabled: processing,
                  }
                }
              />
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Country Modal */}
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit payment method" : "New payment method"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Payment method name</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Payment method name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Status</Label>
                      <Input
                        name="status"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ""}
                        invalid={
                          validation.touched.status && validation.errors.status
                            ? true
                            : false
                        }
                      >
                        <option>Select status</option>
                        <option value="Active">Active</option>
                        <option value="Disabled">Disabled</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback status="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <SecondaryButton
                        type="button"
                        onClick={toggle}
                        className="me-4"
                      >
                        Cancel
                      </SecondaryButton>

                      <PrimaryButton type="submit">
                        {!!isEdit
                          ? "Update payment method"
                          : "Add payment method"}
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default withTranslation()(PaymentMethodList)
