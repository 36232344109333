import React from "react"
import styled from "styled-components"
import { isEmpty } from "lodash"

import { BodyTextBold, SmallText } from "components/atoms"
import Avatar from "components/Avatar/Avatar"
import {
  getMainInfoStyles,
  getAvatarStyles,
  getTitleStyles,
  getInfoStyles,
  getSubtitleStyles,
} from "./MainInfoList.styles"
import CONST from "utils/constants"

const { SIZE } = CONST

const StyledMainInfo = styled.div.attrs({
  className: "hevara-table-row-main-info",
})`
  ${getMainInfoStyles}
`

const StyledAvatar = styled(Avatar).attrs({
  className: "hevara-table-row-main-info-avatar",
})`
  ${getAvatarStyles}
`

const StyledTitle = styled(BodyTextBold).attrs({
  className: "hevara-table-row-main-info-title text-truncate",
})`
  ${getTitleStyles}
`

const StyledSubtitle = styled(SmallText).attrs({
  className: "hevara-table-row-main-info-subtitle",
})`
  ${getSubtitleStyles}
`

const StyledInfo = styled.div`
  ${getInfoStyles}
`

const MainInfoList = ({ avatar, title, subtitle, style, className }) => {
  return (
    <StyledMainInfo style={style} className={className}>
      {!isEmpty(avatar) && <StyledAvatar {...avatar} size={SIZE.L} />}
      <StyledInfo>
        <StyledTitle>{title}</StyledTitle>
        {!!subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      </StyledInfo>
    </StyledMainInfo>
  )
}

MainInfoList.defaultProps = {
  avatar: undefined,
}

export { MainInfoList }