import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import moment from "moment"
import { Card, CardBody, Container, Row } from "reactstrap"

import Fleet from "models/fleet"
import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import ErrorState from "components/ErrorState/errorState"
import Auth from "models/auth"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import Link from "components/Link"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { getStatus } from "utils/utils"

import "./style.scss"

//import images
import vehicleImage from "assets/images/vehicle.png"

const VehicleDetails = props => {
  // meta title
  document.title = "Vehicle details | hevara-DMS"

  const { vehicleId } = useParams()

  const [fleetDetails, setFleetDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchVehicleData = id => {
    Fleet.getFleet(id)
      .then(data => {
        setFleetDetails(data)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (vehicleId && profile) {
      fetchVehicleData(vehicleId)
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [vehicleId])

  const getHeaderActions = () => {
    const canManageFleets = userPermissions.CAN_MANAGE_FLEETS
    const isActive = fleetDetails.status === "Active"

    const actions = [
      canManageFleets && {
        label: "Edit",
        icon: "mdi mdi-pencil",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      canManageFleets && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? console.log("WIP") : console.log("WIP")),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Vehicle details")}
            breadcrumbItem={props.t("Vehicle details")}
          />

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Vehicle details"}
            description={"We are processing your request, please wait"}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {!error && !loading && !isEmpty(fleetDetails) && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    avatar={{
                      initials: fleetDetails.registrationNumber,
                      image: vehicleImage,
                    }}
                    title={fleetDetails.registrationNumber}
                    showBackButton={true}
                    showDivider={false}
                    actions={<HeaderActions actions={getHeaderActions()} />}
                    info={
                      <PageHeaderInfo>
                        <div className="mt-2">
                          {getStatus(fleetDetails.status)}
                        </div>
                        <PageHeaderInfoText className="fw-semibold">
                          {fleetDetails.vehicleTypeName}
                        </PageHeaderInfoText>

                        <PageHeaderInfoText className="fw-semibold">
                          {moment(fleetDetails.dateCreated).format("LL")}
                        </PageHeaderInfoText>
                      </PageHeaderInfo>
                    }
                  />

                  <div className="hevara-fleet-data">
                    <div className="tx-grid mb-3">
                      <div className="mb-4">
                        <h5 className="text-muted mb-4">Vehicle details</h5>

                        <div className="detail-grid">
                          <DataItem
                            label={props.t("Driver")}
                            value={fleetDetails.routeAgentName}
                            avatar={{ icon: "mdi mdi-account" }}
                          >
                            <Link
                              to={`/user-details/${fleetDetails.routeAgent}`}
                            >
                              View driver
                            </Link>
                          </DataItem>

                          <DataItem
                            label={props.t("Company")}
                            value={fleetDetails.companyName}
                            avatar={{ icon: "mdi mdi-office-building-outline" }}
                          />

                          <DataItem
                            label={props.t("Warehouse")}
                            value={
                              fleetDetails.warehouseName
                                ? fleetDetails.warehouseName
                                : "Global"
                            }
                            avatar={{ icon: "mdi mdi-warehouse" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

VehicleDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(VehicleDetails)
