import React from "react"
import { isEmpty, map } from "lodash"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getStatus } from "utils/utils"

import "./featureListItem.scss"

const FeatureListItem = ({ item, actions, permission }) => {
  return (
    <TableRow>
      <div key={item.id} className="hevara-feature-list-item">
        {/* MAIN INFO */}
        <MainInfoList
          title={item.label}
          avatar={{ image: item.img, initials: item.label }}
          className="text-truncate"
          subtitle={item.status}
        />

        <div className="status">
          <div className="title-and-date">
            {/* CODE */}
            <TableRowInfo
              className="text-truncate"
              subtext={item.value}
            />
          </div>

          <div className="status-and-actions">
            {/* IS AVAILABLE */}
            {!isEmpty(item.isAvailable) && getStatus(item.isAvailable)}

            {/* ACTIONS */}
            {permission && (
              <ContextualModal
                className="feature-actions"
                style={{ marginLeft: 4 }}
                position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
                contentElement={map(actions, (modalItem, index) => (
                  <ContextualModalListItem
                    key={index}
                    {...modalItem}
                    onClick={e => modalItem.onClick(e, item)}
                  />
                ))}
              />
            )}
          </div>
        </div>
      </div>
    </TableRow>
  )
}

FeatureListItem.defaultProps = {
  item: {},
  actions: [],
}

export default FeatureListItem
