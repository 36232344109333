import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"

const ProductListModal = ({
  modalIsOpen,
  productCategories,
  productTypes,
  productList,
  isEdit,
  updateProductList,
  addProductList,
  toggle,
}) => {
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (productList && productList.name) || "",
      description: (productList && productList.description) || "",
      category: (productList && productList.category) || "",
      type: (productList && productList.type) || "",
      size: (productList && productList.size) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      description: Yup.string().required("This field is required"),
      category: Yup.string().required("This field is required"),
      type: Yup.string().required("This field is required"),
      size: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updateProductList(productList.id, values)
      } else {
        // create new
        addProductList(values)
      }

      toggle()
      validation.resetForm()
    },
  })

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={toggle}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={!!isEdit ? `Edit ${productList?.name} details` : "New master product"}
    >
      <div className="mt-2">
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col className="col-12">
              {/* PRODUCT NAME */}
              <div className="mb-3">
                <Label className="form-label">Product name</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder="Product name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={validation.touched.name && validation.errors.name ? true : false}
                />
                {validation.touched.name && validation.errors.name && (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                )}
              </div>

              {/* PRODUCT CATEGORY */}
              <div className="mb-3">
                <Label className="form-label">Product category</Label>
                <Input
                  name="category"
                  type="select"
                  placeholder="Select category"
                  defaultValue={"Select category"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.category || ""}
                  invalid={validation.touched.category && validation.errors.category ? true : false}
                >
                  {productCategories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Input>
                {validation.touched.category && validation.errors.category && (
                  <FormFeedback type="invalid">
                    {validation.errors.category}
                  </FormFeedback>
                )}
              </div>

              {/* PRODUCT TYPE */}
              <div className="mb-3">
                <Label className="form-label">Product type</Label>
                <Input
                  name="type"
                  type="select"
                  placeholder="Select product type"
                  defaultValue={"Select product type"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.type || ""}
                  invalid={validation.touched.type && validation.errors.type ? true : false}
                >
                  {productTypes.map(type => (
                    <option key={type.id} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </Input>
                {validation.touched.type &&
                validation.errors.type ? (
                  <FormFeedback type="invalid">
                    {validation.errors.type}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT SIZE (ml's) */}
              <div className="mb-3">
                <Label className="form-label">Product size (milliliters)</Label>
                <Input
                  name="size"
                  className="form-control"
                  placeholder="Product size (milliliters)"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.size || ""}
                  invalid={validation.touched.size && validation.errors.size ? true : false}
                />
                {validation.touched.size && validation.errors.size && (
                  <FormFeedback type="invalid">
                    {validation.errors.size}
                  </FormFeedback>
                )}
              </div>

              {/* PRODUCT DESCRIPTION */}
              <div className="mb-3">
                <Label className="form-label">Product description</Label>
                <Input
                  name="description"
                  className="form-control"
                  placeholder="Product description"
                  type="textarea"
                  rows="5"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={validation.touched.description && validation.errors.description ? true : false}
                />
                {validation.touched.description && validation.errors.description && (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                )}
              </div>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                <SecondaryButton
                  type="button"
                  onClick={toggle}
                  className="me-4"
                >
                  Cancel
                </SecondaryButton>

                <PrimaryButton type="submit">
                  Confirm
                </PrimaryButton>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </LargeModal>
  )
}

export default ProductListModal
