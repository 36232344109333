import axios from "axios"
import API from "config/api.config"

export default {
  getTowns(limit = 10, page = 1) {
    return axios
      .get(`${API.getApi().TOWNS.GET_TOWNS}?limit=${limit}&page=${page}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addTown(values) {
    return axios
      .post(API.getApi().TOWNS.ADD_TOWN, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateTown(countryId, values) {
    return axios
      .patch(`${API.getApi().TOWNS.UPDATE_TOWN}/${countryId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
}
