import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getContacts(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      name: filters.name,
      email: filters.email,   
      message:filters.message,
      subject: filters.subject,
      date: filters.date,
      phone: filters.phone,
    
    }
    const url = queryParams(API.getApi().CONTACT.GET_CONTACTS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getContactDetails(contactId) {
    return axios
      .get(`${API.getApi().CONTACT.GET_CONTACT}/${contactId}`)
      .then(async ({ data }) => {
        return data
      })
  }
}
