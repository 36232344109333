import axios from "axios"
import API from "config/api.config"

export default {
  getStockStatus(limit = 10, page = 1) {
    return axios
      .get(
        `${
          API.getApi().STOCK_STATUS.GET_STOCK_STATUS
        }?limit=${limit}&page=${page}`
      )
      .then(async ({ data }) => {
        return data
      })
  },
  addStockStatus(values) {
    return axios
      .post(API.getApi().STOCK_STATUS.ADD_STOCK_STATUS, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateStockStatus(stockStatusId, values) {
    return axios
      .patch(
        `${API.getApi().STOCK_STATUS.UPDATE_STOCK_STATUS}/${stockStatusId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
}
