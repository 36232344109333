import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getProductLists(limit = 10, page = 1, filters = {}) {
    const parameters = {
      name: filters.name,
      category: filters.category,
      type: filters.type,
      size: filters.size,
      status: filters.status,
      limit,
      page,
    }
    const url = queryParams(API.getApi().PRODUCT_MASTER_LIST.GET_PRODUCT_LISTS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getProductList(productListId) {
    return axios.get(`${API.getApi().PRODUCT_MASTER_LIST.GET_PRODUCT_LIST}/${productListId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addProductList(values) {
    return axios.post(API.getApi().PRODUCT_MASTER_LIST.ADD_PRODUCT_LIST, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateProductList(productListId, values) {
    return axios.patch(`${API.getApi().PRODUCT_MASTER_LIST.UPDATE_PRODUCT_LIST}/${productListId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  deleteProductList(productListId) {
    return axios.delete(`${API.getApi().PRODUCT_MASTER_LIST.DELETE_PRODUCT_LIST}/${productListId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}
