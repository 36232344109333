import React, { useEffect, useState } from "react"
import { isEmpty, omit, orderBy, chain } from "lodash"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import OrderType from "models/orderType"
import Auth from "models/auth"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import {
  PrimaryButton,
  SecondaryButton,
  BUTTON_VARIANT,
} from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import OrderTypeListItem from "components/itemList/orderTypeListItem/orderTypeListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"

const OrderTypeList = props => {
  //meta title
  document.title = "Order types | hevara admin"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [orderTypes, setOrderTypes] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [orderTypeList, setOrderTypeList] = useState([])
  const [orderType, setOrderType] = useState(null)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    OrderType.getOrderTypes(limit, page)
      .then(data => {
        setOrderTypes(data.results)
        setTotalPages(data.totalPages)
        setTotalResults(data.totalResults)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const addOrderType = values => {
    setProcessing(true)
    OrderType.addOrderType(values)
      .then(orderType => {
        setOrderTypes([...orderTypes, orderType])
        toast.success(`${orderType.label} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateOrderType = (orderTypeId, values) => {
    setProcessing(true)
    OrderType.updateOrderType(orderTypeId, values)
      .then(orderType => {
        setOrderTypes(
          orderTypes.map(item =>
            item.id.toString() === orderType.id.toString()
              ? { item, ...orderType }
              : item
          )
        )
        toast.success(`${orderType.label} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableOrderType = values => {
    setProcessing(true)
    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "Active",
    }
    OrderType.updateOrderType(values.id, payload)
      .then(orderType => {
        setOrderTypes(
          orderTypes.map(item =>
            item.id.toString() === orderType.id.toString()
              ? { ...orderType }
              : item
          )
        )
        toast.success(`${orderType.label} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableOrderType = values => {
    setProcessing(true)
    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "Disabled",
    }
    OrderType.updateOrderType(values.id, payload)
      .then(orderType => {
        setOrderTypes(
          orderTypes.map(item =>
            item.id.toString() === orderType.id.toString()
              ? { ...orderType }
              : item
          )
        )
        toast.success(`${orderType.label} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      fetchData(limit, page)
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setOrderTypeList(orderTypes)
  }, [orderTypes])

  useEffect(() => {
    if (!isEmpty(orderTypes) && !!isEdit) {
      setOrderTypeList(orderTypes)
      setIsEdit(false)
    }
  }, [orderTypes])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      label: (orderType && orderType.label) || "",
      value: (orderType && orderType.value) || "",
    },
    validationSchema: Yup.object({
      label: Yup.string().required("Please enter order status label"),
      value: Yup.string().required("Please enter order status value"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updateOrderType(orderType.id, values)
        validation.resetForm()
      } else {
        // create new
        addOrderType(values)
        validation.resetForm()
      }
      toggle()
    },
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setOrderType(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const handleClick = arg => {
    const orderType = arg
    setOrderType(orderType)
    setIsEdit(true)
    toggle()
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "Edit details",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive ? disableOrderType(item) : enableOrderType(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "New order type",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickNewOrderType = () => {
    setIsEdit(false)
    setModal(true)
  }

  const filteredOrderTypes = chain(orderTypes)
    .filter(type => {
      // Label
      const hasLabel = type.label?.toLowerCase().includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(filteredOrderTypes, [p => p.label.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Order types" breadcrumbItem="Order types" />

          {/* Loading */}
          {loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Order type"}
            description={
              "We are processing your request, please wait a moment..."
            }
          />

          {/* LIST */}
          {!loading && !isEmpty(orderTypes) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Order types"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText className="fw-semibold">
                            {`${orderTypes.length} ${
                              orderTypes.length > 1 ? "Types" : "Type"
                            }`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "label", true)}
                      renderItem={item => (
                        <OrderTypeListItem
                          item={item}
                          actions={getListActions(item)}
                          permission={userPermissions.MANAGE_GLOBAL_SETTINGS}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(orderTypes) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                visible={isEmpty(orderTypes)}
                title={`No order type`}
                description={`No order type have been added yet`}
                firstButton={
                  userPermissions.MANAGE_GLOBAL_SETTINGS && {
                    label: `New order type`,
                    onClick: onClickNewOrderType,
                    disabled: processing,
                  }
                }
              />
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Modal */}
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit order type" : "New order type"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Order type label</Label>
                      <Input
                        name="label"
                        type="text"
                        placeholder="Label"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.label || ""}
                        invalid={
                          validation.touched.label && validation.errors.label
                            ? true
                            : false
                        }
                      />
                      {validation.touched.label && validation.errors.label ? (
                        <FormFeedback type="invalid">
                          {validation.errors.label}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Order type value</Label>
                      <Input
                        name="value"
                        type="text"
                        placeholder="Value"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.value || ""}
                        invalid={
                          validation.touched.value && validation.errors.value
                            ? true
                            : false
                        }
                      />
                      {validation.touched.value && validation.errors.value ? (
                        <FormFeedback type="invalid">
                          {validation.errors.value}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <SecondaryButton
                        type="button"
                        onClick={toggle}
                        className="me-4"
                      >
                        Cancel
                      </SecondaryButton>

                      <PrimaryButton type="submit">
                        {!!isEdit ? "Update order type" : "Add order type"}
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default withTranslation()(OrderTypeList)
