import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

const headers = {
  headers: { "Content-Type": "multipart/form-data" },
}

export default {
  getUploads(limit = 10, page = 1, filters = {}) {
    const parameters = {
      productId: filters.productId,
      userId: filters.userId,
      fileType: filters.fileType,
      limit,
      page,
    }
    const url = queryParams(API.getApi().UPLOADS.GET_UPLOADS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getUpload(uploadId) {
    return axios.get(`${API.getApi().UPLOADS.GET_UPLOAD}/${uploadId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addUpload(values) {
    return axios.post(API.getApi().UPLOADS.ADD_UPLOAD, values, headers)
      .then(async ({ data }) => {
        return data
      })
  },
  updateUpload(uploadId, values) {
    return axios.patch(`${API.getApi().UPLOADS.UPDATE_UPLOAD}/${uploadId}`, values, headers)
      .then(async ({ data }) => {
        return data
      })
  },
  deleteUpload(uploadId) {
    return axios.delete(`${API.getApi().UPLOADS.DELETE_UPLOAD}/${uploadId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}
