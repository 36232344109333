import React from "react"
import { map } from "lodash"
import moment from "moment"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import CONST from "utils/constants"

const { DATE_TIME_YMDHM_FORMAT } = CONST

import "./waitlistListItem.scss"

const WaitlistListItem = ({ item, actions, permission }) => {
  
  

  return (
    <TableRow>
      <div key={item.id} className="hevara-waitlist-list-item">
        {/* MAIN INFO */}
        <MainInfoList
          title={item.name}
          subtitle="Company website" 
          avatar={{ image: item.img, initials: item.name }}
          className="text-truncate"
        />

        <div className="date-item">
          {/* EMAIL ADDRESS */}
          <div className="title-and-date">
            <TableRowInfo
              className="text-truncate"
              text={"Email address"}
              subtext={item.email}
            />
          </div>
          <div className="title-and-date">
          <TableRowInfo
              className="text-truncate"
              text={"Date & Time"}
              subtext={moment(item.date).format(DATE_TIME_YMDHM_FORMAT)}
            />
          </div>

          <div className="status-and-actions">
            {/* ACTIONS */}
            {permission && (
              <ContextualModal
                className="item-actions"
                style={{ marginLeft: 4 }}
                position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
                contentElement={map(actions, (modalItem, index) => (
                  <ContextualModalListItem
                    key={index}
                    {...modalItem}
                    onClick={e => modalItem.onClick(e, item)}
                  />
                ))}
              />
            )}
          </div>
        </div>
      </div>
    </TableRow>
  )
}

WaitlistListItem.defaultProps = {
  item: {},
  actions: [],
}

export default WaitlistListItem
