import CONST from "utils/constants"

const { SIZE } = CONST

export const getAvatarIconSizes = size => {
  switch (size) {
    case SIZE.XXXL:
      return "42"
    case SIZE.XXL:
      return "28"
    case SIZE.XL:
      return "24"
    case SIZE.L:
      return "20"
    case SIZE.M:
    case SIZE.S:
    default:
      return "16"
  }
}

export const getAvatarTextSizes = ({ size }) => {
  switch (size) {
    case SIZE.S:
      return `font-size: 10px;
              line-height: 16px;`
    case SIZE.M:
      return `font-size: 12px;
              line-height: 16px;`
    case SIZE.XXL:
    case SIZE.XXXL:
      return `font-size: 28px;
              line-height: 36px;`
    case SIZE.L:
    case SIZE.XL:
    default:
      return `font-size: 14px;
              line-height: 20px;`
  }
}

export const getAvatarSize = ({ size }) => {
  switch (size) {
    case SIZE.S: {
      return `width: 24px;
              height: 24px;
              min-width: 24px;
              min-height: 24px;`
    }
    case SIZE.L: {
      return `width: 40px;
              height: 40px;
              min-width: 40px;
              min-height: 40px;`
    }
    case SIZE.XL: {
      return `width: 48px;
              height: 48px;
              min-width: 48px;
              min-height: 48px;`
    }
    case SIZE.XXL: {
      return `width: 72px;
              height: 72px;
              min-width: 72px;
              min-height: 72px;`
    }
    case SIZE.XXXL: {
      return `width: 94px;
              height: 94px;
              min-width: 94px;
              min-height: 94px;`
    }
    case SIZE.M:
    default: {
      return `width: 32px;
              height: 32px;
              min-width: 32px;
              min-height: 32px;`
    }
  }
}

export const getAvatarRadius = ({ size }) => {
  if (size === SIZE.S || size === SIZE.M) {
    return `border-radius: 8px;`
  } else {
    return `border-radius: 12px;`
  }
}

export const getAvatarDefaultStyles = ({
  backgroundColor,
  color,
  ...props
}) => {
  const background = !!props.icon ? "#f2f2f5" : "#50a5f1"

  return `
    ${!props.image ? `background-color: ${backgroundColor || background};` : ``}
    .hevara-avatar-text {
      color: ${color || "#FFFFFF"}
    }
    .hevara-avatar-icon {
      fill: ${color || "#F6CF0B"}
    }
  `
}
