import React from "react"
import { Navigate } from "react-router-dom"

// NoneAuthentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "pages/Authentication/ResetPassword"
import AccountSetup from "pages/Authentication/AccountSetup"

// Authenticated related pages
import Dashboard from "../pages/Dashboard/index"
import CountryList from "pages/Countries"
import TownList from "pages/Towns"
import PaymentMethodList from "pages/PaymentMethods"
import VehicleTypeList from "pages/VehicleTypes"
import ProductCategoryList from "pages/ProductCategories"
import CompanyTypeList from "pages/CompanyType"
import StockStatusList from "pages/StockStatus"
import FeatureList from "pages/Features"
import PermissionList from "pages/Permissions"
import OrderStatusList from "pages/OrderStatus"
import RoleList from "pages/Roles"
import CompanyRoleList from "pages/Company/CompanyRoles"
import WarehouseList from "pages/Company/Warehouse"
import CompanyList from "pages/Company"
import CompanyDetails from "pages/Company/companyDetails"
import UserDetails from "pages/Company/Users/userDetails"
import FleetList from "pages/Company/Fleets"
import PaymentConfigs from "pages/Company/PaymentConfigs"
import Waitlist from "pages/Waitlist"
import Contact from "pages/Contact"
import ContactDetails from "pages/Contact/contactDetails"
import Notifications from "pages/Notifications"
import ProductCatalogue from "pages/Company/ProductCatalogue"
import ProductCatalogueDetails from "pages/Company/ProductCatalogue/productCatalogueDetails"
import FleetDetails from "pages/Company/Fleets/fleetDetails"
import OrderType from "pages/OrderType"
import Currency from "pages/Currency"
import BillingPlan from "pages/BillingPlan"
import ProductList, { ProductListDetails }  from "pages/ProductList"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/countries", component: <CountryList /> },
  { path: "/towns", component: <TownList /> },
  { path: "/payment_methods", component: <PaymentMethodList /> },
  { path: "/vehicle_types", component: <VehicleTypeList /> },
  { path: "/product_categories", component: <ProductCategoryList /> },
  { path: "/company_types", component: <CompanyTypeList /> },
  { path: "/stock_status", component: <StockStatusList /> },
  { path: "/features", component: <FeatureList /> },
  { path: "/permissions", component: <PermissionList /> },
  { path: "/order_status", component: <OrderStatusList /> },
  { path: "/order_types", component: <OrderType /> },
  { path: "/roles", component: <RoleList /> },
  { path: "/company_roles/:companyId", component: <CompanyRoleList /> },
  { path: "/warehouses/:companyId", component: <WarehouseList /> },
  { path: "/companies", component: <CompanyList /> },
  { path: "/company-details/:companyId", component: <CompanyDetails /> },
  { path: "/user-details/:userId", component: <UserDetails /> },
  { path: "/vehicles/:companyId", component: <FleetList /> },
  { path: "/payment_settings/:companyId", component: <PaymentConfigs /> },
  { path: "/waitlist", component: <Waitlist /> },
  { path: "/contacts", component: <Contact /> },
  {path: "/contact-details/:contactId",component: <ContactDetails /> },
  { path: "/notifications/:userId", component: <Notifications /> },
  { path: "/product-catalogue/:companyId/:companyType", component: <ProductCatalogue /> },
  { path: "/product-catalogue-details/:catalogueId/:companyType", component: <ProductCatalogueDetails /> },
  { path: "/vehicle_details/:vehicleId", component: <FleetDetails /> },
  { path: "/currencies", component: <Currency /> },
  { path: "/billing_plans", component: <BillingPlan /> },
  { path: "/product-list", component: <ProductList /> },
  { path: "/product-list-details/:productListId", component: <ProductListDetails /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/resetPassword", component: <ResetPassword /> },
  { path: "/account", component: <AccountSetup /> },
]

export { authProtectedRoutes, publicRoutes }
