import React, { useState } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import Auth from "models/auth"
import withRouter from "components/Common/withRouter"
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import Footer from "components/Footer"
import Link, { LinkLayout } from "components/Link"
import { PrimaryButton } from "components/Button"
import CONST from "utils/constants"
import { BodyText, BodyTextBold } from "components/atoms"

const { ROLES } = CONST

const Login = props => {
  //meta title
  document.title = "Login | hevara - DMS"

  const history = useNavigate()
  const [show, setShow] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  const userLogin = values => {
    setLoading(true)
    Auth.login(values)
      .then(data => {
        const userRole = data.role
        if (userRole === ROLES.ADMIN) {
          history("/dashboard")
        } else {
          setErrorMessage("You are not allowed to access this platform. Please contact hevara for support.")
        }
      })
      .catch(error => {
        setErrorMessage(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      userLogin(values)
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-dark p-4">
                        <BodyTextBold>hevara admin</BodyTextBold>
                        <BodyText>Administration</BodyText>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-1">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {errorMessage && (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {errorMessage}
                        </Alert>
                      )}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />

                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="d-flex flex-column align-items-center">
                        <PrimaryButton type="submit" className="w-lg">
                          {loading ? "Processing..." : "Login"}
                        </PrimaryButton>
                      </div>

                      <div className="text-center mt-4">
                        <LinkLayout>
                          <Link to="/forgot-password">
                            Forgot your password?
                          </Link>
                        </LinkLayout>
                      </div>
                    </Form>
                  </div>

                  <div className="mt-3 text-center">
                    <Footer />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
