import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { Container, Col, Row, Card, CardBody } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import Notification from "models/notification"
import Loading from "components/Common/Spinner"
import DataItem from "components/DataItem"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import NotificationItem from "./notificationItem"

const Notifications = props => {
  //meta title
  document.title = "Notifications | hevara-DMS"

  const { userId } = useParams()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [notifications, setNotifications] = useState([])

  const fetchNotifications = id => {
    Notification.getUserNotification(id)
      .then(data => {
        setNotifications(data)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (userId) {
      fetchNotifications(userId)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [userId])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Notifications")}
            breadcrumbItem={props.t("Notifications")}
          />

          {/* Loading */}
          {loading && <Loading />}

          {/* LIST */}
          {!loading && !isEmpty(notifications) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center mb-4">
                      <h5 className="mb-0 card-title flex-grow-1">
                        Notifications
                      </h5>
                    </div>

                    {/* TOTAL NOTIFICATIONS */}
                    <DataItem
                      label={props.t("Unread notification")}
                      value={`${notifications?.length}`}
                      className="mt-4"
                    />

                    <NotificationItem notifications={notifications} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(notifications) && (
            <div className="d-flex justify-content-center align-items-center">
              <EmptyState
                visible={isEmpty(notifications)}
                title={`No notifications`}
                description={`You have no notifications at the moment.`}
              />
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

Notifications.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Notifications)
