import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, omit, orderBy, chain } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import ProductCatalogueModel from "models/productCatalogue"
import ProductModal from "models/product"
import Auth from "models/auth"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { PrimaryButton, BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import ProductListItem from "components/itemList/productListItem/productListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"

import CONST from "utils/constants"

const { COMPANY_TYPE } = CONST

const ProductCatalogue = props => {
  //meta title
  document.title = "Product catalogue | hevara admin"

  const { companyId, companyType } = useParams()

  const navigate = useNavigate()

  const [productCatalogues, setProductCatalogues] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [productCatalogueList, setProductCatalogueList] = useState([])
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const pageName = [
    COMPANY_TYPE.WHOLESALLER,
    COMPANY_TYPE.DISTRIBUTOR,
  ].includes(companyType)

  const pageTitle = pageName ? "Product catalogue" : "Products"

  const fetchProductCatalogues = (limit, page, id, type) => {
    let filters = {
      companyId: id
    }

    // if (type === COMPANY_TYPE.DISTRIBUTOR) {
    //   filters = { distributorId: id }
    // } else if (type === COMPANY_TYPE.WHOLESALLER) {
    //   filters = { wholesalerId: id }
    // }

    ProductCatalogueModel.getProductCatalogues(limit, page, filters)
      .then(data => {
        setProductCatalogues(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchProducts = (limit, page, id) => {
    const filters = { companyId: id }

    ProductModal.getProducts(limit, page, filters)
      .then(data => {
        setProductCatalogues(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (companyId && companyType && profile) {
      if (companyType === COMPANY_TYPE.MANUFACTURER) {
        fetchProducts(limit, page, companyId)
      } else {
        fetchProductCatalogues(limit, page, companyId, companyType)
      }

      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [companyId, companyType, limit, page])

  useEffect(() => {
    setProductCatalogueList(productCatalogues)
  }, [productCatalogues])

  const disableProductCatalogue = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "dateCreated"]),
      status: "Disabled",
    }

    ProductCatalogueModel.updateProductCatalogue(data.id, payload)
      .then(productCatalogue => {
        setProductCatalogues(
          productCatalogues.map(item =>
            item.id.toString() === productCatalogue.id.toString()
              ? { ...productCatalogue }
              : item
          )
        )
        toast.success(`${productCatalogue.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableProductCatalogue = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "dateCreated"]),
      status: "Active",
    }
    ProductCatalogueModel.updateProductCatalogue(data.id, payload)
      .then(productCatalogue => {
        setProductCatalogues(
          productCatalogues.map(item =>
            item.id.toString() === productCatalogue.id.toString()
              ? { ...productCatalogue }
              : item
          )
        )
        toast.success(`${productCatalogue.name} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () =>
          navigate(`/product-catalogue-details/${item.id}/${companyType}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_PRODUCTS && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_PRODUCTS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive
            ? disableProductCatalogue(item)
            : enableProductCatalogue(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.CAN_MANAGE_PRODUCTS && {
        label: `New ${pageTitle}`,
        icon: "mdi mdi-plus",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const filteredProductsCatalogue = chain(productCatalogues)
    .filter(product => {
      // Name
      const hasLabel = product.name?.toLowerCase().includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(
    filteredProductsCatalogue,
    [p => p.name.toUpperCase()],
    "asc"
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={pageTitle} breadcrumbItem={pageTitle} />

          {/* Loading */}
          {loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={pageTitle}
            description={"We are processing your request, please wait"}
          />

          {/* LIST */}
          {!loading && !isEmpty(productCatalogues) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={pageTitle}
                      showBackButton={true}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText className="fw-semibold">
                            {`${productCatalogues.length} ${
                              productCatalogues.length > 1
                                ? `${pageTitle}s`
                                : `${pageTitle}`
                            }`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "name", true)}
                      renderItem={item => (
                        <ProductListItem
                          item={item}
                          actions={getListActions(item)}
                          companyType={companyType}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(productCatalogues) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                title={"No product catalogue"}
                description={"No product catalogue have been added."}
              />

              {userPermissions.CAN_MANAGE_PRODUCTS && (
                <PrimaryButton
                  onClick={() => console.log("WIP")}
                  leftIcon="mdi mdi-plus"
                >
                  {`New ${pageTitle}`}
                </PrimaryButton>
              )}
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* MODAL: CHOOSE TO ADD OR CREATE NEW PRODUCT CATALOGUE */}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

ProductCatalogue.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProductCatalogue)
