import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

// Import Images
import emptyState from "assets/images/error.png"

const ErrorState = ({ title, description }) => {
  return (
    <React.Fragment>
      <section className="text-center">
        <Row className="mt-4 justify-content-center">
          <Col md={4}>
            <div className="maintenance-img">
              <img src={emptyState} width={200} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
        <h4 className="mt-2">{title}</h4>
        <p>{description}</p>
      </section>
    </React.Fragment>
  )
}

export default ErrorState
