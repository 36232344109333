import React, { useState } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import Link, { LinkLayout } from "components/Link"
import Auth from "models/auth"
import Footer from "components/Footer"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { PrimaryButton } from "components/Button"
import { BodyText, BodyTextBold } from "components/atoms"

const ForgetPasswordPage = props => {
  //meta title
  document.title = "Forget Password | hevara - DMS"

  const [forgetSuccessMsg, setForgetSuccessMsg] = useState("")
  const [forgetErrorMsg, setForgetErrorMsg] = useState("")
  const [loading, setLoading] = useState(false)

  const forgotPassword = values => {
    setLoading(true)
    Auth.forgotPassword(values)
      .then(data => {
        setForgetSuccessMsg(data.message)
      })
      .catch(error => {
        setForgetErrorMsg(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      forgotPassword(values)
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-dark p-4">
                        <BodyTextBold>Reset password</BodyTextBold>
                        <BodyText>Reset password</BodyText>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {(forgetErrorMsg || forgetSuccessMsg) && (
                      <Alert
                        color={forgetErrorMsg ? "danger" : "success"}
                        style={{ marginTop: "13px" }}
                      >
                        {forgetErrorMsg
                          ? forgetErrorMsg
                          : forgetSuccessMsg
                          ? forgetSuccessMsg
                          : ""}
                      </Alert>
                    )}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="d-flex flex-column align-items-center">
                        <PrimaryButton type="submit" className="w-lg">
                          {loading ? "Processing..." : "Confirm"}
                        </PrimaryButton>
                      </div>
                    </Form>
                  </div>

                  <div className="mt-4 text-center">
                    <LinkLayout className="mb-4">
                      <BodyText>Remember it? </BodyText>
                      <Link to="/login">Login in here</Link>
                    </LinkLayout>
                    <Footer />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
