import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { chain, isEmpty, omit, orderBy } from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer, toast } from "react-toastify"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Container
} from "reactstrap"

import Auth from "models/auth"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import Currencies from "models/currency"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import {
  PrimaryButton,
  SecondaryButton,
  BUTTON_VARIANT,
} from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import CurrencyListItem from "components/itemList/currencyListItem/currencyListItem"


const Currency = props => {
  //meta title
  document.title = "Currency | hevara-DMS"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [currencies, setCurrencies] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [currencyList, setCurrencyList] = useState([])
  const [currency, setCurrency] = useState(null)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    Currencies.getCurrencies(limit, page)
      .then(data => {
        setCurrencies(data.results)
        setTotalPages(data.totalPages)
        setTotalResults(data.totalResults)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const addCurrency = values => {
    setProcessing(true)
    Currencies.addCurrency(values)
      .then(currency => {
        setCurrencies([...currencies, currency])
        toast.success(`${currency.name} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateCurrency = (currencyId, values) => {
    setProcessing(true)
    Currencies.updateCurrency(currencyId, values)
      .then(currency => {
        setCurrencies(
          currencies.map(item =>
            item.id.toString() === currency.id.toString()
              ? { item, ...currency }
              : item
          )
        )
        toast.success(`${currency.name} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableCurrency = (values) => {
    setProcessing(true)

    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "ACTIVE",
    }

    Currencies.updateCurrency(values.id, payload)
      .then(currency => {
        setCurrencies(
          currencies.map(item =>
            item.id.toString() === currency.id.toString()
              ? { ...currency }
              : item
          )
        )
        toast.success(`${currency.name} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableCurrency = (values) => {
    setProcessing(true)

    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "DISABLED",
    }

    Currencies.updateCurrency(values.id, payload)
      .then(currency => {
        setCurrencies(
          currencies.map(item =>
            item.id.toString() === currency.id.toString()
              ? { ...currency }
              : item
          )
        )
        toast.success(`${currency.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      fetchData(limit, page)
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setCurrencyList(currencies)
  }, [currencies])

  useEffect(() => {
    if (!isEmpty(currencies) && !!isEdit) {
      setCurrencyList(currencies)
      setIsEdit(false)
    }
  }, [currencies])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (currency && currency.name) || "",
      code: (currency && currency.code) || "",
      assetFlag: (currency && currency.assetFlag) || "",
      symbol: (currency && currency.symbol) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      code: Yup.string().required("This field is required"),
      assetFlag: Yup.string().required("This field is required"),
      symbol: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      if (isEdit) {
        updateCurrency(currency.id, values)
      } else {
        addCurrency(values)
      }

      validation.resetForm()
      toggle()
    },
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCurrency(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const handleClick = arg => {
    setCurrency(arg)
    setIsEdit(true)
    toggle()
  }

  const getListActions = item => {
    const isActive = item.status === "ACTIVE"

    const actions = [
      userPermissions.CAN_MANAGE_CURRENCIES && {
        label: "Edit details",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_CURRENCIES && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => isActive ? disableCurrency(item) : enableCurrency(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.CAN_MANAGE_CURRENCIES && {
        label: "New currency",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickNewCurrency = () => {
    setIsEdit(false)
    setModal(true)
  }

  const filteredCurrencies = chain(currencies)
    .filter(currency => {
      const hasName = currency.name
        ?.toLowerCase()
        .includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(filteredCurrencies, [p => p.name.toUpperCase()], "asc")


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("Currency")} breadcrumbItem={props.t("Currency")} />

          {/* Loading */}
          {loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Currencies"}
            description={"We are processing your request, please wait a moment..."}
          />

          {/* LIST */}
          {!loading && !isEmpty(currencies) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Currencies"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            {`${currencies.length} ${currencies.length > 1 ? "Currencies" : "Currency"}`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "name", true)}
                      renderItem={item => (
                        <CurrencyListItem
                          item={item}
                          actions={getListActions(item)}
                          permission={userPermissions.CAN_MANAGE_CURRENCIES}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(currencies) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                visible={isEmpty(currencies)}
                title={`No currencies`}
                description={`No currencies have been set yet`}
                firstButton={
                  userPermissions.CAN_MANAGE_CURRENCIES && {
                    label: `New currency`,
                    onClick: onClickNewCurrency,
                    disabled: processing,
                  }
                }
              />
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Country Modal */}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit currency" : "New currency"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Currency name</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Currency name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Currency code</Label>
                      <Input
                        name="code"
                        type="text"
                        placeholder="Currency code"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.code || ""}
                        invalid={
                          validation.touched.code && validation.errors.code
                            ? true
                            : false
                        }
                      />
                      {validation.touched.code && validation.errors.code ? (
                        <FormFeedback type="invalid">
                          {validation.errors.code}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Currency symbol</Label>
                      <Input
                        name="symbol"
                        type="text"
                        placeholder="Currency symbol"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.symbol || ""}
                        invalid={
                          validation.touched.symbol && validation.errors.symbol
                            ? true
                            : false
                        }
                      />
                      {validation.touched.symbol && validation.errors.symbol ? (
                        <FormFeedback type="invalid">
                          {validation.errors.symbol}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Asset flag url</Label>
                      <Input
                        name="assetFlag"
                        type="url"
                        placeholder="Asset flag url"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.assetFlag || ""}
                        invalid={
                          validation.touched.assetFlag && validation.errors.assetFlag
                            ? true
                            : false
                        }
                      />
                      {validation.touched.assetFlag && validation.errors.assetFlag ? (
                        <FormFeedback type="invalid">
                          {validation.errors.assetFlag}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <PrimaryButton type="submit">
                        {!!isEdit ? "Update currency" : "Add currency"}
                      </PrimaryButton>

                      <SecondaryButton
                        type="button"
                        onClick={toggle}
                        className="me-4"
                      >
                        Cancel
                      </SecondaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

Currency.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Currency)
