export const ANIMATION_DURATION = 200

export const TEXT_VARIANT = {
  DEFAULT: "default",
  CRITICAL: "critical",
}

export const CONTEXTUAL_MODAL_POSITIONS = {
  RIGHT: "right",
  LEFT: "left",
}

export const TRANSITION_STYLES = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: {},
}

export const getContextualRowDefaultStyles = ({ disabled, selected }) => {
  return `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: ${disabled ? 0.4 : 1};
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
    padding-left: 16px;
    ${selected ? `background: rgba(0, 0, 0, 0.02)` : ""};
    &:hover {
      background: ${disabled ? "transparent" : "rgba(0, 0, 0, 0.02)"};
      cursor: ${disabled ? "default" : "pointer"}
    }
  `
}

export const getStyledButton = ({ disabled, ...props }) => {
  return `
    border: none;
    background-color: transparent;
    cursor: ${disabled ? "default" : "pointer"};
    opacity: ${disabled ? 0.4 : 1};
    .hevara-contextual-modal-button-icon {
      fill: "#F6CF0B";
    }
  `
}

export const getStyledElementWrapper = ({ position, animationType }) => {
  let boxPosition = "left: 0"

  if (position === CONTEXTUAL_MODAL_POSITIONS.LEFT) {
    boxPosition = `right: 0`
  }
  const transitionStyle = animationType ? "ease-in" : "ease-out"

  return `
    transition: opacity ${ANIMATION_DURATION}ms ${transitionStyle};
    z-index: 1;
    position: absolute;
    margin-top: 8px;
    ${boxPosition}
  `
}

export const getStyledElement = ({ ...props }) => {
  return `
    width: 240px;
    background-color: '#FFFFFF';
    border-radius: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
  `
}

export const getListItemStyle = ({ variant, ...props }) => {
  const color = variant === TEXT_VARIANT.CRITICAL ? "#f46a6a" : "#6B8F3C"

  return `
    padding-right: 8px;
    .contextual-modal-text {
      color: ${color}
    }
  `
}

export const getContextualIconStyle = ({ variant, ...props }) => {
  const color = variant === TEXT_VARIANT.CRITICAL ? "#f46a6a" : "#6B8F3C"

  return `
    margin-right: 8px;
    color: ${color};
    fill: ${color};
  `
}
