import axios from "axios"
import API from "config/api.config"

export default {
  getCompanies(limit = 10, page = 1) {
    return axios
      .get(
        `${API.getApi().COMPANIES.GET_COMPANIES}?limit=${limit}&page=${page}`
      )
      .then(async ({ data }) => {
        return data
      })
  },
  getCompany(companyId) {
    return axios
      .get(`${API.getApi().COMPANIES.GET_COMPANY}/${companyId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addCompany(values) {
    return axios
      .post(API.getApi().COMPANIES.ADD_COMPANY, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateCompany(companyId, values) {
    return axios
      .patch(`${API.getApi().COMPANIES.UPDATE_COMPANY}/${companyId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpCompanies() {
    return axios
      .get(API.getApi().LOOKUPS.LOOKUP_COMPANIES)
      .then(async ({ data }) => {
        return data
      })
  },
}
