import React from "react"
import moment from "moment"
import { isEmpty, map } from "lodash"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getStatus } from "utils/utils"
import CONST from "utils/constants"

const { DEFAULT_DATE_FORMAT } = CONST

import "./countryListItem.styles.scss"

const CountryListItem = ({ item, actions, permission }) => {
  return (
    <TableRow>
      <div key={item.id} className="hevara-country-list-item">
        {/* MAIN INFO */}
        <MainInfoList
          title={item.name}
          avatar={{ image: item.img, initials: item.name }}
          className="text-truncate"
        />

        <div className="date-item">
          {/* CREATION DATE */}
          <div className="title-and-date">
            <TableRowInfo
              className="text-truncate"
              text={moment(item.dateCreated).format(DEFAULT_DATE_FORMAT)}
            />
          </div>

          <div className="status-and-actions">
            {/* STATUS */}
            {!isEmpty(item.status) && getStatus(item.status)}

            {/* ACTIONS */}
            {permission && (
              <ContextualModal
                className="item-actions"
                style={{ marginLeft: 4 }}
                position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
                contentElement={map(actions, (modalItem, index) => (
                  <ContextualModalListItem
                    key={index}
                    {...modalItem}
                    onClick={e => modalItem.onClick(e, item)}
                  />
                ))}
              />
            )}
          </div>
        </div>
      </div>
    </TableRow>
  )
}

CountryListItem.defaultProps = {
  item: {},
  actions: [],
}

export default CountryListItem
