import React from "react"
import {
  isString,
  isEmpty,
  isPlainObject,
  template,
  replace,
  pickBy,
  chain,
  keys,
  deburr,
  toUpper,
  map,
  omitBy,
  isUndefined,
  isNull,
  isObject
} from "lodash"

import Chip, { CHIP_VARIANTS } from "components/Chip"
import CONST from "./constants"

const { COMPANY_TYPE, ROLES } = CONST

export function buildUrl(url, data) {
  if (
    isString(url) &&
    !isEmpty(url.trim()) &&
    isPlainObject(data) &&
    !isEmpty(data)
  ) {
    const taggedURL = url.replace(/(:)([a-z_]+)/gi, "${data.$2}")
    const templateFn = template(taggedURL)
    return templateFn({ data })
  }

  throw new Error(
    'Trying to call "buildUrl" with invalid params: (${url}, ${data})'
  )
}

export function queryParams(url, params) {
  const parameters = pickBy(params, value => !!value)
  if (isString(url) && !isEmpty(url.trim()) && isPlainObject(parameters)) {
    if (isEmpty(parameters)) {
      return url
    }
    const query = chain(keys(parameters))
      .map(p => `${p}=${parameters[p]}`)
      .join("&")

    return `${url}?${query}`
  }

  throw new Error(
    'Trying to call "buildUrl" with invalid params: (${url}, ${parameters})'
  )
}

export const getStatus = status => {
  switch (status) {
    case "Active":
    case "ACTIVE":
      return <Chip variant={CHIP_VARIANTS.SUCCESS}>Enabled</Chip>
    case "Disabled":
    case "DISABLED":
      return <Chip variant={CHIP_VARIANTS.DANGER}>Disabled</Chip>
  }
}

export const getCompanyType = type => {
  let typeName
  switch (type) {
    case COMPANY_TYPE.SUPER_ADMIN:
      typeName = "Super admin"
      break
    case COMPANY_TYPE.IMPORTER:
      typeName = "Importer"
      break
    case COMPANY_TYPE.MANUFACTURER:
      typeName = "Manufacturer"
      break
    case COMPANY_TYPE.DISTRIBUTOR:
      typeName = "Distributor"
      break
    case COMPANY_TYPE.WHOLESALLER:
      typeName = "Wholesaler"
      break
    case COMPANY_TYPE.VENDOR:
      typeName = "Vendor"
      break
    case COMPANY_TYPE.KEY_DISTRIBUTOR:
      typeName = "Key distributor"
      break
    default:
      typeName = ""
      break
  }
  return typeName
}

export const getLastFourDigits = phoneNumber => {
  return `****${phoneNumber.slice(-4)}`
}

export const getCompanyRole = role => {
  let roleName
  switch (role) {
    case ROLES.SUPER_ADMIN:
      roleName = "Super admin"
      break
    case ROLES.ADMIN:
      roleName = "Administrator"
      break
    case ROLES.USER:
      roleName = "User"
      break
    case ROLES.COMPANY_ADMIN:
      roleName = "Company administrator"
      break
    case ROLES.ROUTE_AGENT:
      roleName = "Route agent"
      break
    case ROLES.ACCOUNTANT:
      roleName = "Accountant"
      break
    case ROLES.MANAGER:
      roleName = "Manager"
      break
    case ROLES.VENDOR:
      roleName = "Vendor"
      break
    default:
      roleName = role
      break
  }
  return roleName
}

export const transformPermission = value => {
  const parts = value.replace("CAN_", "").split("_")

  const transformedValue = parts
    .map((part, index) => {
      if (index === 0) {
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
      }
      return part.toLowerCase()
    })
    .join(" ")

  return transformedValue
}

export const getInitials = init => {
  // replace "weird" chars (more info https://lodash.com/docs/#deburr)
  let name = deburr(init)
  let initials = ""
  // remove extra chars that are NOT alphanumeric
  name = replace(name, /[^a-z0-9\s]+/gi, "")
  // remove extra spaces
  name = replace(name, /\s+/g, " ").trim()
  const words = name.split(" ")
  const count = words.length
  // if name is too short to abbreviate return same value
  if (name.length <= 3) {
    initials = name
    // if name has 1 word return first letter
  } else if (count === 1) {
    initials = name.substring(0, 1)
    // if name has 2 or 3 word return first letters
  } else if (count === 2 || count === 3) {
    initials = map(words, word => word.substring(0, 1)).join("")
    // if name has more than 3 word return the letters of the first 2 words
  } else if (count > 3) {
    initials = `${words[0][0]}${words[1][0]}`
  }
  return toUpper(initials) || "?"
}

export const encodeSearch = search => {
  try {
    return search
      ? encodeURIComponent(
          JSON.stringify(
            omitBy(
              search,
              v =>
                v === "" ||
                isUndefined(v) ||
                isNull(v) ||
                (isEmpty(v) && isObject(v))
            )
          )
        )
      : ""
  } catch (e) {
    return ""
  }
}

export const encodeSearchUrl = (url, search) => {
  return search ? `${url}?${encodeSearch(search)}` : ""
}

export const groupListByKey = (
  list,
  objectKey = "name",
  useInitial = false
) => {
  return chain(list)
    .groupBy(c =>
      useInitial ? c[objectKey].charAt(0).toUpperCase() : c[objectKey]
    )
    .map((value, key) => ({ title: key, data: value }))
    .value()
}

export const formatName = (name) => {
  if (name.length === 0) return '';  // Return an empty string if the input is empty
  return name[0].toUpperCase() + name.slice(1).toLowerCase();
}

export const formatVolume = (volumeInMl) => {
  if (volumeInMl < 1000) {
      return volumeInMl + ' ml';  // Return the value in milliliters
  } else {
      let volumeInLiters = volumeInMl / 1000;  // Convert to liters
      return volumeInLiters.toFixed(2) + ' Ltr';  // Format to 2 decimal places and add the liter unit
  }
}

export const getRGBA = (hex, opacity) => {
  const cleanedHex = hex.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (m, r, g, b) => "#" + r + r + g + g + b + b
  )
  const rgb =
    cleanedHex
      .substring(1)
      .match(/.{2}/g)
      ?.map(x => parseInt(x, 16)) || []
  const red = rgb[0]
  const green = rgb[1]
  const blue = rgb[2]

  return isEmpty(rgb) ? "" : `rgba(${red},${green},${blue},${opacity})`
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

/**
 * Filters company types based on the provided name.
 * @param {Array} data - The array of company types to filter.
 * @returns {Array} - The filtered array of company types.
 */
export const filterData = (data) => {
  return chain(data)
    .filter(type => {
      const hasName = type.name?.toLowerCase().includes(name.toLowerCase());
      return hasName;
    })
    .value();
};