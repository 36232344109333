import React, { useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"
import { formatBytes } from "utils/utils"
import Avatar from "components/Avatar/Avatar"
import CONST from "utils/constants"

const { SIZE } = CONST

const ImageModal = ({ modalIsOpen, onClickClose, onClickUploadImage }) => {
  const [selectedFiles, setSelectedFiles] = useState([])

  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setSelectedFiles(files)
  }

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={`Product image`}
    >
      <div className="mt-4">
        <Dropzone
          onDrop={acceptedFiles => {
            handleAcceptedFiles(acceptedFiles)
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-message needsclick">
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop image here or click to upload.</h4>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="dropzone-previews mt-3" id="file-previews">
          {selectedFiles.map((f, i) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <Avatar
                        size={SIZE.XL}
                        initials={f.name}
                        image={f.preview}
                      />
                    </Col>
                    <Col>
                      <Link to="#" className="text-muted font-weight-bold">
                        {f.name}
                      </Link>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            )
          })}
        </div>

        <Row>
          <Col>
            <div className="hstack gap-2 justify-content-center mb-0 mt-4">
            <SecondaryButton
                type="button"
                onClick={onClickClose}
                className="me-4"
              >
                Cancel
              </SecondaryButton>

              <PrimaryButton
                type="button"
                onClick={() => onClickUploadImage(selectedFiles)}
              >
                Confirm
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      </div>
    </LargeModal>
  )
}

export default ImageModal
