// apply base url for api's
/**
 * local url: http://localhost:3000/v1
 * dev url: https://dev.hevara.africa/v1
 * qa url: https://qa.hevara.africa/v1
 * demo url: https://demo.hevara.africa/v1
 * prod url: https://api.hevara.africa/v1
 */

let baseUrl = "https://qa.hevara.africa/v1"

export default {
  getApi() {
    return {
      AUTH: {
        LOGIN: `${baseUrl}/auth/login`,
        LOGOUT: `${baseUrl}/auth/logout`,
        FORGOT_PASSWORD: `${baseUrl}/auth/forgot-password`,
        RECOVER_PASSWORD: `${baseUrl}/auth/reset-password`,
        REFRESH_TOKEN: `${baseUrl}/auth/refresh-tokens`,
        REQUEST_OTP_CODE: `${baseUrl}/auth/request-otp`,
        ACCOUNT_SETUP: `${baseUrl}/auth/account-setup`,
      },
      COUNTRIES: {
        ADD_COUNTRY: `${baseUrl}/countries/addCountry`,
        GET_COUNTRIES: `${baseUrl}/countries/getCountries`,
        UPDATE_COUNTRY: `${baseUrl}/countries/updateCountry`,
      },
      TOWNS: {
        ADD_TOWN: `${baseUrl}/towns/addTown`,
        GET_TOWNS: `${baseUrl}/towns/getTowns`,
        UPDATE_TOWN: `${baseUrl}/towns/updateTown`,
      },
      PAYMENT_METHODS: {
        ADD_PAYMENT_METHOD: `${baseUrl}/paymentMethods/addPaymentMethod`,
        GET_PAYMENT_METHODS: `${baseUrl}/paymentMethods/getPaymentMethods`,
        UPDATE_PAYMENT_METHOD: `${baseUrl}/paymentMethods/updatePaymentMethod`,
      },
      VEHICLE_TYPES: {
        ADD_VEHICLE_TYPE: `${baseUrl}/vehicleTypes/addVehicleType`,
        GET_VEHICLE_TYPES: `${baseUrl}/vehicleTypes/getVehicleTypes`,
        UPDATE_VEHICLE_TYPE: `${baseUrl}/vehicleTypes/updateVehicleType`,
      },
      PRODUCT_CATEGORIES: {
        ADD_PRODUCT_CATEGORY: `${baseUrl}/productCategories/addProductCategory`,
        GET_PRODUCT_CATEGORIES: `${baseUrl}/productCategories/getProductCategory`,
        UPDATE_PRODUCT_CATEGORY: `${baseUrl}/productCategories/updateProductCategory`,
      },
      COMPANY_TYPES: {
        ADD_COMPANY_TYPE: `${baseUrl}/companyTypes/addCompanyType`,
        GET_COMPANY_TYPES: `${baseUrl}/companyTypes/getCompanyTypes`,
        UPDATE_COMPANY_TYPE: `${baseUrl}/companyTypes/updateCompanyType`,
      },
      STOCK_STATUS: {
        ADD_STOCK_STATUS: `${baseUrl}/stockStatuses/addStockStatus`,
        GET_STOCK_STATUS: `${baseUrl}/stockStatuses/getStockStatuses`,
        UPDATE_STOCK_STATUS: `${baseUrl}/stockStatuses/updateStockStatus`,
      },
      ORDER_STATUS: {
        ADD_ORDER_STATUS: `${baseUrl}/orderStatuses/addOrderStatus`,
        GET_ORDER_STATUS: `${baseUrl}/orderStatuses/getOrderStatuses`,
        UPDATE_ORDER_STATUS: `${baseUrl}/orderStatuses/updateOrderStatus`,
      },
      ORDER_TYPE: {
        ADD_ORDER_TYPE: `${baseUrl}/orderType/addOrderType`,
        GET_ORDER_TYPES: `${baseUrl}/orderType/getOrderTypes`,
        GET_ORDER_TYPE: `${baseUrl}/orderType/getOrderType`,
        UPDATE_ORDER_TYPE: `${baseUrl}/orderType/updateOrderType`,
      },
      FEATURES: {
        ADD_FEATURE: `${baseUrl}/features/addFeature`,
        GET_FEATURES: `${baseUrl}/features/getFeatures`,
        UPDATE_FEATURE: `${baseUrl}/features/updateFeature`,
      },
      BILLING_PLANS: {
        ADD_BILLING_PLAN: `${baseUrl}/billing_plans/addBillingPlan`,
        SELECT_BILLING_PLAN: `${baseUrl}/billing_plans/selectBillingPlan`,
        GET_BILLING_PLANS: `${baseUrl}/billing_plans/getBillingPlans`,
        GET_BILLING_PLAN: `${baseUrl}/billing_plans/getBillingPlan`,
        UPDATE_BILLING_PLAN: `${baseUrl}/billing_plans/updateBillingPlan`,
      },
      PERMISSIONS: {
        ADD_PERMISSION: `${baseUrl}/permissions/addPermission`,
        GET_PERMISSIONS: `${baseUrl}/permissions/getPermissions`,
        UPDATE_PERMISSION: `${baseUrl}/permissions/updatePermission`,
      },
      ROLES: {
        ADD_ROLE: `${baseUrl}/roles/addRole`,
        GET_ROLES: `${baseUrl}/roles/getRoles`,
        GET_ROLE: `${baseUrl}/roles/getRole`,
        UPDATE_ROLE: `${baseUrl}/roles/updateRole`,
      },
      COMPANY_ROLES: {
        ADD_COMPANY_ROLE: `${baseUrl}/companyRoles/addCompanyRole`,
        GET_COMPANY_ROLES: `${baseUrl}/companyRoles/getCompanyRoles`,
        GET_COMPANY_ROLE: `${baseUrl}/companyRoles/getCompanyRole`,
        UPDATE_COMPANY_ROLE: `${baseUrl}/companyRoles/updateCompanyRole`,
      },
      COMPANIES: {
        ADD_COMPANY: `${baseUrl}/companies/addCompany`,
        GET_COMPANIES: `${baseUrl}/companies/getCompanies`,
        GET_COMPANY: `${baseUrl}/companies/getCompany`,
        UPDATE_COMPANY: `${baseUrl}/companies/updateCompany`,
      },
      WAREHOUSES: {
        ADD_WAREHOUSE: `${baseUrl}/warehouses/addWarehouse`,
        GET_WAREHOUSES: `${baseUrl}/warehouses/getWarehouses`,
        GET_WAREHOUSE: `${baseUrl}/warehouses/getWarehouse`,
        UPDATE_WAREHOUSE: `${baseUrl}/warehouses/updateWarehouse`,
      },
      USERS: {
        ADD_USER: `${baseUrl}/users`,
        GET_USERS: `${baseUrl}/users`,
        GET_USER_DETAILS: `${baseUrl}/users`,
        UPDATE_USER: `${baseUrl}/users`,
        DELETE_USER: `${baseUrl}/users`,
      },
      FLEETS: {
        ADD_FLEET: `${baseUrl}/vehicles/addVehicle`,
        GET_FLEETS: `${baseUrl}/vehicles/getVehicles`,
        GET_FLEET: `${baseUrl}/vehicles/getVehicle`,
        UPDATE_FLEET: `${baseUrl}/vehicles/updateVehicle`,
      },
      PAYMENT_CONFIGS: {
        ADD_PAYMENT_CONFIG: `${baseUrl}/paymentConfigs/addPaymentConfig`,
        GET_PAYMENT_CONFIGS: `${baseUrl}/paymentConfigs/getPaymentConfigs`,
        GET_PAYMENT_CONFIG: `${baseUrl}/paymentConfigs/getPaymentConfig`,
        UPDATE_PAYMENT_CONFIG: `${baseUrl}/paymentConfigs/updatePaymentConfig`,
      },
      WAITLIST: {
        GET_WAITLIST: `${baseUrl}/waitlist/getWaitlist`,
      },
      CONTACT: {
        GET_CONTACTS: `${baseUrl}/contact/getContacts`,
        GET_CONTACT: `${baseUrl}/contact/getContact`
      },
      NOTIFICATIONS: {
        GET_NOTIFICATION: `${baseUrl}/notifications/getNotification`,
        GET_USER_NOTIFICATIONS: `${baseUrl}/notifications/getUserNotifications`,
        UPDATE_NOTIFICATION: `${baseUrl}/notifications/updateNotification`,
      },
      PRODUCT_CATALOGUE: {
        CREATE_PRODUCT_CATALOGUE: `${baseUrl}/productCatalogue/createProductCatalogue`,
        ADD_PRODUCT_CATALOGUE: `${baseUrl}/productCatalogue/addProductCatalogue`,
        GET_PRODUCT_CATALOGUES: `${baseUrl}/productCatalogue/getProductCatalogues`,
        GET_PRODUCT_CATALOGUE: `${baseUrl}/productCatalogue/getProductCatalogue`,
        UPDATE_PRODUCT_CATALOGUE: `${baseUrl}/productCatalogue/updateProductCatalogue`,
        DELETE_PRODUCT_CATALOGUE: `${baseUrl}/products/deleteProductCatalogue`,
      },
      PRODUCT: {
        CREATE_PRODUCT: `${baseUrl}/products/addProduct`,
        GET_PRODUCTS: `${baseUrl}/products/getProducts`,
        GET_PRODUCT: `${baseUrl}/products/getProduct`,
        UPDATE_PRODUCT: `${baseUrl}/products/updateProduct`,
        DELETE_PRODUCT: `${baseUrl}/products/deleteProduct`,
      },
      PRODUCT_TYPES: {
        CREATE_PRODUCT_TYPE: `${baseUrl}/productTypes/addProductType`,
        GET_PRODUCT_TYPES: `${baseUrl}/productTypes/getProductTypes`,
        GET_PRODUCT_TYPE: `${baseUrl}/productTypes/getProductType`,
        UPDATE_PRODUCT_TYPE: `${baseUrl}/productTypes/updateProductType`,
      },
      CURRENCY: {
        CREATE_CURRENCY: `${baseUrl}/currencies/addCurrency`,
        GET_CURRENCIES: `${baseUrl}/currencies/getCurrencies`,
        GET_CURRENCY: `${baseUrl}/currencies/currencyId`,
        UPDATE_CURRENCY: `${baseUrl}/currencies/updateCurrency`,
      },
      PRODUCT_MASTER_LIST: {
        ADD_PRODUCT_LIST: `${baseUrl}/productList/addProductList`,
        GET_PRODUCT_LISTS: `${baseUrl}/productList/getProductLists`,
        GET_PRODUCT_LIST: `${baseUrl}/productList/getProductList`,
        UPDATE_PRODUCT_LIST: `${baseUrl}/productList/updateProductList`,
        DELETE_PRODUCT_LIST: `${baseUrl}/productList/deleteProductList`,
      },
      UPLOADS: {
        ADD_UPLOAD: `${baseUrl}/uploads/addUpload`,
        GET_UPLOADS: `${baseUrl}/uploads/getUploads`,
        GET_UPLOAD: `${baseUrl}/uploads/getUpload`,
        UPDATE_UPLOAD: `${baseUrl}/uploads/updateUpload`,
        DELETE_UPLOAD: `${baseUrl}/uploads/deleteUpload`,
      },
      LOOKUPS: {
        LOOKUP_COUNTRIES: `${baseUrl}/lookUps/getCountries`,
        LOOKUP_PERMISSIONS: `${baseUrl}/lookUps/getPermissions`,
        LOOKUP_COMPANIES: `${baseUrl}/lookUps/getCompanies`,
        LOOKUP_COMPANY_TYPES: `${baseUrl}/lookUps/getCompanyTypes`,
        LOOKUP_ROLES: `${baseUrl}/lookUps/getRoles`,
        LOOKUP_VEHICLE_TYPES: `${baseUrl}/lookUps/getVehicleTypes`,
        LOOKUP_PAYMENT_METHODS: `${baseUrl}/lookUps/getPaymentMethods`,
        LOOKUP_COMPANY_WAREHOUSES: `${baseUrl}/lookUps/getCompanyWarehouses`,
        LOOKUP_COMPANY_ROLES: `${baseUrl}/lookUps/getCompanyRoles`,
        LOOKUP_BILLING_PLANS: `${baseUrl}/lookUps/getBillingPlans`,
        PRODUCT_TYPES: `${baseUrl}/lookUps/getProductTypes`,
        PRODUCT_CATEGORIES: `${baseUrl}/lookUps/getProductCategories`,
      },
    }
  },
}
