import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { Dropdown, DropdownToggle } from "reactstrap"

import Notification from "models/notification"
import Auth from "models/auth"

const NotificationDropdown = props => {
  const navigate = useNavigate()

  const [error, setError] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [userProfile, setUserProfile] = useState({})

  const fetchNotifications = userId => {
    Notification.getUserNotification(userId)
      .then(data => {
        setNotifications(data)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      fetchNotifications(JSON.parse(profile).id)
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        toggle={() => navigate(`/notifications/${userProfile.id}`)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {notifications.length > 0 && (
            <span className="badge bg-danger rounded-pill font-size-10">
              {notifications.length}
            </span>
          )}
        </DropdownToggle>
      </Dropdown>
      <ToastContainer />
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
