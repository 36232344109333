import axios from "axios"
import API from "config/api.config"

export default {
  getNotification(notificationId) {
    return axios
      .get(`${API.getApi().NOTIFICATIONS.GET_NOTIFICATION}/${notificationId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  getUserNotification(userId) {
    return axios
      .get(`${API.getApi().NOTIFICATIONS.GET_USER_NOTIFICATIONS}/${userId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  dismissNotification(notificationId, values) {
    return axios
      .patch(
        `${API.getApi().NOTIFICATIONS.UPDATE_NOTIFICATION}/${notificationId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
}
