import React from "react"
import { isEmpty } from "lodash"

import { DataItem as HevaraDataItem } from "./DataItem"

const DataItem = ({
  label,
  value,
  avatar,
  link,
  icon,
  onClickIcon,
  className,
  style,
  children,
}) => {
  return (
    <HevaraDataItem
      label={label}
      value={
        !isEmpty(value)
          ? value
          : isEmpty(children) && isEmpty(link)
          ? "-"
          : undefined
      }
      avatar={avatar}
      link={link}
      icon={icon}
      onClickIcon={onClickIcon}
      className={className}
      style={style}
    >
      {children}
    </HevaraDataItem>
  )
}

DataItem.defaultProps = {
  label: "",
  value: undefined,
  avatar: {},
  link: undefined,
  icon: "",
  onClickIcon: undefined,
  className: "",
  style: {},
  children: {},
}

export default DataItem
