import React from "react"
import { map, isEmpty } from "lodash"
import { Link } from "react-router-dom"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getStatus, getCompanyType } from "utils/utils"

import "./companyListItem.scss"

const CompanyListItem = ({ item, actions }) => {
  return (
    <TableRow>
      <Link
        key={item.id}
        to={`/company-details/${item.id}`}
        className="hevara-company-list-item reset-link text-tertiary"
      >
        {/* MAIN INFO */}
        <MainInfoList
          title={item.companyName}
          subtitle={getCompanyType(item.type)}
          avatar={{ image: item.img, initials: item.companyName }}
          className="text-truncate"
        />

        <div className="date-item">
          {/* COUNTRY */}
          <div className="title-and-date">
            <TableRowInfo
              className="text-truncate"
              text={item.countryName}
            />
          </div>

          <div className="status-and-actions">
            {/* IS AVAILABLE */}
            {!isEmpty(item.status) && getStatus(item.status)}

            {/* ACTIONS */}
            <ContextualModal
              className="item-actions"
              style={{ marginLeft: 4 }}
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, item)}
                />
              ))}
            />
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

CompanyListItem.defaultProps = {
  item: {},
  actions: [],
}

export default CompanyListItem
