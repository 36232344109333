import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { chain, isEmpty, omit, orderBy } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import CompanyModal from "models/company"
import CompanyTypes from "models/companyTypes"
import Country from "models/country"
import Auth from "models/auth"
import Loading from "components/Common/Spinner"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { PrimaryButton, SecondaryButton, BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import CompanyListItem from "components/itemList/companyListItem/companyListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"

const Company = props => {
  //meta title
  document.title = "Companies | hevara-DMS"

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)

  const [companies, setCompanies] = useState([])
  const [availableCountries, setAvailableCountries] = useState([])
  const [companyTypes, setCompanyTypes] = useState([])
  const [company, setCompany] = useState(null)
  const [companyList, setCompanyList] = useState([])
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    CompanyModal.getCompanies(limit, page)
      .then(data => {
        setCompanies(data.results)
        setTotalPages(data.totalPages)
        setTotalResults(data.totalResults)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCountries = () => {
    Country.lookUpCountries()
      .then(data => {
        setAvailableCountries([
          {
            status: "Default",
            name: "Select Country",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyTypes = () => {
    CompanyTypes.lookUpCompanyTypes()
      .then(data => {
        setCompanyTypes([
          {
            status: "Default",
            name: "Select company type",
            type: null,
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const disableCompany = data => {
    setProcessing(true)
    const payload = {
      ...omit(data, ["id", "features", "dateCreated", "countryName"]),
      status: "Disabled",
    }
    CompanyModal.updateCompany(data.id, payload)
      .then(company => {
        setCompanies(
          companies.map(item =>
            item.id.toString() === company.id.toString() ? { ...company } : item
          )
        )
        toast.success(`${company.companyName} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableCompany = data => {
    setProcessing(true)
    const payload = {
      ...omit(data, ["id", "features", "dateCreated", "countryName"]),
      status: "Active",
    }
    CompanyModal.updateCompany(data.id, payload)
      .then(company => {
        setCompanies(
          companies.map(item =>
            item.id.toString() === company.id.toString() ? { ...company } : item
          )
        )
        toast.success(`${company.companyName} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const addCompany = values => {
    setProcessing(true)
    CompanyModal.addCompany(values)
      .then(company => {
        setCompanies([...companies, company])
        toast.success(`${company.companyName} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateCompany = (companyId, values) => {
    setProcessing(true)
    CompanyModal.updateCompany(companyId, values)
      .then(company => {
        setCompanies(
          companies.map(item =>
            item.id.toString() === company.id.toString()
              ? { item, ...company }
              : item
          )
        )
        toast.success(`${company.companyName} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyName: (company && company.companyName) || "",
      country: (company && company.country) || "",
      type: (company && company.type) || "",
      companyEmail: (company && company.companyEmail) || "",
      location: (company && company.location) || "",
      status: (company && company.status) || "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Please enter company name"),
      country: Yup.string().required("Please select company country"),
      type: Yup.string().required("Please select company type"),
      companyEmail: Yup.string().email().required("Please enter company email"),
      location: Yup.string().required("Please enter company location"),
      status: Yup.string().required("Please select company status"),
    }),
    onSubmit: values => {
      const payload = {
        companyName: values.companyName,
        country: values.country,
        type: values.type,
        companyEmail: values.companyEmail,
        location: values.location, // TODO: add google maps to get this value
        latitude: "-1.259373836085999", // TODO: add google maps to get this value
        longitude: "36.77702745739489", // TODO: add google maps to get this value
        status: values.status,
      }

      if (isEdit) {
        // update Country
        updateCompany(company.id, payload)
        validation.resetForm()
      } else {
        // add new Country
        addCompany(payload)
        validation.resetForm()
      }
      toggle()
    },
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCompany(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const handleCompanyClick = data => {
    setCompany(data)
    setIsEdit(true)
    toggle()
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      Promise.all([
        fetchData(limit, page),
        fetchCountries(),
        fetchCompanyTypes(),
      ])
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setCompanyList(companies)
  }, [companies])

  useEffect(() => {
    if (!isEmpty(companies) && !!isEdit) {
      setCompanyList(companies)
      setIsEdit(false)
    }
  }, [companies])

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/company-details/${item.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_COMPANIES && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleCompanyClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_COMPANIES && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableCompany(item) : enableCompany(item)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.CAN_MANAGE_COMPANIES && {
        label: "New company",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getNextPage = () => {
    setLimit(limit + 10)
  }

  const filteredCompanies = chain(companies)
    .filter(company => {
      // Name
      const hasName = company.companyName
        ?.toLowerCase()
        .includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(
    filteredCompanies,
    [p => p.companyName.toUpperCase()],
    "asc"
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Companies")}
            breadcrumbItem={props.t("Companies")}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Companies"}
            description={"We are processing your request, please wait"}
          />

          {/* LIST */}
          {!error && !loading && !isEmpty(companies) && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {/* HEADER SECTION */}
                    <PageHeader
                      title={"Company list"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText className="fw-semibold">
                            {`${companies.length} ${
                              companies.length > 1 ? "Companies" : "Company"
                            }`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    {/* COMPANY LIST */}
                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "companyName", true)}
                      hasNextPage={totalPages > 1}
                      getNextPage={getNextPage}
                      loadingPage={loading}
                      loading={loading}
                      renderItem={item => (
                        <CompanyListItem item={item} actions={getListActions(item)}/>
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!error && !loading && isEmpty(companies) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                title={"No companies"}
                description={
                  "No companies at the moment. Please check again later."
                }
              />

              {userPermissions.CAN_MANAGE_COMPANIES && (
                <PrimaryButton
                  onClick={() => {
                    setIsEdit(false)
                    setModal(true)
                  }}
                  leftIcon="mdi mdi-plus"
                >
                  New company
                </PrimaryButton>
              )}
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Modal */}
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit
                ? `Edit ${company?.companyName} details`
                : "Create new company"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Company name</Label>
                      <Input
                        name="companyName"
                        type="text"
                        placeholder="Company name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.companyName || ""}
                        invalid={
                          validation.touched.companyName &&
                          validation.errors.companyName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.companyName &&
                      validation.errors.companyName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.companyName}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Country</Label>
                      <Input
                        name="country"
                        type="select"
                        placeholder="Select country"
                        defaultValue={"Select country"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.country || ""}
                        invalid={
                          validation.touched.country &&
                          validation.errors.country
                            ? true
                            : false
                        }
                      >
                        {availableCountries.map(country => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.country &&
                      validation.errors.country ? (
                        <FormFeedback type="invalid">
                          {validation.errors.country}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Compny type</Label>
                      <Input
                        name="type"
                        type="select"
                        placeholder="Select company type"
                        defaultValue={"Select company type"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || ""}
                        invalid={
                          validation.touched.type && validation.errors.type
                            ? true
                            : false
                        }
                      >
                        {companyTypes.map(type => (
                          <option key={type.id} value={type.type}>
                            {type.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Company email</Label>
                      <Input
                        name="companyEmail"
                        type="email"
                        placeholder="Company email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.companyEmail || ""}
                        invalid={
                          validation.touched.companyEmail &&
                          validation.errors.companyEmail
                            ? true
                            : false
                        }
                      />
                      {validation.touched.companyEmail &&
                      validation.errors.companyEmail ? (
                        <FormFeedback type="invalid">
                          {validation.errors.companyEmail}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Company location</Label>
                      <Input
                        name="location"
                        type="text"
                        placeholder="Company location"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.location || ""}
                        invalid={
                          validation.touched.location &&
                          validation.errors.location
                            ? true
                            : false
                        }
                      />
                      {validation.touched.location &&
                      validation.errors.location ? (
                        <FormFeedback type="invalid">
                          {validation.errors.location}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Company status</Label>
                      <Input
                        name="status"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ""}
                        invalid={
                          validation.touched.status && validation.errors.status
                            ? true
                            : false
                        }
                      >
                        <option>Select status</option>
                        <option value="Active">Active</option>
                        <option value="Disabled">Disabled</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback status="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <SecondaryButton
                        type="button"
                        onClick={toggle}
                        className="me-4"
                      >
                        Cancel
                      </SecondaryButton>

                      <PrimaryButton type="submit">
                        {!!isEdit ? "Update company" : "Add company"}
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

Company.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Company)
