import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { isEmpty, orderBy, chain } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { Col, Row, Card, CardBody } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import Auth from "models/auth"
import Waitlist from "models/waitlist"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import WaitlistListItem from "components/itemList/waitlistListItem/waitlistListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"

const WaitList = props => {
  //meta title
  document.title = "Waitlist | hevara admin"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [waitLists, setWaitLists] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [waitlistList, setWaitlistList] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    Waitlist.getWaitlist(limit, page)
      .then(data => {
        setWaitLists(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      fetchData(limit, page)
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setWaitlistList(waitLists)
  }, [waitLists])

  useEffect(() => {
    if (!isEmpty(waitLists) && !!isEdit) {
      setWaitlistList(waitLists)
      setIsEdit(false)
    }
  }, [waitLists])

  const sendEmail = data => {
    console.log(data)
  }

  const getListActions = item => {
    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "Reply",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => sendEmail(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const filteredWaitlist = chain(waitLists)
    .filter(list => {
      // Name
      const hasName = list.name?.toLowerCase().includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(filteredWaitlist, [p => p.name.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Waitlist" breadcrumbItem="Waitlist" />

          {/* Loading */}
          {loading && <Loading />}

          {/* LIST */}
          {!loading && !isEmpty(waitLists) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Waitlist"}
                      showDivider={false}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText className="fw-semibold">
                            {`${waitLists.length} ${
                              waitLists.length > 1 ? "Waitlist" : "Waitlist"
                            }`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "name", true)}
                      renderItem={item => (
                        <WaitlistListItem
                          item={item}
                          actions={getListActions(item)}
                          permission={userPermissions.MANAGE_GLOBAL_SETTINGS}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(waitLists) && (
            <div className="text-center">
              <EmptyState
                title={"No waitlist"}
                description={
                  "No waitlist at the moment. Keep checking this section for updates."
                }
              />
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

WaitList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(WaitList)
