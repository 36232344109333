import React from "react"
import moment from "moment"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"

import Notification from "models/notification"

const NotificationItem = ({ notifications }) => {
  const navigate = useNavigate()

  const goToDetails = notification => {
    navigate(`/user-details/${notification.userId}`)
    dismiss(notification)
  }

  const dismiss = notification => {
    Notification.dismissNotification(notification.id, { dismissed: true })
      .then(() => {
        toast.success(`Notification dissmissed`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const getTime = time => {
    const now = moment()
    const then = moment(time)

    const duration = moment.duration(now.diff(then))

    let formattedDate

    if (duration.asMinutes() < 1) {
      formattedDate = Math.floor(duration.asSeconds()) + " seconds ago"
    } else if (duration.asHours() < 1) {
      formattedDate = Math.floor(duration.asMinutes()) + " minutes ago"
    } else {
      formattedDate = Math.floor(duration.asHours()) + " hours ago"
    }

    return formattedDate
  }

  return (
    <React.Fragment>
      {notifications.map((notification, key) => (
        <div onClick={() => goToDetails(notification)} key={key}>
          <div
            className="d-flex p-3 clickable bg-white border border-dark border-2 border-opacity-10"
            style={{ borderRadius: 8 }}
          >
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-light">
                  <i className="mdi mdi-information-outline font-size-18 text-tertiary" />
                </span>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="float-end">
                <p className="text-muted mb-0">
                  <i className="mdi mdi-close-thick font-size-18 p-1" />
                </p>
              </div>
              <h5 className="font-size-14">{notification.title}</h5>

              <p className="text-muted">{notification.message}</p>

              <p className="text-muted mb-0">
                <i className="mdi mdi-clock-outline" />{" "}
                {getTime(notification.dateCreated)}
              </p>
            </div>
          </div>
        </div>
      ))}
      <ToastContainer />
    </React.Fragment>
  )
}

export default NotificationItem
