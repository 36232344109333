import React from "react"
import { isEmpty, map } from "lodash"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getStatus } from "utils/utils"

import "./paymentSettingListItem.scss"

const PaymentSettingListItem = ({ item, actions }) => {
  return (
    <TableRow>
      <div key={item.id} className="hevara-payment-setting-list-item">
        {/* MAIN INFO */}
        <MainInfoList
          title={item.mpesa_initiatorName}
          subtitle={item.companyName}
          avatar={{ image: item.img, initials: item.mpesa_initiatorName }}
          className="text-truncate"
        />

        {/* SHORT CODE */}
        <TableRowInfo
          className="text-truncate"
          text={"Short code"}
          subtext={item.mpesa_shortCode}
        />

        <div className="status">
          <div className="status-list">
            <div className="status-chip">
              {/* PAYMENT TYPE */}
              <TableRowInfo
                className="text-truncate"
                text={"Type"}
                subtext={item.paymentMethod}
              />
            </div>
          </div>

          <div className="actions-wrapper">
            {/* STATUS */}
            {!isEmpty(item.status) && getStatus(item.status)}

            {/* ACTIONS */}
            <ContextualModal
              className="feature-actions"
              style={{ marginLeft: 4 }}
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, item)}
                />
              ))}
            />
          </div>
        </div>
      </div>
    </TableRow>
  )
}

PaymentSettingListItem.defaultProps = {
  user: {},
  actions: [],
}

export default PaymentSettingListItem
