import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { isEmpty, omit, orderBy, chain } from "lodash"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer } from "react-toastify"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import Auth from "models/auth"
import PaymentMethod from "models/paymentMethod"
import Warehouse from "models/warehouse"
import PaymentConfig from "models/paymentConfig"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import {
  PrimaryButton,
  SecondaryButton,
  BUTTON_VARIANT,
} from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import PaymentSettingListItem from "components/itemList/paymentSettingListItem/paymentSettingListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"

const PaymentConfigList = props => {
  //meta title
  document.title = "Payment settings | hevara admin"

  const { companyId } = useParams()

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [paymentConfigs, setPaymentConfigs] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [paymentConfigsList, setPaymentConfigsList] = useState([])
  const [paymentConfig, setPaymentConfig] = useState(null)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page, id) => {
    const filters = {
      companyId: id,
    }
    PaymentConfig.getPaymentConfigs(limit, page, filters)
      .then(data => {
        setPaymentConfigs(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchPaymentMethods = () => {
    PaymentMethod.lookUpPaymentMethods()
      .then(data => {
        setPaymentMethods([
          {
            status: "Default",
            name: "Select payment method",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
  }

  const addPaymentConfig = values => {
    setProcessing(true)
    PaymentConfig.addPaymentConfig(values)
      .then(paymentConfig => {
        setPaymentConfigs([...paymentConfigs, paymentConfig])
        toast.success(`${paymentConfig.mpesa_initiatorName} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updatePaymentConfig = (paymentConfigId, values) => {
    setProcessing(true)
    PaymentConfig.updatePaymentConfig(paymentConfigId, values)
      .then(paymentConfig => {
        setPaymentConfigs(
          paymentConfigs.map(item =>
            item.id.toString() === paymentConfig.id.toString()
              ? { item, ...paymentConfig }
              : item
          )
        )
        toast.success(`${paymentConfig.mpesa_initiatorName} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (companyId && profile) {
      Promise.all([
        fetchData(limit, page, companyId),
        fetchPaymentMethods(),
        fetchCompanyWarehouses(companyId),
      ])
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [companyId, limit, page])

  useEffect(() => {
    setPaymentConfigsList(paymentConfigs)
  }, [paymentConfigs])

  useEffect(() => {
    if (!isEmpty(paymentConfigs) && !!isEdit) {
      setPaymentConfigsList(paymentConfigs)
      setIsEdit(false)
    }
  }, [paymentConfigs])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyId: companyId && companyId,
      ...(paymentConfig && paymentConfig.warehouseId ? { warehouseId: (paymentConfig && paymentConfig.warehouseId) || "" } : {}),
      mpesa_shortCode: (paymentConfig && paymentConfig.mpesa_shortCode) || "",
      mpesa_accountType: (paymentConfig && paymentConfig.mpesa_accountType) || "",
      mpesa_consumerKey: (paymentConfig && paymentConfig.mpesa_consumerKey) || "",
      mpesa_consumerSecret: (paymentConfig && paymentConfig.mpesa_consumerSecret) || "",
      mpesa_passKey: (paymentConfig && paymentConfig.mpesa_passKey) || "",
      mpesa_B2C_Security: (paymentConfig && paymentConfig.mpesa_B2C_Security) || "",
      mpesa_partyA: (paymentConfig && paymentConfig.mpesa_partyA) || "",
      mpesa_initiatorName: (paymentConfig && paymentConfig.mpesa_initiatorName) || "",
      mpesa_environment: (paymentConfig && paymentConfig.mpesa_environment) || "",
      paymentMethod: (paymentConfig && paymentConfig.paymentMethod) || "",
    },
    validationSchema: Yup.object({
      companyId: Yup.string().required("Company information not provided"),
      ...(paymentConfig && paymentConfig.warehouseId ? { warehouseId: Yup.string().required("Please select warehouse") } : {}),
      mpesa_shortCode: Yup.string().required("Please enter mpesa short code"),
      mpesa_accountType: Yup.string().required("Please enter mpesa account type"),
      mpesa_consumerKey: Yup.string().required("Please enter mpesa consumer key"),
      mpesa_consumerSecret: Yup.string().required("Please enter mpesa consumer secret"),
      mpesa_passKey: Yup.string().required("Please enter mpesa pass key"),
      mpesa_B2C_Security: Yup.string().required("Please enter mpesa B2C security"),
      mpesa_partyA: Yup.string().required("Please enter mpesa party A"),
      mpesa_initiatorName: Yup.string().required("Please enter mpesa initiator name"),
      mpesa_environment: Yup.string().required("Please enter mpesa environment"),
      paymentMethod: Yup.string().required("Please select payment method"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updatePaymentConfig(paymentConfig.id, values)
        validation.resetForm()
      } else {
        // add new
        addPaymentConfig(values)
        validation.resetForm()
      }
      toggle()
    },
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setPaymentConfig(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const handleClick = data => {
    setPaymentConfig(data)
    setIsEdit(true)
    toggle()
  }

  const enablePaymentConfig = data => {
    setProcessing(true)
    const payload = {
      ...omit(data, ["id", "companyName", "dateCreated"]),
      status: "Active",
    }
    PaymentConfig.updatePaymentConfig(data.id, payload)
      .then(data => {
        setPaymentConfigs(
          paymentConfigs.map(item =>
            item.id.toString() === data.id.toString() ? { ...data } : item
          )
        )
        toast.success(`${data.mpesa_initiatorName} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disablePaymentConfig = data => {
    setProcessing(true)
    const payload = {
      ...omit(data, ["id", "companyName", "dateCreated"]),
      status: "Disabled",
    }
    PaymentConfig.updatePaymentConfig(data.id, payload)
      .then(data => {
        setPaymentConfigs(
          paymentConfigs.map(item =>
            item.id.toString() === data.id.toString() ? { ...data } : item
          )
        )
        toast.success(`${data.mpesa_initiatorName} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      userPermissions.CAN_MANAGE_PAYMENT_CONFIGS && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.CAN_MANAGE_PAYMENT_CONFIGS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive ? disablePaymentConfig(item) : enablePaymentConfig(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.CAN_MANAGE_PAYMENT_CONFIGS && {
        label: "New payment setting",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickNewPaymentConfig = () => {
    setIsEdit(false)
    setModal(true)
  }

  const filteredConfigs = chain(paymentConfigs)
    .filter(config => {
      // mpesa_initiatorName
      const hasLabel = config.mpesa_initiatorName
        ?.toLowerCase()
        .includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(
    filteredConfigs,
    [p => p.mpesa_initiatorName.toUpperCase()],
    "asc"
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Payment settings"
            breadcrumbItem="Payment settings"
          />

          {/* Loading */}
          {loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Payment setting"}
            description={"We are processing your request, please wait"}
          />

          {/* LIST */}
          {!loading && !isEmpty(paymentConfigs) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Payment settings"}
                      showBackButton={true}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText className="fw-semibold">
                            {`${paymentConfigs.length} ${
                              paymentConfigs.length > 1
                                ? "Payment settings"
                                : "Payment setting"
                            }`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "mpesa_initiatorName", true)}
                      renderItem={item => (
                        <PaymentSettingListItem
                          item={item}
                          actions={getListActions(item)}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(paymentConfigs) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                visible={isEmpty(paymentConfigs)}
                title={`No payment settings`}
                description={`No payment settings at the moment. Please check again later.`}
                firstButton={
                  userPermissions.CAN_MANAGE_PAYMENT_CONFIGS && {
                    label: `New payment setting`,
                    onClick: onClickNewPaymentConfig,
                    disabled: processing,
                  }
                }
              />
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Modal */}
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit payment setting" : "New payment setting"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    {/* MPESA SHORT CODE */}
                    <div className="mb-3">
                      <Label className="form-label">Mpesa short code</Label>
                      <Input
                        name="mpesa_shortCode"
                        type="text"
                        placeholder="Short code"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mpesa_shortCode || ""}
                        invalid={
                          validation.touched.mpesa_shortCode &&
                          validation.errors.mpesa_shortCode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mpesa_shortCode &&
                      validation.errors.mpesa_shortCode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mpesa_shortCode}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* MPESA ACCOUNT TYPE */}
                    <div className="mb-3">
                      <Label className="form-label">Mpesa account type</Label>
                      <Input
                        name="mpesa_accountType"
                        type="text"
                        placeholder="Mpesa account type"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mpesa_accountType || ""}
                        invalid={
                          validation.touched.mpesa_accountType &&
                          validation.errors.mpesa_accountType
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mpesa_accountType &&
                      validation.errors.mpesa_accountType ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mpesa_accountType}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* MPESA CONSUMER KEY */}
                    <div className="mb-3">
                      <Label className="form-label">Mpesa consumer key</Label>
                      <Input
                        name="mpesa_consumerKey"
                        type="text"
                        placeholder="Mpesa consumer key"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mpesa_consumerKey || ""}
                        invalid={
                          validation.touched.mpesa_consumerKey &&
                          validation.errors.mpesa_consumerKey
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mpesa_consumerKey &&
                      validation.errors.mpesa_consumerKey ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mpesa_consumerKey}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* MPESA CONSUMER SECRET */}
                    <div className="mb-3">
                      <Label className="form-label">
                        Mpesa consumer secret
                      </Label>
                      <Input
                        name="mpesa_consumerSecret"
                        type="text"
                        placeholder="Mpesa consumer secret"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mpesa_consumerSecret || ""}
                        invalid={
                          validation.touched.mpesa_consumerSecret &&
                          validation.errors.mpesa_consumerSecret
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mpesa_consumerSecret &&
                      validation.errors.mpesa_consumerSecret ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mpesa_consumerSecret}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* MPESA PASS KEY*/}
                    <div className="mb-3">
                      <Label className="form-label">Mpesa pass key</Label>
                      <Input
                        name="mpesa_passKey"
                        type="text"
                        placeholder="Mpesa pass key"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mpesa_passKey || ""}
                        invalid={
                          validation.touched.mpesa_passKey &&
                          validation.errors.mpesa_passKey
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mpesa_passKey &&
                      validation.errors.mpesa_passKey ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mpesa_passKey}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* MPESA PASS B2C SECURITY */}
                    <div className="mb-3">
                      <Label className="form-label">Mpesa B2C security</Label>
                      <Input
                        name="mpesa_B2C_Security"
                        type="text"
                        placeholder="Mpesa B2C security"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mpesa_B2C_Security || ""}
                        invalid={
                          validation.touched.mpesa_B2C_Security &&
                          validation.errors.mpesa_B2C_Security
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mpesa_B2C_Security &&
                      validation.errors.mpesa_B2C_Security ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mpesa_B2C_Security}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* MPESA PARTY A */}
                    <div className="mb-3">
                      <Label className="form-label">Mpesa party A</Label>
                      <Input
                        name="mpesa_partyA"
                        type="text"
                        placeholder="Mpesa party A"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mpesa_partyA || ""}
                        invalid={
                          validation.touched.mpesa_partyA &&
                          validation.errors.mpesa_partyA
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mpesa_partyA &&
                      validation.errors.mpesa_partyA ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mpesa_partyA}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* MPESA INITIATOR NAME */}
                    <div className="mb-3">
                      <Label className="form-label">Mpesa initiator name</Label>
                      <Input
                        name="mpesa_initiatorName"
                        type="text"
                        placeholder="Mpesa initiator name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mpesa_initiatorName || ""}
                        invalid={
                          validation.touched.mpesa_initiatorName &&
                          validation.errors.mpesa_initiatorName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mpesa_initiatorName &&
                      validation.errors.mpesa_initiatorName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mpesa_initiatorName}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* MPESA ENVIRONMENT */}
                    <div className="mb-3">
                      <Label className="form-label">Mpesa environment</Label>
                      <Input
                        name="mpesa_environment"
                        type="text"
                        placeholder="Mpesa environment"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mpesa_environment || ""}
                        invalid={
                          validation.touched.mpesa_environment &&
                          validation.errors.mpesa_environment
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mpesa_environment &&
                      validation.errors.mpesa_environment ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mpesa_environment}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* PAYMENT METHOD */}
                    <div className="mb-3">
                      <Label className="form-label">Payment method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        placeholder="Select payment method"
                        defaultValue={"Select payment method"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentMethod || ""}
                        invalid={
                          validation.touched.paymentMethod &&
                          validation.errors.paymentMethod
                            ? true
                            : false
                        }
                      >
                        {paymentMethods.map(method => (
                          <option key={method.id} value={method.name}>
                            {method.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.paymentMethod &&
                      validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid">
                          {validation.errors.paymentMethod}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* WAREHOUSE */}
                    <div className="mb-3">
                      <Label className="form-label">Warehouse (Optional)</Label>
                      <Input
                        name="warehouseId"
                        type="select"
                        placeholder="Select warehouse"
                        defaultValue={"Select warehouse"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.warehouseId || ""}
                        invalid={
                          validation.touched.warehouseId &&
                          validation.errors.warehouseId
                            ? true
                            : false
                        }
                      >
                        {warehouses.map(role => (
                          <option key={role.id} value={role.id}>
                            {role.warehouseName}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.warehouseId &&
                      validation.errors.warehouseId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.warehouseId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <SecondaryButton
                        type="button"
                        onClick={toggle}
                        className="me-4"
                      >
                        Cancel
                      </SecondaryButton>

                      <PrimaryButton type="submit">
                        {!!isEdit
                          ? "Update payment setting"
                          : "Add payment setting"}
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

PaymentConfigList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(PaymentConfigList)
