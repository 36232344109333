import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { chain, isEmpty, omit, orderBy } from "lodash"
import { ToastContainer, toast } from "react-toastify"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Card,
  CardBody,
  Container
} from "reactstrap"

import Auth from "models/auth"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import ProductMasterList from "models/productList"
import ProductCategory from "models/productCategory"
import ProductTypes from "models/productTypes"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import ProductMasterListItem from "components/itemList/productMasterListItem/productMasterListItem"
import ProductListModal from "./ProductListModal"

const ProductList = props => {
  //meta title
  document.title = "Product master list | hevara-DMS"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [productLists, setProductLists] = useState([])
  const [productCategories, setProductCategories] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [productListList, setProductListList] = useState([])
  const [productList, setProductList] = useState(null)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    ProductMasterList.getProductLists(limit, page)
      .then(data => {
        setProductLists(data.results)
        setTotalPages(data.totalPages)
        setTotalResults(data.totalResults)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchProductCategories = () => {
    ProductCategory.lookUpProductCategories()
      .then(data => {
        setProductCategories([
          {
            status: "Default",
            name: "Select product category",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchProductTypes = () => {
    ProductTypes.lookUpProductTypes()
      .then(data => {
        setProductTypes([
          {
            status: "Default",
            label: "Select product type",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const addProductList = values => {
    setProcessing(true)

    ProductMasterList.addProductList(values)
      .then(list => {
        setProductLists([...productLists, list])
        toast.success(`${list.name} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateProductList = (productListId, values) => {
    setProcessing(true)

    ProductMasterList.updateProductList(productListId, values)
      .then(list => {
        setProductLists(
          productLists.map(item =>
            item.id.toString() === list.id.toString()
              ? { item, ...list }
              : item
          )
        )
        toast.success(`${list.name} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableProductList = values => {
    setProcessing(true)

    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "ACTIVE",
    }

    ProductMasterList.updateProductList(values.id, payload)
      .then(list => {
        setProductLists(
          productLists.map(item =>
            item.id.toString() === list.id.toString()
              ? { ...list }
              : item
          )
        )
        toast.success(`${list.name} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableProductList = values => {
    setProcessing(true)

    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "DISABLED",
    }

    ProductMasterList.updateProductList(values.id, payload)
      .then(list => {
        setProductLists(
          productLists.map(item =>
            item.id.toString() === list.id.toString()
              ? { ...list }
              : item
          )
        )
        toast.success(`${list.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      Promise.all([fetchData(limit, page), fetchProductCategories(), fetchProductTypes()])
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setProductListList(productLists)
  }, [productLists])

  useEffect(() => {
    if (!isEmpty(productLists) && !!isEdit) {
      setProductListList(productLists)
      setIsEdit(false)
    }
  }, [productLists])

  const handleClick = item => {
    setProductList(item)
    setIsEdit(true)
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setProductList(null)
    } else {
      setModal(true)
    }
  }

  const getListActions = item => {
    const isActive = item.status === "ACTIVE"

    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/product-list-details/${item.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "Edit details",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => isActive ? disableProductList(item) : enableProductList(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "New master product",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const filteredProductList = chain(productLists)
    .filter(productList => {
      const hasName = productList.name
        ?.toLowerCase()
        .includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(filteredProductList, [p => p.name.toUpperCase()], "asc")

  const getNextPage = () => {
    setLimit(limit + 10)
  }

  const onClickNewProductList = () => {
    setIsEdit(false)
    setModal(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs 
            title={props.t("Product master list")} 
            breadcrumbItem={props.t("Product master list")} 
          />

          {/* Loading */}
          {loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Product master list"}
            description={"We are processing your request, please wait a moment..."}
          />

          {/* PRODUCT LIST MODAL */}
          <ProductListModal 
            modalIsOpen={modal}
            isEdit={isEdit}
            toggle={toggle}
            productCategories={productCategories}
            productTypes={productTypes}
            productList={productList}
            updateProductList={updateProductList}
            addProductList={addProductList}
          />

          {/* LIST */}
          {!loading && !isEmpty(productLists) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Product master list"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            {`${productLists.length} ${productLists.length > 1 ? "Products" : "Product"}`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    {/* LIST */}
                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "name", true)}
                      hasNextPage={totalPages > 1}
                      getNextPage={getNextPage}
                      loadingPage={loading}
                      loading={loading}
                      renderItem={item => (
                        <ProductMasterListItem
                          item={item}
                          actions={getListActions(item)}
                          permission={userPermissions.MANAGE_GLOBAL_SETTINGS}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(productLists) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                visible={isEmpty(productLists)}
                title={`No products`}
                description={`No products have been set yet`}
                firstButton={
                  userPermissions.MANAGE_GLOBAL_SETTINGS && {
                    label: `New master product`,
                    onClick: onClickNewProductList,
                    disabled: processing,
                  }
                }
              />
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

ProductList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProductList)
