import axios from "axios"

import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getOrderTypes(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      label: filters.label,
      value: filters.value,
      status: filters.status,
    }
    const url = queryParams(API.getApi().ORDER_TYPE.GET_ORDER_TYPES, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getOrderType(orderTypeId) {
    return axios
      .get(`${API.getApi().ORDER_TYPE.GET_ORDER_TYPE}/${orderTypeId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addOrderType(values) {
    return axios
      .post(API.getApi().ORDER_TYPE.ADD_ORDER_TYPE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateOrderType(orderTypeId, values) {
    return axios
      .patch(
        `${API.getApi().ORDER_TYPE.UPDATE_ORDER_TYPE}/${orderTypeId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
}
