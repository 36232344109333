import React from "react"
import { Navigate } from "react-router-dom"

const Authmiddleware = props => {
  const params = props?.params || {}

  if (params?.token) {
    return (
      <Navigate
        to={{ pathname: "/resetPassword", state: { from: props.location } }}
      />
    )
  }

  if (params?.token && params?.id) {
    return (
      <Navigate
        to={{ pathname: "/account", state: { from: props.location } }}
      />
    )
  }

  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
