import React from "react"
import { map, isEmpty } from "lodash"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getStatus } from "utils/utils"

import "./warehouseListItem.scss"

const WarehouseListItem = ({ item, actions }) => {
  return (
    <TableRow>
      <div key={item.id} className="hevara-warehouse-list-item">
        {/* MAIN INFO */}
        <MainInfoList
          title={item.warehouseName}
          subtitle={item.location}
          avatar={{ image: item.img, initials: item.warehouseName }}
          className="text-truncate"
        />

        <div className="date-item">
          {/* LOCATION */}
          <div className="title-and-date">
            <TableRowInfo
              className="text-truncate"
              text={item.companyName}
            />
          </div>

          <div className="status-and-actions">
            {/* STATUS */}
            {!isEmpty(item.status) && getStatus(item.status)}

            {/* ACTIONS */}
            <ContextualModal
              className="item-actions"
              style={{ marginLeft: 4 }}
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, item)}
                />
              ))}
            />
          </div>
        </div>
      </div>
    </TableRow>
  )
}

WarehouseListItem.defaultProps = {
  item: {},
  actions: [],
}

export default WarehouseListItem
