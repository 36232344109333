import React from "react"
import { isEmpty, map } from "lodash"
import { Link } from "react-router-dom"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import { ContextualModal, ContextualModalListItem, CONTEXTUAL_MODAL_POSITIONS } from "components/ContextualModalBox"
import { getStatus, formatName, formatVolume } from "utils/utils"
import "./productMasterListItem.scss"

const ProductMasterListItem = ({ item, actions, permission }) => {
  return (
    <TableRow>
      <Link 
        key={item.id}
        to={`/product-list-details/${item.id}`}
        className="hevara-product_master-list-item reset-link text-tertiary">
        {/* MAIN INFO */}
        <MainInfoList
          title={item.name}
          subtitle={formatVolume(item.size)}
          avatar={{ image: item.image?.file, initials: item.name }}
          className="text-truncate"
        />

        {/* CATEGORY */}
        <TableRowInfo
          className="text-truncate"
          text={"Category"}
          subtext={formatName(item.categoryName)}
        />

        <div className="status">
          <div className="status-list">
            <div className="status-chip">
              <TableRowInfo
                className="text-truncate"
                text={"Type"}
                subtext={formatName(item.type)}
              />
            </div>
          </div>

          <div className="actions-wrapper">
            {/* STATUS */}
            {!isEmpty(item.status) && getStatus(item.status)}

            {/* ACTIONS */}
            {permission && (
              <ContextualModal
                className="feature-actions"
                style={{ marginLeft: 4 }}
                position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
                contentElement={map(actions, (modalItem, index) => (
                  <ContextualModalListItem
                    key={index}
                    {...modalItem}
                    onClick={e => modalItem.onClick(e, item)}
                  />
                ))}
              />
            )}
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

ProductMasterListItem.defaultProps = {
  item: {},
  actions: [],
}

export default ProductMasterListItem
