import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getBillingPlans(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      name: filters.name,
      code: filters.code,
      status: filters.status,
    }
    const url = queryParams(API.getApi().BILLING_PLANS.GET_BILLING_PLANS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getBillingPlan(billingPlanId) {
    return axios.get(`${API.getApi().BILLING_PLANS.GET_BILLING_PLAN}/${billingPlanId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addBillingPlan(values) {
    return axios.post(API.getApi().BILLING_PLANS.ADD_BILLING_PLAN, values)
      .then(async ({ data }) => {
        return data
      })
  },
  selectBillingPlan(values) {
    return axios.post(API.getApi().BILLING_PLANS.SELECT_BILLING_PLAN, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateBillingPlan(billingPlanId, values) {
    return axios.patch(`${API.getApi().BILLING_PLANS.UPDATE_BILLING_PLAN}/${billingPlanId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  lookupBillingPlans() {
    return axios.get(API.getApi().LOOKUPS.LOOKUP_BILLING_PLANS)
      .then(async ({ data }) => {
        return data
      })
  },
}
