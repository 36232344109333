import React, { useEffect, useState } from "react"
import { isEmpty, omit, orderBy } from "lodash"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import Auth from "models/auth"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import CompanyTypes from "models/companyTypes"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { PrimaryButton, SecondaryButton, BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import CompanyTypeListItem from "components/itemList/companyTypeListItem/companyTypeListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey, filterData } from "utils/utils"

const CompanyTypeList = props => {
  //meta title
  document.title = "Company types | hevara admin"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [companyTypes, setCompanyTypes] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [companyTypeList, setCompanyTypeList] = useState([])
  const [companyType, setCompanyType] = useState(null)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    CompanyTypes.getCompanyTypes(limit, page)
      .then(data => {
        setCompanyTypes(data.results)
        setTotalPages(data.totalPages)
        setTotalResults(data.totalResults)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const addCompanyType = values => {
    setProcessing(true)
    CompanyTypes.addCompanyType(values)
      .then(companyType => {
        setCompanyTypes([...companyTypes, companyType])
        toast.success(`${companyType.name} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateCompanyType = (companyTypeId, values) => {
    setProcessing(true)
    CompanyTypes.updateCompanyType(companyTypeId, values)
      .then(companyType => {
        setCompanyTypes(
          companyTypes.map(item =>
            item.id.toString() === companyType.id.toString()
              ? { item, ...companyType }
              : item
          )
        )
        toast.success(`${companyType.name} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableCompanyType = values => {
    setProcessing(true)
    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "Active",
    }
    CompanyTypes.updateCompanyType(values.id, payload)
      .then(companyType => {
        setCompanyTypes(
          companyTypes.map(item =>
            item.id.toString() === companyType.id.toString()
              ? { ...companyType }
              : item
          )
        )
        toast.success(`${companyType.name} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableCompanyType = values => {
    setProcessing(true)
    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "Disabled",
    }
    CompanyTypes.updateCompanyType(values.id, payload)
      .then(companyType => {
        setCompanyTypes(
          companyTypes.map(item =>
            item.id.toString() === companyType.id.toString()
              ? { ...companyType }
              : item
          )
        )
        toast.success(`${companyType.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      fetchData(limit, page)
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setCompanyTypeList(companyTypes)
  }, [companyTypes])

  useEffect(() => {
    if (!isEmpty(companyTypes) && !!isEdit) {
      setCompanyTypeList(companyTypes)
      setIsEdit(false)
    }
  }, [companyTypes])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (companyType && companyType.name) || "",
      type: (companyType && companyType.type) || "",
      description: (companyType && companyType.description) || "",
      status: (companyType && companyType.status) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      type: Yup.string().required("This field is required"),
      description: Yup.string().required("This field is required"),
      status: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updateCompanyType(companyType.id, values)
      } else {
        // add new
        addCompanyType(values)
      }
      validation.resetForm()
      toggle()
    },
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCompanyType(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const handleClick = arg => {
    const companyType = arg
    setCompanyType(companyType)
    setIsEdit(true)
    toggle()
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "Edit details",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive ? disableCompanyType(item) : enableCompanyType(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "New Company type",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getNextPage = () => {
    setLimit(limit + 10)
  }

  const onClickNewCompanyType = () => {
    setIsEdit(false)
    setModal(true)
  }

  const data = orderBy(filterData(companyTypes), [p => p.name.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Company types" breadcrumbItem="Company type List" />

          {/* Loading */}
          {loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Company types"}
            description={"We are processing your request, please wait a moment..."}
          />

          {/* LIST */}
          {!loading && !isEmpty(companyTypes) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Company type"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText className="fw-semibold">
                            {`${companyTypes.length} ${ companyTypes.length > 1 ? "Types" : "Type"}`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    {/* LIST */}
                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "name", true)}
                      hasNextPage={totalPages > 1}
                      getNextPage={getNextPage}
                      loadingPage={loading}
                      loading={loading}
                      renderItem={item => (
                        <CompanyTypeListItem
                          item={item}
                          actions={getListActions(item)}
                          permission={userPermissions.MANAGE_GLOBAL_SETTINGS}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(companyTypes) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                visible={isEmpty(companyTypes)}
                title={`No company types`}
                description={`No company types have been added yet`}
                firstButton={
                  userPermissions.MANAGE_GLOBAL_SETTINGS && {
                    label: `New company type`,
                    onClick: onClickNewCompanyType,
                    disabled: processing,
                  }
                }
              />
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Company type Modal */}
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit company type" : "New company type"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Name</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={validation.touched.name && validation.errors.name ? true : false}
                      />
                      {validation.touched.name && validation.errors.name && (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      )}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Value</Label>
                      <Input
                        name="type"
                        type="text"
                        placeholder="Value"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || ""}
                        disabled={!!validation.values.type}
                        invalid={validation.touched.type && validation.errors.type ? true : false}
                      />
                      {validation.touched.type && validation.errors.type && (
                        <FormFeedback type="invalid">
                          {validation.errors.type}
                        </FormFeedback>
                      )}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Description</Label>
                      <Input
                        name="description"
                        type="text"
                        placeholder="Description"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={validation.touched.description && validation.errors.description ? true : false}
                      />
                      {validation.touched.description && validation.errors.description && (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      )}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Status</Label>
                      <Input
                        name="status"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ""}
                        invalid={validation.touched.status && validation.errors.status ? true : false}
                      >
                        <option>Select status</option>
                        <option value="Active">Active</option>
                        <option value="Disabled">Disabled</option>
                      </Input>
                      {validation.touched.status && validation.errors.status && (
                        <FormFeedback status="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <PrimaryButton type="submit" className="me-4">
                        {!!isEdit ? "Update" : "Create"}
                      </PrimaryButton>

                      <SecondaryButton type="button" onClick={toggle}>
                        Cancel
                      </SecondaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

export default withTranslation()(CompanyTypeList)
