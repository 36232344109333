export const getIconColor = props => {
  return `
    fill: "#6B8F3C"
  `
}

export const getDataItemStyles = props => {
  return `
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .hevara-avatar {
      margin-right: 16px;
      margin-top: 2px;
    }
    .hevara-data-label-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .hevara-data-label-row-icon {
      margin-left: 8px;
      ${getIconColor(props)}
    }
    .hevara-link {
      margin-top: 4px;
    }
  `
}
