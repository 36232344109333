import React from "react"
import { isEmpty, map } from "lodash"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getStatus } from "utils/utils"

import "./billingPlanListItem.scss"

const BillingPlanListItem = ({ item, actions, permission }) => {
  return (
    <TableRow>
      <div key={item.id} className="hevara-billingPlan-list-item">
        {/* MAIN INFO */}
        <MainInfoList
          title={item.name}
          avatar={{ image: item.assetFlag, initials: item.name }}
          className="text-truncate"
        />

        {/* CODE */}
        <TableRowInfo
          className="text-truncate"
          text={"Code"}
          subtext={item.code}
        />

        <div className="status">
          <div className="status-list">
            <div className="status-chip">
              <TableRowInfo
                className="text-truncate"
                text={"Value"}
                subtext={`Ksh ${item.value?.toLocaleString()}`}
              />
            </div>
          </div>

          <div className="actions-wrapper">
            {/* IS AVAILABLE */}
            {!isEmpty(item.status) && getStatus(item.status)}

            {/* ACTIONS */}
            {permission && (
              <ContextualModal
                className="feature-actions"
                style={{ marginLeft: 4 }}
                position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
                contentElement={map(actions, (modalItem, index) => (
                  <ContextualModalListItem
                    key={index}
                    {...modalItem}
                    onClick={e => modalItem.onClick(e, item)}
                  />
                ))}
              />
            )}
          </div>
        </div>
      </div>
    </TableRow>
  )
}

BillingPlanListItem.defaultProps = {
  item: {},
  actions: [],
}

export default BillingPlanListItem
