import React, { useState } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

import Auth from "models/auth"
import OtpInput from "components/OtpInput"
import Footer from "components/Footer"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { PrimaryButton } from "components/Button"
import { BodyText, BodyTextBold } from "components/atoms"

const AccountSetup = props => {
  //meta title
  document.title = "Account Setup | hevara - DMS"

  const [modal, setModal] = useState(false)
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState("")
  const [otp, setOtp] = useState("")

  // Read token value from url
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get("token")
  const userId = searchParams.get("id")

  //form validation: For passwords
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      password2: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter your password")
        .matches(
          /^(?=.*\d)(?=.*[a-zA-Z]).+$/,
          "Password must contain at least one letter and one digit"
        ),
      password2: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please re-enter your password"),
    }),
    onSubmit: values => {
      // Perform additional validation just to be sure => too much OCD, hehehehe, again
      if (values.password !== values.password2) {
        validation.setFieldError("password2", "Passwords must match")
      } else {
        setLoading(true)
        // Request OTP Code here
        Auth.requestOTP(userId)
          .then(() => {
            setModal(true)
          })
          .catch(error => {
            setErrorMessage(error.response?.data?.message)
          })
          .finally(() => setLoading(false))
      }
    },
  })

  const handleOtpSubmit = otp => {
    setOtp(otp)
  }

  const accountSetUp = () => {
    setLoading1(true)
    const payload = {
      userId: userId || "",
      token: token || "",
      password: validation.values.password || "",
      otpCode: otp,
    }
    Auth.accountSetup(payload)
      .then(() => {
        history("/dashboard")
        validation.resetForm()
        toggle()
      })
      .catch(error => {
        setError(error.response?.data?.message)
      })
      .finally(() => {
        setLoading1(false)
      })
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-dark p-4">
                        <BodyTextBold>Account setup</BodyTextBold>
                        <BodyText>Set up your account credentials</BodyText>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {errorMessage && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {errorMessage}
                      </Alert>
                    )}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />

                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Repeat password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password2"
                            value={validation.values.password2 || ""}
                            type={show2 ? "text" : "password"}
                            placeholder="Repeat password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password2 &&
                              validation.errors.password2
                                ? true
                                : false
                            }
                          />

                          <button
                            onClick={() => setShow2(!show2)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password2 &&
                        validation.errors.password2 ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password2}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="d-flex flex-column align-items-center">
                        <PrimaryButton type="submit" className="w-lg">
                          {loading ? "Processing..." : "Continue"}
                        </PrimaryButton>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <Footer />
              </div>
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Security code
            </ModalHeader>
            <ModalBody>
              <div className="text-center p-2">
                <div className="p-2">
                  <div className="text-center mb-4">
                    <div className="avatar-md mx-auto">
                      <div className="avatar-title rounded-circle bg-light">
                        <i className="bx bx-shield-quarter h1 mb-0 text-primary"></i>
                      </div>
                    </div>
                    <div className="p-2 mt-4">
                      <BodyTextBold>Verify code</BodyTextBold>
                      <BodyText className="mb-5">
                        Please enter the 4 digit code sent to your email address
                      </BodyText>

                      {error && (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {error}
                        </Alert>
                      )}

                      <OtpInput onSubmit={handleOtpSubmit} />
                    </div>
                  </div>
                  <div className="mt-4">
                    <PrimaryButton type="button" onClick={accountSetUp}>
                      {loading1 ? "Processing..." : "Confirm"}
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

AccountSetup.propTypes = {
  history: PropTypes.object,
}

export default withRouter(AccountSetup)
