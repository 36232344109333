import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { isEmpty, omit, map } from "lodash"
import { toast, ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import Switch from "react-switch"
import {
  Card,
  CardBody,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

import Fleet from "models/fleet"
import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import ErrorState from "components/ErrorState/errorState"
import User from "models/user"
import Auth from "models/auth"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import CONST from "utils/constants"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import CardItem from "./cardItem"
import { PrimaryButton, BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import {
  getCompanyRole,
  getLastFourDigits,
  getStatus,
  transformPermission,
} from "utils/utils"

import "./styles.scss"
import Link, { LinkLayout } from "components/Link"

const { ROLES } = CONST

const primary = "#42bfc1"
const smooth_gray = "#cccccc"

/**
 * Permission Item Row
 */
const PermissionItem = ({ label, isChecked, onChange }) => (
  <div className="d-flex center-y center-x permission-box" key={label}>
    <Switch
      uncheckedIcon={false}
      checkedIcon={false}
      width={51}
      offColor={smooth_gray}
      onColor={primary}
      onChange={() => onChange(!isChecked)}
      checked={isChecked}
      className="me-2"
    />
    <span className="mt-1">{transformPermission(label)}</span>
  </div>
)

const UserDetails = props => {
  // meta title
  document.title = "User details | hevara-DMS"

  const { userId } = useParams()

  const navigate = useNavigate()

  const [modal, setModal] = useState(false)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [fleets, setFleets] = useState([])
  const [user, setUser] = useState([])
  const [permissions, setPermissions] = useState({})
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})
  const [profile, setProfile] = useState({})

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const fetchUserData = id => {
    User.getUserDetails(id)
      .then(data => {
        setUser(data)
        setPermissions(data.permission)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const getVehicles = (limit, page, id) => {
    const filters = {
      routeAgent: id,
    }
    Fleet.getFleets(limit, page, filters)
      .then(data => {
        setFleets(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (userId && profile) {
      fetchUserData(userId)
      getVehicles(limit, page, userId)
      setUserPermissions(JSON.parse(profile).permission)
      setProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [userId, limit, page])

  // Function to handle changes in the toggle switches
  const handlePermissionChange = key => {
    const updatedPermissions = { ...permissions, [key]: !permissions[key] }
    setPermissions(updatedPermissions)
  }

  const updateUserPermissions = () => {
    toggle() // Close permission modal

    setProcessing(true)
    const payload = {
      ...omit(user, [
        "id",
        "isMobileNumberVerified",
        "isEmailVerified",
        "dateCreated",
        "otpCode",
        "warehouseName",
        "middleName",
      ]),
      permission: permissions,
    }

    User.updateUser(user.id, payload)
      .then(data => {
        setUser(data)
        setPermissions(data.permission)
        toast.success(`${data.fullName} permissions updated`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const getHeaderActions = () => {
    const canManageUsers = userPermissions.CAN_MANAGE_USERS
    const isActive = user.status === "Active"
    const resendInvitation = user.status === "Disabled"

    const actions = [
      canManageUsers && {
        label: "Edit",
        icon: "mdi mdi-pencil",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      {
        label: "Permissions",
        icon: "mdi mdi-security",
        onClick: () => toggle(),
        variant: BUTTON_VARIANT.SECONDARY,
      },
      resendInvitation && {
        label: "Resend",
        icon: "mdi mdi-send",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.SECONDARY,
      },
      canManageUsers && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? console.log("WIP") : console.log("WIP")),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("User details")}
            breadcrumbItem={props.t("User details")}
          />

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"User details"}
            description={"We are processing your request, please wait"}
          />

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Permissions"}
            description={"We are processing your request, please wait"}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {!error && !loading && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    avatar={{ initials: user.fullName, image: user.image?.file }}
                    title={user.fullName}
                    showBackButton={true}
                    showDivider={false}
                    actions={<HeaderActions actions={getHeaderActions()} />}
                    info={
                      <PageHeaderInfo>
                        {!loading && !isEmpty(user) && (
                          <>
                            <div className="mt-2">
                              {getStatus(user.status)}
                            </div>

                            <PageHeaderInfoText className="fw-semibold">
                              {getCompanyRole(user.companyRole)}
                            </PageHeaderInfoText>
                          </>
                        )}
                      </PageHeaderInfo>
                    }
                  />

                  <div className="hevara-user-data">
                    <div className="tx-grid mb-3">
                      <div className="mb-4">
                        <h5 className="text-muted">Personal details</h5>

                        <div className="detail-grid">
                          <DataItem
                            label={props.t("First name")}
                            value={user.firstName}
                          />

                          <DataItem
                            label={props.t("Middle name")}
                            value={user.middleName}
                          />

                          <DataItem
                            label={props.t("Last name")}
                            value={user.lastName}
                          />

                          <DataItem
                            label={props.t("Email address")}
                            value={user.email}
                          />

                          <DataItem
                            label={props.t("Phone")}
                            value={getLastFourDigits(user.mobileNumber)}
                          />

                          <DataItem
                            label={props.t("Country")}
                            value={user.countryName}
                          />
                        </div>
                      </div>

                      {user.companyRole === ROLES.ROUTE_AGENT && (
                        <div>
                          <h5 className="text-muted">User Vehicles </h5>
                          {!isEmpty(fleets) ? (
                            <div>
                              {map(fleets.slice(0, 3), fleet => (
                                <CardItem
                                  key={`card-${fleet.id}`}
                                  vehicle={fleet}
                                />
                              ))}

                              {fleets.length > 3 && (
                                <LinkLayout className="mt-3">
                                  <Link onClick={() => navigate(`/vehicles/${user.companyId}`)}>
                                    View all vehicles
                                  </Link>
                                </LinkLayout>
                              )}
                            </div>
                          ) : (
                            <div className="d-flex center-y center-x permission-box justify-content-center py-4">
                              <p className="text-center mb-0">
                                This user does not have vehicles yet.
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Permissions
            </ModalHeader>
            <ModalBody>
              {Object.keys(permissions).map(key => (
                <PermissionItem
                  key={key}
                  label={key}
                  isChecked={permissions[key]}
                  onChange={() => handlePermissionChange(key)}
                />
              ))}

              <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                <PrimaryButton
                  type="button"
                  onClick={() => updateUserPermissions()}
                  disabled={user.id === profile.id || !userPermissions.CAN_MANAGE_USERS}
                >
                  {loading ? "Processing..." : "Update permissions"}
                </PrimaryButton>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

UserDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(UserDetails)
