import React, { useEffect, useState } from "react"
import { isEmpty, omit, orderBy, chain } from "lodash"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import Auth from "models/auth"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import Role from "models/role"
import EmptyState from "components/EmptyState"
import ErrorState from "components/ErrorState/errorState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import {
  PrimaryButton,
  SecondaryButton,
  BUTTON_VARIANT,
} from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import RoleListItem from "components/itemList/roleItemList/roleListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"

const RoleList = props => {
  //meta title
  document.title = "Roles | hevara admin"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [roles, setRoles] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [roleList, setRoleList] = useState([])
  const [role, setRole] = useState(null)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})

  const fetchData = (limit, page) => {
    Role.getRoles(limit, page)
      .then(data => {
        setRoles(data.results)
        setTotalPages(data.totalPages)
        setTotalResults(data.totalResults)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const addRole = values => {
    setProcessing(true)
    Role.addRole(values)
      .then(role => {
        setRoles([...roles, role])
        toast.success(`${role.label} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateRole = (roleId, values) => {
    setProcessing(true)
    Role.updateRole(roleId, values)
      .then(role => {
        setRoles(
          roles.map(item =>
            item.id.toString() === role.id.toString() ? { item, ...role } : item
          )
        )
        toast.success(`${role.label} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableRole = values => {
    setProcessing(true)
    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "Active",
    }
    Role.updateRole(values.id, payload)
      .then(role => {
        setRoles(
          roles.map(item =>
            item.id.toString() === role.id.toString() ? { ...role } : item
          )
        )
        toast.success(`${role.label} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableRole = values => {
    setProcessing(true)
    const payload = {
      ...omit(values, ["id", "dateCreated"]),
      status: "Disabled",
    }
    Role.updateRole(values.id, payload)
      .then(role => {
        setRoles(
          roles.map(item =>
            item.id.toString() === role.id.toString() ? { ...role } : item
          )
        )
        toast.success(`${role.label} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      fetchData(limit, page)
      setUserPermissions(JSON.parse(profile).permission)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  useEffect(() => {
    setRoleList(roles)
  }, [roles])

  useEffect(() => {
    if (!isEmpty(roles) && !!isEdit) {
      setRoleList(roles)
      setIsEdit(false)
    }
  }, [roles])

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      label: (role && role.label) || "",
      value: (role && role.value) || "",
      status: (role && role.status) || "",
    },
    validationSchema: Yup.object({
      label: Yup.string().required("Please enter role label"),
      value: Yup.string().required("Please enter role value"),
      status: Yup.string().required("Please select role status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updateRole(role.id, values)
        validation.resetForm()
      } else {
        // create new
        addRole(values)
        validation.resetForm()
      }
      toggle()
    },
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setRole(null)
    } else {
      setModal(true)
    }

    validation.resetForm()
  }

  const handleClick = arg => {
    const role = arg
    setRole(role)
    setIsEdit(true)
    toggle()
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "Edit details",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableRole(item) : enableRole(item)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userPermissions.MANAGE_GLOBAL_SETTINGS && {
        label: "New role",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const filteredRoles = chain(roles)
    .filter(role => {
      // Label
      const hasLabel = role.label?.toLowerCase().includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(filteredRoles, [p => p.label.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Roles" breadcrumbItem="Roles" />

          {/* Loading */}
          {loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Roles"}
            description={
              "We are processing your request, please wait a moment..."
            }
          />

          {/* LIST */}
          {!loading && !isEmpty(roles) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Roles"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            {`${roles.length} ${
                              roles.length > 1 ? "Roles" : "Role"
                            }`}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "label", true)}
                      renderItem={item => (
                        <RoleListItem
                          item={item}
                          actions={getListActions(item)}
                          permission={userPermissions.MANAGE_GLOBAL_SETTINGS}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Empty state */}
          {!loading && isEmpty(roles) && (
            <div className="d-flex flex-column align-items-center">
              <EmptyState
                title={"No roles"}
                description={
                  "No roles at the moment. Please check again later."
                }
              />

              {userPermissions.MANAGE_GLOBAL_SETTINGS && (
                <PrimaryButton
                  onClick={() => {
                    setIsEdit(false)
                    setModal(true)
                  }}
                  leftIcon="mdi mdi-plus"
                >
                  New role
                </PrimaryButton>
              )}
            </div>
          )}

          {/* ERROR */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}

          {/* Edit/Add Modal */}
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit role" : "New role"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Role label</Label>
                      <Input
                        name="label"
                        type="text"
                        placeholder="Label"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.label || ""}
                        invalid={
                          validation.touched.label && validation.errors.label
                            ? true
                            : false
                        }
                      />
                      {validation.touched.label && validation.errors.label ? (
                        <FormFeedback type="invalid">
                          {validation.errors.label}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Role value</Label>
                      <Input
                        name="value"
                        type="text"
                        placeholder="Value"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.value || ""}
                        invalid={
                          validation.touched.value && validation.errors.value
                            ? true
                            : false
                        }
                      />
                      {validation.touched.value && validation.errors.value ? (
                        <FormFeedback type="invalid">
                          {validation.errors.value}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Role status</Label>
                      <Input
                        name="status"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ""}
                        invalid={
                          validation.touched.status && validation.errors.status
                            ? true
                            : false
                        }
                      >
                        <option>Select status</option>
                        <option value="Active">Active</option>
                        <option value="Disabled">Disabled</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback status="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                      <SecondaryButton
                        type="button"
                        onClick={toggle}
                        className="me-4"
                      >
                        Cancel
                      </SecondaryButton>

                      <PrimaryButton type="submit">
                        {!!isEdit ? "Update role" : "Add role"}
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default withTranslation()(RoleList)
