import axios from "axios"
import API from "config/api.config"

export default {
  getCountries(limit = 10, page = 1) {
    return axios
      .get(
        `${API.getApi().COUNTRIES.GET_COUNTRIES}?limit=${limit}&page=${page}`
      )
      .then(async ({ data }) => {
        return data
      })
  },
  addCountry(values) {
    return axios
      .post(API.getApi().COUNTRIES.ADD_COUNTRY, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateCountry(countryId, values) {
    return axios
      .patch(`${API.getApi().COUNTRIES.UPDATE_COUNTRY}/${countryId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpCountries() {
    return axios
      .get(API.getApi().LOOKUPS.LOOKUP_COUNTRIES)
      .then(async ({ data }) => {
        return data
      })
  },
}
