import axios from "axios"
import API from "config/api.config"

export default {
  getProductCategories(limit = 10, page = 1) {
    return axios.get(`${API.getApi().PRODUCT_CATEGORIES.GET_PRODUCT_CATEGORIES}?limit=${limit}&page=${page}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addProductCategory(values) {
    return axios.post(API.getApi().PRODUCT_CATEGORIES.ADD_PRODUCT_CATEGORY, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateProductCategory(productCategoryId, values) {
    return axios.patch(`${API.getApi().PRODUCT_CATEGORIES.UPDATE_PRODUCT_CATEGORY}/${productCategoryId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpProductCategories() {
    return axios.get(API.getApi().LOOKUPS.PRODUCT_CATEGORIES)
      .then(async ({ data }) => {
        return data
      })
  },
}
