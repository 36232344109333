import React from "react"
import styled from "styled-components"

import {
  getButtonRadius,
  getIconButtonSizes,
  getIconButtonStyles,
} from "./Button.styles"

const StyledIconButton = styled.button.attrs({
  className: "hevara-icon-button",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  transition: all 0.25s;
  ${getIconButtonSizes}
  ${getButtonRadius}
  ${getIconButtonStyles}
`

const StyledIcon = styled.i.attrs({
  className: "hevara-button-icon",
})``

export const IconButton = props => {
  return (
    <StyledIconButton {...props}>
      <StyledIcon size={props.size === "xs" ? "20" : "24"}>
        <i className={props.name + " hevara-button-icon"}></i>
      </StyledIcon>
    </StyledIconButton>
  )
}

IconButton.defaultProps = {
  onClick: undefined,
  variant: "primary",
  size: "m",
}
