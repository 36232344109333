import React from "react"
import { map } from "lodash"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"

import "./stockStatusListItem.scss"

const StockStatusListItem = ({ item, actions, permission }) => {
  return (
    <TableRow>
      <div key={item.id} className="hevara-stock-status-list-item">
        {/* MAIN INFO */}
        <MainInfoList
          title={item.name}
          avatar={{ image: item.img, initials: item.name }}
          className="text-truncate"
        />

        <div className="date-item">
          {/* CODE */}
          <div className="title-and-date">
            <TableRowInfo
              className="text-truncate"
              subtext={item.code}
            />
          </div>

          <div className="status-and-actions">
            {/* ACTIONS */}
            {permission && (
              <ContextualModal
                className="item-actions"
                style={{ marginLeft: 4 }}
                position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
                contentElement={map(actions, (modalItem, index) => (
                  <ContextualModalListItem
                    key={index}
                    {...modalItem}
                    onClick={e => modalItem.onClick(e, item)}
                  />
                ))}
              />
            )}
          </div>
        </div>
      </div>
    </TableRow>
  )
}

StockStatusListItem.defaultProps = {
  item: {},
  actions: [],
}

export default StockStatusListItem
