import PropTypes from "prop-types"
import React, { useState, useEffect, Fragment } from "react"
import { isEmpty, omit, map } from "lodash"
import { toast, ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import Switch from "react-switch"
import {
    Card,
    CardBody,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
  } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import ErrorState from "components/ErrorState/errorState"
import Contact from "models/contact"
import Auth from "models/auth"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import CONST from "utils/constants"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import { PrimaryButton, BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import {
  getCompanyRole,
  getLastFourDigits,
  getStatus,
  transformPermission,
} from "utils/utils"

import Link, { LinkLayout } from "components/Link"

const { ROLES } = CONST

const primary = "#42bfc1"
const smooth_gray = "#cccccc"

const ContactDetails = props =>{
// attempt to use react-helmet and create new component
    document.title = "Contact details | hevara-DMS"

    const { contactId } = useParams()

    const navigate = useNavigate();

    const [modal,setModal] = useState(false)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [contact, setContact]= useState([])
    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState(false)
    const [profile, setProfile] = useState({})
    const [userPermissions, setUserPermissions] = useState({})
    
    const toggle = () => {
        if (modal) {
          setModal(false)
        } else {
          setModal(true)
        }
      }
    //CONSOLE LOG THE CONTENTS OF THE DATA
      const fetchContactData = id => {
        Contact.getContactDetails(id)
          .then(data => {
            setContact(data)
          })
          .catch(error => {
            setError(true)
            toast.error(error.response?.data?.message)
          })
          .finally(() => setLoading(false))
      }

      useEffect(() => {
        const profile = Auth.getLoggedInUserData()
        if(contactId && profile){
            fetchContactData(contactId)
            console.log(contactId)
        }
        setError(true)
        toast.error("Something went wrong. Please try again later")
      },[contactId])

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title = {props.t("Contact Details")}
            breadcrumbItem= {props.t("Contact Details")}
          />
             {/* PROCESSING */}
          <LoadingBackdrop
            show = {processing}
            title = {"Contact Details"}
            description= {"We are processing your request, please wait"}
          />

          {/* LOADING */}
          {!error && loading && <Loading/>}

          {!error && !loading && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    avatar ={{initials: contact.name }}
                    title = { contact.name }
                    showBackButton = { true }
                    showDivider = { false }
                    actions = {<HeaderActions actions = {getAction()}/>}
                    info = {
                      <PageHeaderInfo>
                        {!loading && !isEmpty(contact) && (
                          <>
                            <div className="mt-2">
                              {contact.email}
                            </div>

                            <PageHeaderInfoText className="fw-semibold">
                              {contact.subject}
                            </PageHeaderInfoText>
                          </>
                          )}
                      </PageHeaderInfo>
                    }
                  />

                <div className="hevara-contact-data">
                  <div className="tx-grid mb-3">
                    <div className="mb-4">
                      <h5 className="text-muted">Contact Details</h5>
                      <div className="detail-grid">
                        <DataItem 
                          label = {props.t("Name")}
                          value = {contact.name}
                          />

                          <DataItem 
                            label = {props.t("Email Address")}
                            value = {contact.email}
                          />

                          <DataItem
                            label = {props.t("Phone Number")}
                            value = {contact.phone}
                          />
                          
                          <DataItem 
                            label = {props.t("Subject")}
                            value = {contact.subject}
                          />


                          <DataItem 
                            label = {props.t("Date Received")}
                            value = {contact.date}
                          />

                          <DataItem
                            label = {props.t("Message :")}
                            value={contact.message}
                          />
                      </div>
                    </div>
                  </div>
                </div>
                </CardBody>
              </Card>
            </Row>
          )}

          {/** ERROR MESSAGE */}
          {error && (
            <div className="text-center">
              <ErrorState
                title={"Something went wrong"}
                description={"Please try again later."}
              />
            </div>
          )}
        </Container>
      </div>
      <ToastContainer />
    </Fragment>
  )

}

ContactDetails.propTypes={
    t: PropTypes.any
}
export default withTranslation()(ContactDetails)