import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default { 
  getWaitlist(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      name: filters.name,
      email: filters.email,
      date: filters.date
    }
    const url = queryParams(API.getApi().WAITLIST.GET_WAITLIST, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
}
