import React from "react"
import { isEmpty, map, isFunction } from "lodash"

import { GroupSeparator } from "components/Separator/GroupSeparator"
import Loading from "components/Common/Spinner"
import "./sectionList.scss"
import { SecondaryButton } from "components/Button"

const SectionList = ({
  sectionTitle,
  renderItem,
  data,
  hideTitle,
  getNextPage,
  hasNextPage,
  loadingPage,
  loading,
  className = "",
  listClassName = "",
}) => {
  return (
    <>
      {loading && (
        <div className="d-flex center-x">
          <Loading />
        </div>
      )}

      {!isEmpty(data) && (
        <React.Fragment>
          {/* SECTION LIST */}
          <ul className="reset-list-style hevara-section-list mb-4">
            {map(data, (data, index) => (
              <li key={index}>
                {!hideTitle &&
                  (isFunction(sectionTitle) ? (
                    sectionTitle(data.title)
                  ) : (
                    <GroupSeparator key={data.title} label={data.title} />
                  ))}
                <ul className={`reset-list-style ${listClassName}`}>
                  {map(data.data, (tx, index2) => {
                    return (
                      <li className="section-list-item">
                        {renderItem(tx, index2)}
                      </li>
                    )
                  })}
                </ul>
              </li>
            ))}
          </ul>

          {/* PAGINATION */}
          {hasNextPage && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="mt-5">
              {!loadingPage ? (
                <SecondaryButton onClick={getNextPage}>
                  Show more
                </SecondaryButton>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Loading style={{ width: 20 }} />
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </>
  )
}

SectionList.defaultProps = {
  data: [],
  hideTitle: false,
  hasNextPage: false,
  loadingPage: false,
  loading: false,
}

export default SectionList